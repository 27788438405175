/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react'
import { EuiStepsHorizontal, EuiStepStatus } from '@elastic/eui'
import { MdArrowBackIos } from 'react-icons/md'
import { Container, Row } from 'components/Grid'
import { Col60 } from 'components/Grid/Styled'
import { StepsRow, StepsText } from 'pages/nft-bridge/Styled'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from 'components/Step4'
import Box from 'components/Box'
import { BoxTitle } from 'components/Box/Styled'
import { BoxHeader } from './Styled'

function Bridge(): JSX.Element {
  const [step, setStep] = useState(1)
  const [txHash, setTxHash] = useState('')

  const getStepStatus = (_step: number): EuiStepStatus => {
    if (step == _step) {
      return 'current'
    }

    if (step > _step) {
      return 'complete'
    }

    return 'disabled'
  }

  const steps = [
    {
      title: '',
      status: getStepStatus(1),
      onClick: () => {
        setStep(1)
      },
    },
    {
      title: '',
      status: getStepStatus(2),
      onClick: () => {
        if (step > 2) {
          setStep(2)
        }
      },
    },
    {
      title: '',
      status: getStepStatus(3),
      onClick: () => {},
    },
    {
      title: '',
      status: getStepStatus(4),
      onClick: () => {},
    },
  ]

  const goToStep4 = (_txHash: string) => {
    setTxHash(_txHash)
    setStep(4)
  }

  return (
    <Container>
      <Row justifyContent="center">
        <Col60>
          <Box>
            <BoxHeader>
              <BoxTitle>
                {step == 1 ? 'Choose blockchain & asset' : ''}
                {step == 2 ? 'Amount and address' : ''}
                {step == 3 ? 'Confirm' : ''}
                {step == 4 ? 'Completed' : ''}
              </BoxTitle>
              <StepsRow justifyContent="center">
                <div>
                  <EuiStepsHorizontal steps={steps} />
                  <StepsText>
                    {step != 1 && step != 4 ? (
                      <p onClick={() => setStep(step - 1)}>
                        <MdArrowBackIos />
                        <span>Back</span>
                      </p>
                    ) : (
                      <p>&nbsp;</p>
                    )}
                    <p>
                      <strong>Step {step}</strong> / {steps.length}
                    </p>
                  </StepsText>
                </div>
              </StepsRow>
            </BoxHeader>
            <div style={{ padding: '1rem 1.5rem' }}>
              {step == 1 && <Step1 nextStepCallback={() => setStep(2)} />}
              {step == 2 && <Step2 prevStepCallback={() => setStep(1)} nextStepCallback={() => setStep(3)} />}
              {step == 3 && (
                <Step3
                  backtoStep1Callback={() => setStep(1)}
                  prevStepCallback={() => setStep(2)}
                  nextStepCallback={goToStep4}
                />
              )}
              {step == 4 && <Step4 txHash={txHash} prevStepCallback={() => setStep(1)} />}
            </div>
          </Box>
        </Col60>
      </Row>
    </Container>
  )
}

export default Bridge
