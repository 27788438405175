import { useCallback, useEffect, useState } from 'react'
import { EuiButton, EuiButtonEmpty } from '@elastic/eui'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import Box from 'components/Box'
import {
  ReSelectButton,
  Step3NetworkSelect,
  SelectedNFTsTitle,
  StepBox3Title,
  SelectedNFTsBox,
  StyledButton,
  ConfirmationButtons,
  BalanceText,
  FeeBox,
  FeeText,
} from '../Styled'
import {
  useActiveWeb3React,
  useCurrentNetwork,
  useRequestCasperNFTBridge,
  useTargetNetwork,
  useTokenBalanceCallback,
} from 'hooks'
import AddressInput from 'components/AddressInput'
import NFTListItem from '../components/NFTListItem'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { Dots, InputError, NetworkLogo } from 'theme/components'
import { useTransactionAdder } from 'state/transactions/hooks'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal/TransactionSubmittedContent'
import UnknownSVG from 'assets/images/unknown.svg'
import { clearNFTCache } from 'state/nft/actions'
import { useCasperNFTListCallback, useSelectedNFTCollection, useSelectedNFTs } from 'state/nft/hooks'
import { useAccount, useChainId, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { CasperClient, DeployUtil } from 'casper-js-sdk'
import { NATIVE_TOKEN_ADDERSS } from '../../../constants'
import { NFTApprovalState, useApproveNFTCasperCallback } from 'hooks/useApproveNFTCallback'
import NFT from 'type/NFT'
import { updateRecipientAddress, updateTargetNetwork } from 'state/application/actions'
import WarningMessage from 'pages/bridge/components/WarningMessage'
import { getDeployFunction } from 'utils'
import { useNFTBridgeAddress } from 'state/application/hooks'
import axios from 'axios'

const ConfirmButton = ({
  disableButton,
  showConfirm,
  attemptingTxn,
  onClick,
}: {
  disableButton: boolean
  showConfirm: boolean
  attemptingTxn: boolean
  onClick: () => void
}): JSX.Element => {
  return (
    <StyledButton
      style={{ marginBottom: '1rem' }}
      fill
      isDisabled={disableButton}
      isLoading={showConfirm && attemptingTxn}
      onClick={onClick}
    >
      Transfer
    </StyledButton>
  )
}

interface Step3Props {
  prevStepCallback: () => void
  nextStepCallback: (txHash: string) => void
}

function Step3Casper(props: Step3Props): JSX.Element {
  const { prevStepCallback, nextStepCallback } = props

  const { connector } = useActiveWeb3React()
  const account = useAccount()
  const chainId = useChainId()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelectedNFTCollection()
  const selectedNFTs = useSelectedNFTs()
  const tokenIds = selectedNFTs.map(nft => nft.tokenId)

  const addTransaction = useTransactionAdder()

  const [isValidAddress, setIsValidAddress] = useState(false)
  const [toAddress, setToAddress] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const nftBridgeAddress = useNFTBridgeAddress()

  const [approvalNFT, approveNFTCallback] = useApproveNFTCasperCallback(
    nftCollection?.contractHash,
    nftBridgeAddress,
    nftCollection?.originChainId !== chainId,
  )
  const ntfsCallback = useCasperNFTListCallback(account, sourceNetwork)
  const casperNFTBridgeCallback = useRequestCasperNFTBridge(
    account,
    nftCollection,
    sourceNetwork,
    targetNetwork,
    toAddress,
  )

  // Button state
  const [disableButton, setDisableButton] = useState(true)
  useEffect(() => {
    // setDisableButton(
    //   !isValidAddress ||
    //     (targetNetwork !== undefined &&
    //       nftCollection !== undefined &&
    //       nftCollection.supportedChainIds.length > 0 &&
    //       !nftCollection?.supportedChainIds.includes(targetNetwork?.chainId)),
    // )
    setDisableButton(!isValidAddress || targetNetwork === undefined)
  }, [isValidAddress, targetNetwork, nftCollection])

  const [wasmData, setWasmData] = useState(undefined)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        const response = await axios.get('/cep78_bridge_session.wasm', {
          responseType: 'arraybuffer',
        })
        if (response.status === 200 && response.data) {
          setWasmData(response.data)
        }
      } catch (error) {
        console.error('Could not get wasm data')
      }
    })()
  }, [])

  const [tokenBalance, isLoadingBalance] = useTokenBalanceCallback(
    NATIVE_TOKEN_ADDERSS,
    sourceNetwork?.nativeCurrency.decimals,
    account,
    undefined,
    sourceNetwork,
  )

  const addressInputCallback = (valid: boolean, _address: string) => {
    setIsValidAddress(valid)

    if (valid && targetNetwork) {
      setToAddress(_address)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const onRequestNFTBridge = async () => {
    setAttemptingTxn(true)

    if (account && sourceNetwork) {
      try {
        const moduleBytes = wasmData ? new Uint8Array(Buffer.from(wasmData, 'binary')) : undefined
        const deploy = await casperNFTBridgeCallback(tokenIds, moduleBytes)
        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(sourceNetwork.rpcURL)

          const deployFn = getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

          deployFn
            .then(async (hash: any) => {
              const tokenIdsString = tokenIds.map(id => `#${id}`)
              addTransaction(hash, {
                summary: `Send ${tokenIds.length} NFT${tokenIds.length > 1 ? 's' : ''} (${tokenIdsString.join(
                  ', ',
                )}) to bridge`,
              })

              setTxHash(hash)

              // Clear all nft state
              dispatch(clearNFTCache())
              dispatch(updateRecipientAddress({ address: '' }))
              dispatch(updateTargetNetwork({ network: undefined }))

              // update cache
              await ntfsCallback(false)

              setAttemptingTxn(false)
              nextStepCallback(hash)
            })
            .catch((error: any) => {
              setAttemptingTxn(false)
              console.error(error)
            })
        }
      } catch (error) {
        console.error(error)
        handleDismissConfirmation()
      }
    }
  }

  const confirmModalTopContent = () => {
    return (
      <>
        {sourceNetwork && targetNetwork ? (
          <p>
            This action will transfer you NFTs from <NetworkLogo src={sourceNetwork.logoURI ?? UnknownSVG} />{' '}
            {sourceNetwork.name} to <NetworkLogo src={targetNetwork.logoURI ?? UnknownSVG} /> {targetNetwork.name}.
          </p>
        ) : (
          <p>This action will transfer you NFTs to our bridge.</p>
        )}
      </>
    )
  }

  const confirmModalBottomContent = () => {
    return (
      <ConfirmationButtons>
        <EuiButtonEmpty onClick={handleDismissConfirmation}>Cancel</EuiButtonEmpty>
        <EuiButton fill onClick={onRequestNFTBridge}>
          Yes, Transfer
        </EuiButton>
      </ConfirmationButtons>
    )
  }

  return (
    <>
      <>
        <Box style={{ padding: '0 1.5rem' }}>
          <StepBox3Title>
            <span>Destination</span>
            <div>
              <Step3NetworkSelect
                selectedNetwork={targetNetwork}
                otherNetwork={sourceNetwork}
                side="TARGET"
                showTitle={false}
                nftBridge={true}
              />
              {targetNetwork !== undefined &&
                nftCollection !== undefined &&
                nftCollection.supportedChainIds.length > 0 &&
                !nftCollection?.supportedChainIds.includes(targetNetwork?.chainId) && (
                  <InputError>Unsupported Network</InputError>
                )}
            </div>
          </StepBox3Title>
          <AddressInput
            title="DESTINATION ADDRESS"
            placeholder="Enter the destination wallet address"
            showMyAccountBtn={true}
            callback={addressInputCallback}
          />
          <WarningMessage />
          <SelectedNFTsTitle>
            <span style={{ color: '#727eaa' }}>Selected NFTs</span>
            <ReSelectButton onClick={prevStepCallback}>Reselect</ReSelectButton>
          </SelectedNFTsTitle>
          {selectedNFTs && selectedNFTs.length > 0 && (
            <>
              <SelectedNFTsBox>
                {selectedNFTs?.map((nft: NFT) => (
                  <NFTListItem key={nft.tokenId} nft={nft} />
                ))}
              </SelectedNFTsBox>
              <FeeBox>
                <span style={{ color: '#727eaa' }}>Estimated Fees</span>
                <div>
                  <FeeText>
                    <span style={{ color: '#727eaa' }}>30 {sourceNetwork?.nativeCurrency.symbol}</span>
                  </FeeText>
                  <BalanceText>
                    Balance: {isLoadingBalance ? '--' : tokenBalance.toFixed(3)} {sourceNetwork?.nativeCurrency.symbol}
                  </BalanceText>
                </div>
              </FeeBox>
            </>
          )}
          {wasmData ? (
            <ConfirmButton
              disableButton={disableButton}
              showConfirm={showConfirm}
              attemptingTxn={attemptingTxn}
              onClick={() => setShowConfirm(true)}
            />
          ) : (
            <>
              {approvalNFT == NFTApprovalState.NOT_APPROVED ||
              approvalNFT == NFTApprovalState.PENDING ||
              approvalNFT == NFTApprovalState.LOADING ? (
                <StyledButton
                  style={{ marginBottom: '1rem' }}
                  isDisabled={attemptingTxn || approvalNFT == NFTApprovalState.LOADING}
                  isLoading={approvalNFT == NFTApprovalState.PENDING || approvalNFT == NFTApprovalState.LOADING}
                  fill
                  onClick={approveNFTCallback}
                >
                  {approvalNFT == NFTApprovalState.PENDING ? (
                    <Dots>Approving {nftCollection?.name}</Dots>
                  ) : (
                    <>{approvalNFT === NFTApprovalState.LOADING ? <Dots>Loading</Dots> : <span>Approve</span>}</>
                  )}
                </StyledButton>
              ) : (
                <ConfirmButton
                  disableButton={disableButton}
                  showConfirm={showConfirm}
                  attemptingTxn={attemptingTxn}
                  onClick={() => setShowConfirm(true)}
                />
              )}
            </>
          )}
        </Box>
      </>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Bridge your NFTs?"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => (
          <ConfirmationModalContent topContent={confirmModalTopContent} bottomContent={confirmModalBottomContent} />
        )}
      />
    </>
  )
}

export default Step3Casper
