import { Col, Container, Row } from 'components/Grid'
import { useSupportedTokens } from 'hooks/useSupportedTokens'
import TokensTable from './TokensTable'
import styled from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { MapInfo } from 'type/TokenMap'
import Network from 'type/Network'
import { EuiLoadingSpinner, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui'
import NFTsTable from './NFTsTable'
import { getExplorerLink } from 'utils'
import { SUPPORTED_NETWORKS } from '../../constants'
import Background1 from 'assets/images/bg-1.svg'

const TableRow = styled(Row)`
  margin-top: 16px;
  margin-bottom: 0;

  @media (min-width: 1200px) {
    margin-top: 32px;
  }
`

function SupportedTokens(): JSX.Element {
  const [tokenMap, isLoading] = useSupportedTokens()
  const [tokens, setTokens] = useState<MapInfo[]>([])
  const [nfts, setNFTs] = useState<MapInfo[]>([])
  const [selectedTabId, setSelectedTabId] = useState('token')

  useEffect(() => {
    const _items: MapInfo[] = []
    tokenMap.forEach(t => {
      if (t.mapInfo) {
        Object.values(t.mapInfo).forEach(info => {
          const network = SUPPORTED_NETWORKS.find(n => n.chainId === info.networkId) as Network
          const isCasper = network.key?.includes('casper')
          const isContractPackage = info.address === t.address

          _items.push({
            address: info.address,
            name: info.name,
            symbol: info.symbol,
            decimals: info.decimals,
            networkId: info.networkId,
            originKey: t.originKey,
            logoURI: t.logoURI,
            explorerLink: network
              ? getExplorerLink(
                  network,
                  info.address,
                  isCasper ? (isContractPackage ? 'contract-package' : 'contract') : 'token',
                )
              : '',
            symbolTable: { symbol: info.symbol, logoURI: t.logoURI },
            metamaskInfo: {
              address: info.address,
              symbol: t.symbol,
              decimals: t.decimals,
              logoURI: t.logoURI,
              network,
              explorerLink: network
                ? getExplorerLink(
                    network,
                    info.address,
                    isCasper ? (isContractPackage ? 'contract-package' : 'contract') : 'token',
                  )
                : '',
            },
            network,
          })
        })
      }
    })
    setTokens(_items.filter(item => item.decimals > 0))
    setNFTs(_items.filter(item => item.decimals == 0))
  }, [tokenMap])

  const tabs = [
    {
      id: 'token',
      name: 'ERC20 Token',
      content: (
        <>
          <EuiSpacer />
          <EuiSpacer />
          <TableRow>
            <Col>{isLoading ? <EuiLoadingSpinner /> : <TokensTable items={tokens} tokenMap={tokenMap} />}</Col>
          </TableRow>
        </>
      ),
    },
    {
      id: 'nft',
      name: 'NFT',
      content: (
        <>
          <EuiSpacer />
          <EuiSpacer />
          <TableRow>
            <Col>{isLoading ? <EuiLoadingSpinner /> : <NFTsTable items={nfts} tokenMap={tokenMap} />}</Col>
          </TableRow>
        </>
      ),
    },
  ]

  const selectedTabContent = tabs.find(obj => obj.id === selectedTabId)?.content

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id)
  }

  return (
    <Container>
      <Row style={{ marginTop: '2rem' }} alignItems="center">
        <Col>
          <div style={{ backgroundColor: '#fff', padding: '1.5rem' }}>
            <EuiTabs
              style={{
                backgroundImage: `url(${Background1})`,
                backgroundSize: '170px 150px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
              }}
            >
              <h3>Explorer</h3>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: '#4364a5',
                  borderRadius: '0.5rem 0.5rem 0 0',
                }}
              >
                {tabs.map((tab: any, index: number) => (
                  <EuiTab key={index} onClick={() => onSelectedTabChanged(tab.id)} isSelected={tab.id == selectedTabId}>
                    {tab.name}
                  </EuiTab>
                ))}
              </div>
              <p>{''}</p>
            </EuiTabs>
            {selectedTabContent}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SupportedTokens
