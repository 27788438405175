import styled from 'styled-components/macro'
import { Col } from 'components/Grid'

export const Col20 = styled(Col)`
  @media (min-width: 48rem) {
    display: flex;
    width: 20% !important;
    flex: 0 0 20% !important;
  }
`
export const Col50 = styled(Col)`
  @media (min-width: 48rem) {
    display: flex;
    flex-direction: column;
    width: 50% !important;
    flex: 0 0 50% !important;
  }
`
export const Col90 = styled(Col)`
  @media (min-width: 48rem) {
    display: flex;
    flex-direction: column;
    width: 90% !important;
    flex: 0 0 90% !important;
  }
`
export const Col80 = styled(Col)`
  @media (min-width: 48rem) {
    display: flex;
    flex-direction: column;
    width: 80% !important;
    flex: 0 0 80% !important;
  }
`
export const Col60 = styled(Col)`
  @media (min-width: 48rem) {
    display: flex;
    flex-direction: column;
    width: 60% !important;
    flex: 0 0 60% !important;
  }
`
export const Col40 = styled(Col)`
  @media (min-width: 48rem) {
    display: flex;
    flex-direction: column;
    width: 40% !important;
    flex: 0 0 40% !important;
  }
`
