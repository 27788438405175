import Transaction from 'type/Transaction'
import { CollapseWrap, Row } from '../Styled'
import { NATIVE_TOKEN_ADDERSS } from '../../../constants'
import NetworkInfo from '../NetworkInfo'
import { getExplorerLink } from 'utils'
import ClaimTokenForButton from './ClaimTokenButton/ClaimTokenForButton'
import { Spacer } from 'theme/components'
import { useAccount } from 'state/wallet/hooks'
import { useCurrentNetwork } from 'hooks'
import { useLocation } from 'react-router-dom'

function TxnDetails({ item }: { item: Transaction }): JSX.Element {
  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const url = useLocation()

  const path = item.contractPackageHash ? 'contract-package' : item.contractHash ? 'contract' : ''
  const address = item.contractPackageHash ?? item.contractHash ?? ''
  const isCasper = currentNetwork?.name.toLowerCase().includes('casper')

  return (
    <CollapseWrap>
      <Row>
        <div>
          <span>Transfer</span>
          <span className="text-secondary w-600">
            {item.originNetwork ? (
              <>
                {item.originToken === NATIVE_TOKEN_ADDERSS ? (
                  <span>{item.amountFormated}</span>
                ) : (
                  <a
                    href={getExplorerLink(item.originNetwork, address, path)}
                    target="__blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {item.amountFormated}
                  </a>
                )}
              </>
            ) : (
              <span>{item.amountFormated}</span>
            )}
          </span>
        </div>
        <div>
          <span>from</span>
          <NetworkInfo network={item.fromNetwork} />
        </div>
        <div>
          <span>to</span>
          <NetworkInfo network={item.toNetwork} />
        </div>
      </Row>
      {item.account !== item.txCreator && (
        <Row>
          {item.toNetwork?.notEVM ? 'Recipient account hash:' : 'Recipient account address:'}&nbsp;
          <a href={`${item.accountUrl}`} target="__blank" rel="noopener noreferrer nofollow">
            {item.account}
          </a>
        </Row>
      )}
      {item.originNetwork && item.originToken !== NATIVE_TOKEN_ADDERSS && (
        <>
          <Row>
            This token was deployed on <NetworkInfo network={item.originNetwork} />
          </Row>
          {(item.fromNetwork?.key?.includes('casper') || item.toNetwork?.key?.includes('casper')) &&
            item.originNetwork?.key?.includes('casper') &&
            address && (
              <Row>
                Contract hash on <NetworkInfo network={item.fromNetwork?.notEVM ? item.fromNetwork : item.toNetwork} />
                <a
                  href={`${getExplorerLink(item.originNetwork, address, path)}`}
                  target="__blank"
                  rel="noopener noreferrer nofollow"
                >
                  {` ${address}`}
                </a>
              </Row>
            )}
        </>
      )}
      <Spacer height={0.5} />
      {!isCasper &&
        account &&
        account?.toLowerCase() != item.account.toLowerCase() &&
        !item.toNetwork?.notEVM &&
        !url.pathname.includes('my-transactions') && <ClaimTokenForButton item={item} />}
    </CollapseWrap>
  )
}

export default TxnDetails
