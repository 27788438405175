import { EuiButton } from '@elastic/eui'
import { Col, Row } from 'components/Grid'
import { Col80 } from 'components/Grid/Styled'
import SendNFTModal from 'components/SendNFTModal'
import { useCurrentNetwork } from 'hooks'
import LoadingNFTItem from 'pages/nft-bridge/components/LoadingNFTItem'
import NFTItem from 'pages/nft-bridge/components/NFTItem'
import {
  InlineNFTCollectionSelect,
  NFTGrid,
  RefreshButton,
  SelectAll,
  Step2Box,
  Step2BoxRow,
  Step2BoxTitle,
  Step2Buttons,
  Step2Footer,
  YourNFTsIn,
} from 'pages/nft-bridge/Styled'
import React, { useEffect, useState } from 'react'
import { HiOutlineCheck, HiOutlineRefresh } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from 'state'
import { updateSelectedNFTs } from 'state/nft/actions'
import { useCasperNFTListCallback, useSelectedNFTCollection, useSelectedNFTs } from 'state/nft/hooks'
import { useAccount, useChainId } from 'state/wallet/hooks'
import NFT from 'type/NFT'

function NFTCasperEVM(): JSX.Element {
  const account = useAccount()
  const chainId = useChainId()
  const sourceNetwork = useCurrentNetwork()
  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelectedNFTCollection()
  const selectedNFTs = useSelectedNFTs()

  const ntfsCallback = useCasperNFTListCallback(account, sourceNetwork)
  const [loading, setLoading] = useState(false)
  const [nfts, setNFTs] = useState<NFT[]>([])

  const [isSelectAll, setSelectAll] = useState(false)
  const [showSendNFTModal, setShowSendNFTModal] = useState(false)

  const MAX_ITEMS = 1

  const onSelectAll = () => {
    if (isSelectAll) {
      dispatch(updateSelectedNFTs({ selectedNFTs: [] }))
    } else {
      dispatch(updateSelectedNFTs({ selectedNFTs: nfts }))
    }
    setSelectAll(!isSelectAll)
  }

  const fetchNFTs = async (loadFromCache: boolean) => {
    try {
      setLoading(true)
      const _nfts = await ntfsCallback(loadFromCache)
      setNFTs(_nfts)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNFTs(true)
  }, [account, chainId, nftCollection])

  useEffect(() => {
    setSelectAll(selectedNFTs != undefined && selectedNFTs?.length > 0 && selectedNFTs?.length == nfts.length)
  }, [selectedNFTs])

  const onRefresh = async () => {
    dispatch(updateSelectedNFTs({ selectedNFTs: [] }))
    await fetchNFTs(false)
  }

  return (
    <Row justifyContent="center">
      <Col80>
        <Step2Box>
          <Step2BoxRow>
            <Step2BoxTitle style={{ marginBottom: 0 }}>
              <div>
                <YourNFTsIn>Your NFTs in</YourNFTsIn>
                {sourceNetwork && <InlineNFTCollectionSelect showTitle={false} />}
              </div>
              <RefreshButton onClick={onRefresh} className={loading ? 'is-loading' : ''}>
                <HiOutlineRefresh color="#154ba0" size={24} />
              </RefreshButton>
            </Step2BoxTitle>
            {selectedNFTs && (
              <>
                <span style={{ color: '#727eaa' }}>
                  {selectedNFTs.length} {selectedNFTs.length > 1 ? 'items' : 'item'} selected
                </span>
              </>
            )}
          </Step2BoxRow>

          <NFTGrid className="eui-yScroll">
            {loading ? (
              <>
                <LoadingNFTItem />
                <LoadingNFTItem />
                <LoadingNFTItem />
                <LoadingNFTItem />
                <LoadingNFTItem />
                <LoadingNFTItem />
              </>
            ) : (
              <>
                {selectedNFTs && nfts.length > 0 ? (
                  <>
                    {nfts.map(nft => (
                      <NFTItem
                        key={nft.tokenId}
                        nft={nft}
                        isSelected={selectedNFTs.findIndex(n => n.tokenId == nft.tokenId) >= 0}
                      />
                    ))}
                  </>
                ) : (
                  <>{nftCollection ? <Col>No NFTs found.</Col> : <Col>Select an NFT Collection.</Col>}</>
                )}
              </>
            )}
          </NFTGrid>

          <Step2Footer>
            <SelectAll onClick={onSelectAll} className={isSelectAll ? 'is-selected' : ''}>
              <HiOutlineCheck />
              <span style={{ color: '#727eaa' }}>Select All</span>
            </SelectAll>
            <Step2Buttons style={{ gap: 20 }}>
              {/* <EuiButton
                isDisabled={selectedNFTs && (selectedNFTs?.length <= 0 || selectedNFTs?.length > MAX_ITEMS)}
                onClick={() => setShowSendNFTModal(true)}
              >
                Send
              </EuiButton> */}
              {showSendNFTModal && <SendNFTModal onDismiss={() => setShowSendNFTModal(false)} />}
              <EuiButton
                fill
                iconSide="right"
                iconType="arrowRight"
                isDisabled={selectedNFTs && (selectedNFTs?.length <= 0 || selectedNFTs?.length > MAX_ITEMS)}
                onClick={() => navigate('/nft-bridge/3')}
              >
                {selectedNFTs?.length <= MAX_ITEMS ? 'Bridge' : `Max ${MAX_ITEMS} ${MAX_ITEMS > 1 ? 'items' : 'item'}`}
              </EuiButton>
            </Step2Buttons>
          </Step2Footer>
        </Step2Box>
      </Col80>
    </Row>
  )
}

export default NFTCasperEVM
