import {
  EuiOutsideClickDetector,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFieldText,
  EuiButton,
} from '@elastic/eui'
import NetworkSelect from 'components/NetworkSelect'
import {
  useActiveWeb3React,
  useAllNetworks,
  useCurrentNetwork,
  useDebounce,
  useTargetNetwork,
  useTokenBalanceCallback,
} from 'hooks'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSelectedToken } from 'state/token/hooks'
import styled from 'styled-components/macro'
import { InputTitle } from 'theme/components'
import TokenSelect from 'pages/bridge/components/TokenSelect'
import { useAccount } from 'state/wallet/hooks'
import WalletModal from 'components/WalletModal'
import { debounce } from 'lodash'
import BridgeAppContext from 'context/BridgeAppContext'
import { ethers } from 'ethers'

const StyledEuiModal = styled(EuiModal)`
  background-color: #151515;
  .euiFormControlLayout__childrenWrapper {
    height: auto;
  }
`
const InputField = styled(EuiFieldText)`
  padding: 1.25rem 1rem;
`
export const StyledButton = styled(EuiButton)`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  @media (min-width: 62rem) {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
`

interface BuyModalProps {
  title: string
  onDismiss: () => void
}

function BuyModal(props: BuyModalProps): JSX.Element {
  const { title, onDismiss } = props

  const { ledgerAddress } = useContext(BridgeAppContext)
  const { library: web3Library } = useActiveWeb3React()

  const account = useAccount()
  const selectedToken = useSelectedToken()
  const currentNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()

  const [amountPay, setAmountPay] = useState<string | number | undefined>()
  const [amountReceiver, setAmountReceiver] = useState<string | number | undefined>()
  const [showWalletModal, setShowWalletModal] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [textButton, setTextButton] = useState<string>()

  const library =
    ledgerAddress !== '' ? new ethers.providers.JsonRpcProvider(currentNetwork?.rpcURL ?? '') : web3Library

  const [tokenBalance, loading] = useTokenBalanceCallback(
    selectedToken ? selectedToken.address : undefined,
    selectedToken ? selectedToken.decimals : undefined,
    account,
    library,
    currentNetwork,
  )

  useEffect(() => {
    //console.log('currentNetwork', currentNetwork)
    //console.log('support token: ', tokenMap)
    //console.log('is testnet: ', isDev)
    const _amountPay = Number(amountPay)

    if (!selectedToken) {
      setDisableButton(true)
      setTextButton('Select token to pay')
    } else {
      setTextButton('Enter amount')
    }

    if (loading) {
      setDisableButton(true)
      setTextButton('Loading')
    } else {
      if (_amountPay > Number(tokenBalance)) {
        setDisableButton(true)
        setTextButton('Insufficient balance')
      }
      if (_amountPay && _amountPay === 0) {
        setDisableButton(true)
        setTextButton('Enter amount')
      }
      if (_amountPay && _amountPay > 0 && _amountPay < tokenBalance) {
        setDisableButton(true)
        setTextButton('Buy')
      }
    }
  }, [selectedToken, amountPay, tokenBalance])

  //useEffect(() => {
  //console.log('token selected balance: ', tokenBalance)
  //}, [selectedToken])

  const onAmountChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setAmountPay(value)
    debounceFn(value)
  }
  const handleDebounceFn = async (inputValue: string) => {
    setAmountReceiver(inputValue)
  }

  const debounceFn = useCallback(debounce(handleDebounceFn, 200), [])
  const onBuyCSPR = async () => {}
  return (
    <>
      <EuiOutsideClickDetector onOutsideClick={onDismiss}>
        <StyledEuiModal onClose={onDismiss}>
          <EuiModalHeader style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <EuiModalHeaderTitle style={{ marginBottom: '3rem' }}>
              <h1>{title}</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <NetworkSelect
              selectedNetwork={currentNetwork}
              otherNetwork={targetNetwork}
              side="SOURCE"
              showTitle={true}
            />
            <TokenSelect
              showNativeToken={!currentNetwork?.name.toLowerCase().includes('casper')}
              isListTokenSwap={true}
            />
            <div style={{ marginBottom: '2rem' }}>
              <InputTitle>Amount {selectedToken?.symbol ? selectedToken?.symbol : 'Token'} pay</InputTitle>
              <InputField value={amountPay} autoFocus={true} onChange={onAmountChange} />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <InputTitle>Amount CSPR receive</InputTitle>
              <InputField value={amountReceiver} onChange={() => setAmountReceiver(amountReceiver)} />
            </div>
            {account ? (
              <StyledButton fill disabled={disableButton} onClick={onBuyCSPR}>
                {selectedToken ? textButton : 'Select token to pay'}
              </StyledButton>
            ) : (
              <>
                <StyledButton fill onClick={() => setShowWalletModal(true)}>
                  Connect Wallet
                </StyledButton>
                {showWalletModal && <WalletModal onDismiss={() => setShowWalletModal(false)} />}
              </>
            )}
          </EuiModalBody>
        </StyledEuiModal>
      </EuiOutsideClickDetector>
    </>
  )
}

export default BuyModal
