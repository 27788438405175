import { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { TransactionResponse } from '@ethersproject/providers'
import { EuiButton, EuiButtonEmpty } from '@elastic/eui'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import Box from 'components/Box'
import {
  ReSelectButton,
  Step3NetworkSelect,
  SelectedNFTsTitle,
  StepBox3Title,
  SelectedNFTsBox,
  FeeBox,
  FeeText,
  BalanceText,
  StyledButton,
  ConfirmationButtons,
  NoteText,
  FeeBoxRow,
} from '../Styled'
import {
  NFTApprovalState,
  useActiveWeb3React,
  useApproveNFTCallback,
  useCurrentNetwork,
  useNFTBridgeContract,
  useTargetNetwork,
  useTokenBalanceCallback,
} from 'hooks'
import AddressInput from 'components/AddressInput'
import NFTListItem from '../components/NFTListItem'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { Dots, NetworkLogo } from 'theme/components'
import { useTransactionAdder } from 'state/transactions/hooks'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal/TransactionSubmittedContent'
import UnknownSVG from 'assets/images/unknown.svg'
import { NATIVE_TOKEN_ADDERSS } from '../../../constants'
import { clearNFTCache } from 'state/nft/actions'
import { useNFTListCallback, useSelectedNFTCollection, useSelectedNFTs } from 'state/nft/hooks'
import { useAccount } from 'state/wallet/hooks'
import { CLPublicKey } from 'casper-js-sdk'
import { formatEther } from 'ethers/lib/utils'
import { updateRecipientAddress, updateTargetNetwork } from 'state/application/actions'
import toast from 'react-hot-toast'
import WarningMessage from 'pages/bridge/components/WarningMessage'
import { useNFTBridgeAddress } from 'state/application/hooks'
import useNativeFee from 'hooks/useNativeFee'

interface Step3Props {
  prevStepCallback: () => void
  nextStepCallback: (txHash: string) => void
}

function Step3EVM(props: Step3Props): JSX.Element {
  const { prevStepCallback, nextStepCallback } = props

  const account = useAccount()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()

  const { library } = useActiveWeb3React()

  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelectedNFTCollection()
  const selectedNFTs = useSelectedNFTs()

  const addTransaction = useTransactionAdder()
  const nftBridgeAddress = useNFTBridgeAddress()
  const nftBridgeContract = useNFTBridgeContract(nftBridgeAddress)
  const [nativeFee, isLoadingFee] = useNativeFee(nftBridgeContract)
  const nativeFeeNum = Number(formatEther(nativeFee))

  const [isValidAddress, setIsValidAddress] = useState(false)
  const [toAddress, setToAddress] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const [approvalNFT, approveNFTCallback] = useApproveNFTCallback(
    nftCollection?.address,
    nftCollection?.type,
    nftBridgeAddress,
  )
  const ntfsCallback = useNFTListCallback(account, nftCollection?.address ?? '', nftCollection?.type)

  // Button state
  const [disableButton, setDisableButton] = useState(true)
  useEffect(() => {
    // setDisableButton(
    //   !isValidAddress ||
    //     (targetNetwork !== undefined &&
    //       nftCollection !== undefined &&
    //       nftCollection.supportedChainIds.length > 0 &&
    //       !nftCollection?.supportedChainIds.includes(targetNetwork?.chainId)),
    // )
    setDisableButton(!isValidAddress || targetNetwork === undefined)
  }, [isValidAddress, targetNetwork, nftCollection])

  // Get token balance
  const [tokenBalance, isLoadingBalance] = useTokenBalanceCallback(
    NATIVE_TOKEN_ADDERSS,
    sourceNetwork?.nativeCurrency.decimals,
    account,
    library,
    sourceNetwork,
  )

  const addressInputCallback = (valid: boolean, _address: string) => {
    setIsValidAddress(valid)

    if (valid && targetNetwork) {
      const abiCoder = new ethers.utils.AbiCoder()
      let _toAddress = abiCoder.encode(['string'], [_address.toLowerCase()])
      if (targetNetwork.key?.includes('casper')) {
        const _accountHash = CLPublicKey.fromHex(_address).toAccountHashStr()
        _toAddress = abiCoder.encode(['string'], [_accountHash.toLowerCase()])
      }
      setToAddress(_toAddress)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const onRequestNFTBridge = () => {
    setAttemptingTxn(true)
    if (nftBridgeContract && nftCollection && targetNetwork && toAddress) {
      const tokenIds = selectedNFTs.map(nft => nft.tokenId)

      nftBridgeContract
        .requestMultiNFT721Bridge(nftCollection?.address, toAddress, tokenIds, targetNetwork.chainId, {
          value: nativeFee,
        })
        .then((response: TransactionResponse) => {
          const tokenIdsString = tokenIds.map(id => `#${id}`)
          addTransaction(response, {
            summary: `Send ${tokenIds.length} NFT${tokenIds.length > 1 ? 's' : ''} (${tokenIdsString.join(
              ', ',
            )}) to bridge`,
          })

          setTxHash(response.hash)

          response.wait().then(async () => {
            // Clear all nft state
            dispatch(clearNFTCache())

            dispatch(updateRecipientAddress({ address: '' }))

            dispatch(updateTargetNetwork({ network: undefined }))

            // update cache
            await ntfsCallback(false)

            setAttemptingTxn(false)

            nextStepCallback(response.hash)
          })
        })
        .catch((error: any) => {
          setAttemptingTxn(false)

          if (error?.code === 'UNPREDICTABLE_GAS_LIMIT') {
            const errorMsg = error.error.data.message
            toast.error(errorMsg.replace('execution reverted: ', ''))
          } else {
            console.error(error)
          }
        })
    }
  }

  const confirmModalTopContent = () => {
    return (
      <>
        {sourceNetwork && targetNetwork ? (
          <p>
            This action will transfer you NFTs from <NetworkLogo src={sourceNetwork.logoURI ?? UnknownSVG} />{' '}
            {sourceNetwork.name} to <NetworkLogo src={targetNetwork.logoURI ?? UnknownSVG} /> {targetNetwork.name}.
          </p>
        ) : (
          <p>This action will transfer you NFTs to our bridge.</p>
        )}
      </>
    )
  }

  const confirmModalBottomContent = () => {
    return (
      <ConfirmationButtons>
        <EuiButtonEmpty onClick={handleDismissConfirmation}>Cancel</EuiButtonEmpty>
        <EuiButton fill onClick={onRequestNFTBridge}>
          Yes, Transfer
        </EuiButton>
      </ConfirmationButtons>
    )
  }

  return (
    <>
      <>
        <Box style={{ padding: '0 1.5rem' }}>
          <StepBox3Title>
            <span>Destination</span>
            <div>
              <Step3NetworkSelect
                selectedNetwork={targetNetwork}
                otherNetwork={sourceNetwork}
                side="TARGET"
                showTitle={false}
                nftBridge={true}
              />
              {/* {targetNetwork !== undefined &&
                nftCollection !== undefined &&
                nftCollection.supportedChainIds.length > 0 &&
                !nftCollection?.supportedChainIds.includes(targetNetwork?.chainId) && (
                  <InputError>Unsupported Network</InputError>
                )} */}
            </div>
          </StepBox3Title>
          <AddressInput
            title="DESTINATION ADDRESS"
            placeholder="Enter the destination wallet address"
            showMyAccountBtn={true}
            callback={addressInputCallback}
          />
          <WarningMessage />
          <SelectedNFTsTitle>
            <span>Selected NFTs</span>
            <ReSelectButton onClick={prevStepCallback}>Reselect</ReSelectButton>
          </SelectedNFTsTitle>
          {selectedNFTs && selectedNFTs.length > 0 && (
            <>
              <SelectedNFTsBox>
                {selectedNFTs?.map(nft => (
                  <NFTListItem key={nft.tokenId} nft={nft} />
                ))}
              </SelectedNFTsBox>
              {nativeFeeNum > 0 && (
                <FeeBox>
                  <FeeBoxRow>
                    <div>
                      <h5>Estimated Fees</h5>
                      <NoteText>
                        To prevent spam on our bridge, a native fee will be applied when bridging NFTs.
                      </NoteText>
                    </div>
                    <div>
                      <FeeText>
                        {isLoadingFee ? (
                          <Dots position="before">{sourceNetwork?.nativeCurrency.symbol}</Dots>
                        ) : (
                          <p>
                            {parseFloat(nativeFeeNum.toFixed(6))} {sourceNetwork?.nativeCurrency.symbol}
                          </p>
                        )}
                      </FeeText>
                      <BalanceText>
                        Balance: {isLoadingBalance ? '--' : tokenBalance.toFixed(3)}{' '}
                        {sourceNetwork?.nativeCurrency.symbol}
                      </BalanceText>
                    </div>
                  </FeeBoxRow>
                </FeeBox>
              )}
            </>
          )}
          {approvalNFT == NFTApprovalState.NOT_APPROVED || approvalNFT == NFTApprovalState.PENDING ? (
            <StyledButton
              style={{ marginBottom: '1rem' }}
              isDisabled={attemptingTxn}
              isLoading={approvalNFT == NFTApprovalState.PENDING}
              fill
              onClick={approveNFTCallback}
            >
              {approvalNFT == NFTApprovalState.PENDING ? (
                <Dots>Approving {nftCollection?.name}</Dots>
              ) : (
                <span>Approve</span>
              )}
            </StyledButton>
          ) : (
            <StyledButton
              style={{ marginBottom: '1rem' }}
              fill
              isDisabled={disableButton}
              isLoading={showConfirm && attemptingTxn}
              onClick={() => setShowConfirm(true)}
            >
              Transfer
            </StyledButton>
          )}
        </Box>
      </>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Bridge your NFTs?"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => (
          <ConfirmationModalContent topContent={confirmModalTopContent} bottomContent={confirmModalBottomContent} />
        )}
      />
    </>
  )
}

export default Step3EVM
