import { EuiButton } from '@elastic/eui'
import { Row } from 'components/Grid'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { ExternalLink } from 'theme/components'

export const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid #2f333f;
  border-radius: 0.75rem;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`
export const AccountGroupingRow = styled(Row)`
  margin: 0 !important;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: #fff;

  div {
    display: flex;
    align-items: center;
    height: auto;
  }
`
export const WalletName = styled.div`
  font-size: 0.825rem;
  font-weight: 500;
  color: #154ba0;
`
export const WalletAction = styled(EuiButton)`
  border: 1px solid ${props => props.theme.primary};
  background-color: transparent;
  font-size: 0.825rem;
  font-weight: 400;
  padding: 4px 6px;
  min-width: 0;
`
export const AccountControl = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
export const IconWrapper = styled.div<{ size?: number }>`
  diplay: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }

  @media (min-width: 48rem) {
    align-items: flex-end;
  }
`
export const LinkStyle = css`
  font-size: 0.825rem;
  color: #888d9b;
  font-size: 0.825rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
  }

  span {
    margin-left: 4px;
  }
`
export const AddressLink = styled(ExternalLink)`
  ${LinkStyle}

  span + svg {
    display: none !important;
  }

  svg {
    diplay: inline-block;
    vertical-align: middle;
  }
`
export const YourNFTsLink = styled(Link)`
  ${LinkStyle}
`
export const LowerSection = styled.div`
  text-align: center;
  padding: 1rem;
  flex-grow: 1;
  overflow: auto;
  background-color: #154ba0;
  color: #fff;
  border-radius: 0.75rem;

  h5 {
    margin: 0;
    font-weight: 400;
  }

  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    padding: 1.5rem;
  }
`
export const TransactionListWrapper = styled.div`
  margin-top: 1rem;
`
