import {
  EuiOutsideClickDetector,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
} from '@elastic/eui'
import { TransactionResponse } from '@ethersproject/providers'
// @ts-ignore
import { EuiWindowEvent } from '@elastic/eui/lib/services'
import AddressInput from 'components/AddressInput'
import { useCurrentNetwork, useNFTContract } from 'hooks'
import React, { useCallback, useState } from 'react'
import { useSelectedNFTCollection, useSelectedNFTs } from 'state/nft/hooks'
import { useAccount } from 'state/wallet/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import toast from 'react-hot-toast'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'

function SendNFTModal({ onDismiss }: { onDismiss: () => void }): JSX.Element {
  const currentNetwork = useCurrentNetwork()
  const nftCollection = useSelectedNFTCollection()
  const selectedNFTs = useSelectedNFTs()
  const account = useAccount()

  const [isValidAddress, setIsValidAddress] = useState(false)
  const [sending, setSending] = useState(false)
  const [recipient, setRecipient] = useState('')

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const nftContract = useNFTContract(nftCollection?.address, nftCollection?.type)

  const onEscKeydown = (e: React.KeyboardEvent) => {
    if (e.key === '27') {
      onDismiss()
    }
  }

  const addressInputCallback = (valid: boolean, _address: string) => {
    setIsValidAddress(valid)
    setRecipient(_address)
  }

  const sendNFTEVM = () => {
    if (account && nftContract) {
      return nftContract.safeTransferFrom(account, recipient, selectedNFTs[0].tokenId)
    }
  }

  const handleSendNFT = async () => {
    if (!currentNetwork) {
      return
    }

    try {
      setSending(true)
      if (currentNetwork.notEVM) {
        if (currentNetwork.key?.includes('casper')) {
        }
      } else {
        sendNFTEVM()
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Send NFT (#${selectedNFTs[0].tokenId}) to bridge`,
            })

            setTxHash(response.hash)

            response.wait().then(async () => {
              // update cache
              setAttemptingTxn(false)
            })
          })
          .catch((error: any) => {
            setAttemptingTxn(false)

            if (error?.code === 'UNPREDICTABLE_GAS_LIMIT') {
              const errorMsg = error.error.data.message
              toast.error(errorMsg.replace('execution reverted: ', ''))
            } else {
              console.error(error)
            }
          })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSending(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <EuiOutsideClickDetector onOutsideClick={onDismiss}>
        <EuiModal onClose={onDismiss}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Send Your NFT</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <AddressInput
              title="RECIPIENT"
              placeholder="Enter the recipient wallet address"
              callback={addressInputCallback}
              showMyAccountBtn={false}
              darker={true}
              inSameNetwork={true}
            />
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton fill disabled={!isValidAddress} isLoading={sending} onClick={handleSendNFT}>
              Send
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOutsideClickDetector>
      <EuiWindowEvent event="keydown" handler={onEscKeydown} />

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Bridge your NFTs?"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default SendNFTModal
