import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { network } from '../../connectors'
import { useInactiveListener } from '../../hooks'
import { connectorLocalStorageKey, ConnectorNames, NetworkContextName } from '../../constants'
import { useEagerConnect } from 'state/wallet/hooks'

function Web3ReactManager({ children }: { children: JSX.Element }): JSX.Element | null {
  const { active } = useWeb3React()
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName)

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate it
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (triedEager && !networkActive && !networkError && !active) {
        const connectorID = window.localStorage.getItem(connectorLocalStorageKey)
        if (connectorID == ConnectorNames.Injected) {
          activateNetwork(network)
        }
      }
    })()
  }, [triedEager, networkActive, networkError, activateNetwork])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null
  }

  return children
}

export default Web3ReactManager
