import { BsArrowUpCircle } from 'react-icons/bs'
import { useCurrentNetwork } from 'hooks'
import { ExternalLink } from 'theme/components'
import { getExplorerLink } from 'utils'

interface TransactionSubmittedContentProps {
  hash: string
}

export function ConfirmationModalContent({
  topContent,
  bottomContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}): JSX.Element {
  return (
    <div>
      <div>{topContent()}</div>
      <div>{bottomContent()}</div>
    </div>
  )
}

function TransactionSubmittedContent(props: TransactionSubmittedContentProps): JSX.Element {
  const { hash } = props
  const networkInfo = useCurrentNetwork()

  return (
    <div className="text-center">
      <BsArrowUpCircle size={50} className="mb-2" color="#154ba0" />
      <h3 style={{ color: '#154ba0' }}>Transaction Submitted</h3>
      <p>
        <ExternalLink href={getExplorerLink(networkInfo, hash, 'transaction')}>View on explorer</ExternalLink>
      </p>
    </div>
  )
}

export default TransactionSubmittedContent
