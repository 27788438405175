import styled from 'styled-components/macro'

export const FaucetBoxWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 1rem;
  box-shadow: 0 0 5px ${props => props.theme.primary}cc;
  padding: 0.625rem 1.25rem;
  margin: 0 auto 2rem;
  max-width: 18rem;
  border-radius: 0.5rem;
  background-color: #151515;
  z-index: 999;

  @media (min-width: 120rem) {
    right: auto;
    left: 5rem;
  }
`
export const FaucetBoxTitle = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 600;
  }
`
export const FaucetBlockText = styled.div`
  font-size: 12px;
  margin-bottom: 1rem;
`
export const FaucetButtons = styled.div`
  display: flex;
  justify-content: center;
`
export const CloseButton = styled.div`
  cursor: pointer;
  color: #353945;
`
