import { EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiOutsideClickDetector } from '@elastic/eui'
// @ts-ignore
import { EuiWindowEvent } from '@elastic/eui/lib/services'
import MetaMaskLogo from 'assets/images/metamask.svg'
import OKXWalletLogo from 'assets/images/okx-wallet.jpeg'
import CasperSignerLogo from 'assets/images/casper.png'
import CasperWalletLogo from 'assets/images/casperwallet.png'
import CasperDashLogo from 'assets/images/casper-dash.png'
import LedgerLogo from 'assets/images/ledger-wallet.png'
import { useCurrentNetwork } from 'hooks'
import WalletButton from './WalletButton'
import { ConnectorNames } from '../../constants'

export type Wallet = {
  name: string
  connector: ConnectorNames
  image: string
  disable?: boolean
}

interface WalletModalProps {
  onDismiss: () => void
}

function WalletModal(props: WalletModalProps): JSX.Element {
  const { onDismiss } = props
  const sourceNetwork = useCurrentNetwork()

  const wallets: Wallet[] = [
    {
      name: 'MetaMask',
      connector: ConnectorNames.Injected,
      image: MetaMaskLogo,
      disable: sourceNetwork && sourceNetwork.notEVM,
    },
    {
      name: 'OKX Wallet',
      connector: ConnectorNames.OKXWallet,
      image: OKXWalletLogo,
      disable: sourceNetwork && sourceNetwork.notEVM,
    },
    {
      name: 'Casper Wallet',
      connector: ConnectorNames.CasperWallet,
      image: CasperWalletLogo,
      disable: sourceNetwork && !sourceNetwork.notEVM,
    },
    {
      name: 'Casper Signer',
      connector: ConnectorNames.CasperSigner,
      image: CasperSignerLogo,
      disable: sourceNetwork && !sourceNetwork.notEVM,
    },
    {
      name: 'CasperDash',
      connector: ConnectorNames.CasperDash,
      image: CasperDashLogo,
      disable: sourceNetwork && !sourceNetwork.notEVM,
    },
    {
      name: 'Ledger Wallet',
      connector: ConnectorNames.Ledger,
      image: LedgerLogo,
      disable: sourceNetwork && sourceNetwork.notEVM,
    },
  ]

  const onEscKeydown = (e: React.KeyboardEvent) => {
    if (e.key === '27') {
      onDismiss()
    }
  }

  return (
    <>
      <EuiOutsideClickDetector onOutsideClick={onDismiss}>
        <EuiModal onClose={onDismiss}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Connect wallet</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {wallets.map(w => (
              <WalletButton key={w.connector} wallet={w} onDismiss={onDismiss} />
            ))}
          </EuiModalBody>
        </EuiModal>
      </EuiOutsideClickDetector>
      <EuiWindowEvent event="keydown" handler={onEscKeydown} />
    </>
  )
}

export default WalletModal
