import { MutableRefObject } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { FixedSizeList } from 'react-window'
import Network from 'type/Network'
import NetworkRow from './NetworkRow'
import { connectorLocalStorageKey, ConnectorNames } from '../../constants'
import { setupNetwork } from 'utils'
// import ToastMessage from 'components/ToastMessage'
// import { toast } from 'react-toastify'
import { updateSourceNetwork, updateTargetNetwork } from 'state/application/actions'
import { useChainId, useConnectWalletCallback, useDeactivateCallback } from 'state/wallet/hooks'
import { useCurrentNetwork } from 'hooks'
import { updateSelectedToken, updateTokenAmount } from 'state/token/actions'
import toast from 'react-hot-toast'
import { updateConnector } from 'state/wallet/actions'

interface NetworkListProps {
  networkList: Network[]
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  side: 'SOURCE' | 'TARGET'
  selectedNetwork: Network | undefined
  otherNetwork: Network | undefined
  onSelectNetwork: () => void
  selectNetworkCallback?: (network: Network) => void
  onDismissModal?: () => void
}

function NetworkList(props: NetworkListProps): JSX.Element {
  const { onSelectNetwork, networkList, side, selectedNetwork, otherNetwork, selectNetworkCallback, onDismissModal } =
    props

  const chainId = useChainId()
  const connectWalletCallback = useConnectWalletCallback()
  const deactivate = useDeactivateCallback()
  const currentNetwork = useCurrentNetwork()
  const dispatch = useDispatch<AppDispatch>()

  const changeToEVMChain = async (network: Network) => {
    const hasSetup = await setupNetwork(network)

    if (hasSetup) {
      // @ts-ignore
      window.ethereum.on('chainChanged', () => {
        if (chainId === network.chainId && currentNetwork) {
          dispatch(updateTargetNetwork({ network: currentNetwork }))
        }
        // dispatch(updateSourceNetwork({ network }))
        dispatch(updateSelectedToken({ token: undefined }))
        dispatch(updateTokenAmount({ amount: '' }))
      })
    }
  }

  const onSetupNetwork = async (network: Network) => {
    try {
      const isBitcoin = network.name.toLowerCase().includes('bitcoin')
      const isCasper = network.name.toLowerCase().includes('casper')

      // if current network is EVM
      if (!currentNetwork?.notEVM) {
        // if change to EVM
        if (!network.notEVM) {
          await changeToEVMChain(network)
        } else if (isCasper) {
          const casperConnector =
            // @ts-ignore
            window.CasperWalletProvider !== undefined
              ? ConnectorNames.CasperWallet
              : // @ts-ignore
              window.casperDashHelper != undefined
              ? ConnectorNames.CasperDash
              : ConnectorNames.CasperSigner
          window.localStorage.setItem(connectorLocalStorageKey, casperConnector)
          dispatch(updateConnector({ connector: casperConnector }))

          await deactivate()
          await connectWalletCallback(ConnectorNames.CasperDash)
          window.location.reload()
        } else if (isBitcoin) {
          // window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Bitcoin)
          dispatch(updateConnector({ connector: ConnectorNames.Bitcoin }))
        }
      } else {
        // from Casper to EVM
        if (currentNetwork?.name.toLowerCase().includes('casper') && !network.notEVM) {
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))

          await deactivate()
          await changeToEVMChain(network)
          window.location.reload()
        }
        // from Bitcoin to EVM
        if (currentNetwork?.name.toLowerCase().includes('bitcoin') && !network.notEVM) {
          window.localStorage.removeItem(connectorLocalStorageKey)
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))
          await connectWalletCallback(ConnectorNames.Injected)
        }
      }

      dispatch(updateSourceNetwork({ network }))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error('Could not setup network')
        console.error(error)
      }
    } finally {
      onSelectNetwork()
    }
  }

  return (
    <>
      {networkList.length > 0 && (
        <div>
          <div className="eui-yScroll">
            {networkList.map(network => (
              <NetworkRow
                key={network.chainId}
                network={network}
                isSelected={Boolean(network && network.chainId === selectedNetwork?.chainId)}
                isDisabled={Boolean(network && network.chainId === otherNetwork?.chainId)}
                onSelect={async () => {
                  // on RequestClaimForm
                  if (selectNetworkCallback && onDismissModal) {
                    selectNetworkCallback(network)
                    onDismissModal()
                  } else {
                    if (network && side == 'SOURCE') {
                      onSetupNetwork(network)
                    }

                    if (side == 'TARGET') {
                      dispatch(updateTargetNetwork({ network }))
                      onSelectNetwork()
                    }
                  }
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default NetworkList
