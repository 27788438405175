import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { EuiButton, EuiButtonEmpty } from '@elastic/eui'
import { HiOutlineCheck, HiOutlineRefresh } from 'react-icons/hi'
import { Col } from 'components/Grid'
import { useCurrentNetwork } from 'hooks'
import {
  InlineNFTCollectionSelect,
  RefreshButton,
  Step2BoxTitle,
  Step2BoxRow,
  NFTGrid,
  Step2Box,
  Step2Buttons,
  YourNFTsIn,
  Step2Footer,
  SelectAll,
} from '../Styled'
import NFTItem from '../components/NFTItem'
import NFT from 'type/NFT'
import LoadingNFTItem from '../components/LoadingNFTItem'
import { updateSelectedNFTs } from 'state/nft/actions'
import { useCasperNFTListCallback, useSelectedNFTCollection, useSelectedNFTs } from 'state/nft/hooks'
import { useAccount, useChainId } from 'state/wallet/hooks'

interface Step2Props {
  prevStepCallback: () => void
  nextStepCallback: () => void
}

function Step2Casper(props: Step2Props): JSX.Element {
  const { prevStepCallback, nextStepCallback } = props

  const account = useAccount()
  const chainId = useChainId()
  const sourceNetwork = useCurrentNetwork()

  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelectedNFTCollection()
  const selectedNFTs = useSelectedNFTs()

  const ntfsCallback = useCasperNFTListCallback(account, sourceNetwork)
  const [loading, setLoading] = useState(false)
  const [nfts, setNFTs] = useState<NFT[]>([])

  const [isSelectAll, setSelectAll] = useState(false)

  const MAX_ITEMS = 1

  const onSelectAll = () => {
    if (isSelectAll) {
      dispatch(updateSelectedNFTs({ selectedNFTs: [] }))
    } else {
      dispatch(updateSelectedNFTs({ selectedNFTs: nfts }))
    }
    setSelectAll(!isSelectAll)
  }

  const onNext = () => {
    dispatch(updateSelectedNFTs({ selectedNFTs }))
    nextStepCallback()
  }

  const fetchNFTs = async (loadFromCache: boolean) => {
    try {
      setLoading(true)
      const _nfts = await ntfsCallback(loadFromCache)
      setNFTs(_nfts)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNFTs(true)
  }, [account, chainId, nftCollection])

  useEffect(() => {
    setSelectAll(selectedNFTs != undefined && selectedNFTs?.length > 0 && selectedNFTs?.length == nfts.length)
  }, [selectedNFTs])

  const onRefresh = async () => {
    dispatch(updateSelectedNFTs({ selectedNFTs: [] }))
    await fetchNFTs(false)
  }

  return (
    <>
      <Step2Box>
        <Step2BoxRow>
          <Step2BoxTitle style={{ marginBottom: 0 }}>
            <div>
              <YourNFTsIn>Your NFTs in</YourNFTsIn>
              {sourceNetwork && <InlineNFTCollectionSelect showTitle={false} />}
            </div>
            <RefreshButton onClick={onRefresh} className={loading ? 'is-loading' : ''}>
              <HiOutlineRefresh color="#154ba0" size={24} />
            </RefreshButton>
          </Step2BoxTitle>
          {selectedNFTs && (
            <>
              <span style={{ color: '#727eaa' }}>
                {selectedNFTs.length} {selectedNFTs.length > 1 ? 'items' : 'item'} selected
              </span>
            </>
          )}
        </Step2BoxRow>

        {/* NFTs Grid */}
        <NFTGrid className="eui-yScroll">
          {loading ? (
            <>
              <LoadingNFTItem />
              <LoadingNFTItem />
              <LoadingNFTItem />
              <LoadingNFTItem />
              <LoadingNFTItem />
              <LoadingNFTItem />
            </>
          ) : (
            <>
              {selectedNFTs && nfts.length > 0 ? (
                <>
                  {nfts.map(nft => (
                    <NFTItem
                      key={nft.tokenId}
                      nft={nft}
                      isSelected={selectedNFTs.findIndex(n => n.tokenId == nft.tokenId) >= 0}
                    />
                  ))}
                </>
              ) : (
                <Col>No NFTs found.</Col>
              )}
            </>
          )}
        </NFTGrid>

        <Step2Footer>
          <SelectAll onClick={onSelectAll} className={isSelectAll ? 'is-selected' : ''}>
            <HiOutlineCheck />
            <span style={{ color: '#727eaa' }}>Select All</span>
          </SelectAll>
          <Step2Buttons>
            <EuiButtonEmpty onClick={prevStepCallback}>Back</EuiButtonEmpty>
            <EuiButton
              fill
              iconSide="right"
              iconType="arrowRight"
              isDisabled={selectedNFTs && (selectedNFTs?.length <= 0 || selectedNFTs?.length > MAX_ITEMS)}
              onClick={onNext}
            >
              {selectedNFTs?.length <= MAX_ITEMS ? 'Next' : `Max ${MAX_ITEMS} ${MAX_ITEMS > 1 ? 'items' : 'item'}`}
            </EuiButton>
          </Step2Buttons>
        </Step2Footer>
      </Step2Box>
    </>
  )
}

export default Step2Casper
