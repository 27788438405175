import { Contract } from 'ethers'
import { useTokenContract } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { useSelector } from 'react-redux'
import Token from 'type/Token'
import { AppState } from 'state'

// undefined if invalid or does not exist
// otherwise returns the token
const getTokenInfo = async (tokenContract: Contract | null): Promise<Token | undefined> => {
  try {
    const tokenName = await tokenContract?.name()
    const symbol = await tokenContract?.symbol()
    const decimals = await tokenContract?.decimals()

    if (!decimals || !symbol || !tokenName) return undefined

    return {
      name: tokenName,
      symbol,
      decimals: Number(decimals),
      logoURI: '',
    } as Token
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export const useToken = (tokenAddress?: string): Token | undefined => {
  const [tokenInfo, setTokenInfo] = useState<Token>()

  const tokenContract = useTokenContract(tokenAddress)

  useEffect(() => {
    const fetchData = async () => {
      const result = await getTokenInfo(tokenContract)
      setTokenInfo(result)
      // const tokens = await getTokensFromConfig(chainId)
      // const _token = tokens.find(t => t.address === tokenAddress)

      // if (_token) {
      //   setTokenInfo(_token)
      // }
    }

    fetchData()
  }, [tokenAddress, tokenContract])

  return useMemo(() => {
    if (!tokenInfo) return undefined

    return {
      ...tokenInfo,
      address: tokenAddress,
    } as Token
  }, [tokenAddress, tokenInfo])
}

export const useIsUserAddedToken = (token: Token): boolean => {
  const account = useAccount()
  const chainId = useChainId()

  const data = localStorage.getItem(`tokens_${account}_${chainId}`)

  if (!data) {
    return false
  }

  const tokens = JSON.parse(data) as Token[]
  return !!tokens.find(t => t.address === token.address)
}

export const useSelectedToken = (): Token | undefined => {
  const selectedToken = useSelector((state: AppState) => state.token.selectedToken)
  return useMemo(() => selectedToken, [selectedToken])
}

export const useTokenAmount = (): number => {
  const amount = useSelector((state: AppState) => state.token.tokenAmount)
  return useMemo(() => Number(amount), [amount])
}
