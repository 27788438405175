import {
  EuiOutsideClickDetector,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFieldSearch,
} from '@elastic/eui'
import { useAllNetworks, useCurrentNetwork, useDebounce } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelectedToken } from 'state/token/hooks'
import styled from 'styled-components/macro'
import Network from 'type/Network'
import { filterNetworks } from './filtering'
import NetworkList from './NetworkList'

const StyledEuiModal = styled(EuiModal)`
  .euiFormControlLayout__childrenWrapper {
    height: auto;
  }
`
const NoResultsFound = styled.p`
  margin-top: 1.5rem;
  text-align: center;
  color: #6c7284;
`

interface NetworksModalProps {
  title: string
  side: 'SOURCE' | 'TARGET'
  selectedNetwork: Network | undefined
  otherNetwork: Network | undefined
  onDismiss: () => void
  requestClaim?: boolean
  nftBridge?: boolean
  showAllNetworks?: boolean
  selectNetworkCallback?: (network: Network) => void
}

function NetworksModal(props: NetworksModalProps): JSX.Element {
  const {
    title,
    side,
    selectedNetwork,
    otherNetwork,
    onDismiss,
    requestClaim,
    nftBridge,
    showAllNetworks,
    selectNetworkCallback,
  } = props

  const selectedToken = useSelectedToken()
  const currentNetwork = useCurrentNetwork()
  const networks = useAllNetworks(currentNetwork ? currentNetwork?.isMainnet : true)

  const [networkList, setNetworkList] = useState<Network[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const handleInput = useCallback((event: any) => {
    const input = event.target.value
    setSearchQuery(input)
  }, [])

  const handleSelect = () => {
    onDismiss()
  }

  const filteredNetworks: Network[] = useMemo(() => {
    // only EVM chain if we use RequestClaimForm
    if (selectNetworkCallback && requestClaim) {
      return filterNetworks(
        networkList.filter(n => !n.notEVM),
        debouncedQuery,
      )
    }

    // remove Bitcoin if we use in NFT Bridge
    if (nftBridge) {
      return filterNetworks(
        networkList.filter(n => !n.name.toLowerCase().includes('bitcoin')),
        debouncedQuery,
      )
    }
    return filterNetworks(networkList, debouncedQuery)
  }, [networkList, debouncedQuery])

  useEffect(() => {
    const supportedChainIds = requestClaim ? [] : selectedToken?.supportedChainIds ?? []
    const _supportedNetworks = showAllNetworks
      ? networks
      : networks.filter(n => (supportedChainIds.length > 0 ? supportedChainIds.includes(n.chainId) : n))

    setNetworkList(_supportedNetworks)
  }, [selectedToken])

  return (
    <>
      <EuiOutsideClickDetector onOutsideClick={onDismiss}>
        <StyledEuiModal onClose={onDismiss}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>{title}</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFieldSearch
              name="search-network"
              value={searchQuery}
              autoComplete="off"
              autoFocus={true}
              placeholder="Search name or chain id"
              onChange={handleInput}
            />
            {filteredNetworks.length > 0 ? (
              <NetworkList
                networkList={filteredNetworks}
                side={side}
                selectedNetwork={selectedNetwork}
                otherNetwork={otherNetwork}
                onSelectNetwork={handleSelect}
                selectNetworkCallback={selectNetworkCallback}
                onDismissModal={onDismiss}
              />
            ) : (
              <NoResultsFound>No results found.</NoResultsFound>
            )}
          </EuiModalBody>
        </StyledEuiModal>
      </EuiOutsideClickDetector>
    </>
  )
}

export default NetworksModal
