import { EuiFieldText } from '@elastic/eui'
import styled from 'styled-components/macro'

export const InputField = styled(EuiFieldText)`
  padding: 1.25rem 1rem;
  background-color: #727eaa;
`

export const DarkerInputField = styled.div`
  .euiButtonEmpty {
    background-color: #727eaa;
  }
`
