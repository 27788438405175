import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOutsideClickDetector,
  EuiToolTip,
} from '@elastic/eui'
import Slider from 'react-slick'
import { Col50 } from 'components/Grid/Styled'
import { NFTImageInfo, NFTInfoList } from 'pages/nft-bridge/Styled'
import { useState } from 'react'
import styled from 'styled-components/macro'
import NFT from 'type/NFT'
import { Row } from 'components/Grid'

const NFTImageList = styled.ul`
  width: 100%;
  position: relative;

  li:first-child {
    position: relative;
    z-index: 3;
  }

  li:nth-child(2) {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    z-index: 2;
  }

  li:nth-child(3) {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 1;
  }
`

const NFTImage = styled.img`
  cursor: pointer;
  display: inline-block !important;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  position: relative;
`

function NFTsModal({ nftData, onDismiss }: { nftData: NFT[]; onDismiss: () => void }): JSX.Element {
  return (
    <EuiOutsideClickDetector onOutsideClick={onDismiss}>
      <EuiModal onClose={onDismiss}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>NFT Details</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody className="no-overflow">
          <Slider dots={true} arrows={false} infinite={true} slidesToShow={1} autoplay={true}>
            {nftData.map(nft => {
              return (
                <div key={nft.tokenId}>
                  <Row style={{ marginTop: 0, marginBottom: 0 }} key={nft.tokenId}>
                    <Col50>
                      <NFTImageInfo src={nft.image} alt={nft.name} />
                    </Col50>
                    <Col50>
                      <NFTInfoList className="eui-yScroll">
                        <p>
                          <strong>Name:</strong> {nft.name}
                        </p>
                        <p>
                          <strong>Token ID:</strong> {nft.tokenId}
                        </p>
                        <p>
                          <strong>Collection Name:</strong> {nft.collectionName}
                        </p>
                        <p>
                          <strong>Symbol:</strong> {nft.collectionSymbol}
                        </p>
                        {nft.attributes && (
                          <>
                            {nft.attributes.map((attr: any) => (
                              <p key={attr.trait_type}>
                                <strong>{attr.trait_type}:</strong> {attr.value}
                              </p>
                            ))}
                          </>
                        )}
                      </NFTInfoList>
                    </Col50>
                  </Row>
                </div>
              )
            })}
          </Slider>
        </EuiModalBody>
      </EuiModal>
    </EuiOutsideClickDetector>
  )
}

function NFTData({ nftData }: { nftData: NFT[] }): JSX.Element {
  const [showNFTsModal, setShowNFTsModal] = useState(false)
  return (
    <EuiToolTip content="Click for details">
      <>
        <NFTImageList onClick={() => setShowNFTsModal(true)}>
          {nftData.map((nft, index) => (
            <li key={nft.tokenId}>{index < 3 && <NFTImage src={nft.image} alt={nft.name} />}</li>
          ))}
        </NFTImageList>
        {showNFTsModal && <NFTsModal nftData={nftData} onDismiss={() => setShowNFTsModal(false)} />}
      </>
    </EuiToolTip>
  )
}

export default NFTData
