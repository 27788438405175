import { TransactionResponse } from '@ethersproject/providers'
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import axios, { AxiosResponse } from 'axios'
import BridgeAppContext from 'context/BridgeAppContext'
import { ethers, Contract, BigNumber } from 'ethers'
import { useCallback, useContext } from 'react'
import EthApp from '@ledgerhq/hw-app-eth'
import { useChainId } from 'state/wallet/hooks'
import { useBridgeContract, useERC20CasperContract } from './useContract'
import { useCurrentNetwork } from './useNetwork'
import BRIDGE_ABI from '../constants/abi/GenericBridge.abi.json'
import { useBridgeAddress } from 'state/application/hooks'

export function useRequestWidthrawAPI(): (
  requestHash: string,
  fromChainId: number,
  toChainId: number,
  index: number,
) => Promise<AxiosResponse<any>> {
  const callback = useCallback(
    async (requestHash: string, fromChainId: number, toChainId: number, index: number): Promise<any> => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/request-withdraw`,
        {
          requestHash,
          fromChainId,
          toChainId,
          index,
        },
        {
          timeout: 300000,
        },
      )
    },
    [],
  )

  return callback
}

export function useClaimTokenCallback(): (
  response: AxiosResponse<any>,
  toAddress: string,
  requestHash: string,
  originChainId: number,
  fromChainId: number,
  toChainId: number,
  index: number,
  originToken: string,
  amount: string,
  handleTransactionResponse: (response: TransactionResponse) => void,
) => Promise<any> {
  const { ledgerAddress, ledgerApp, ledgerPath } = useContext(BridgeAppContext)
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()

  const bridgeAddress = useBridgeAddress()

  const bridgeContract = useBridgeContract(bridgeAddress)

  const callback = useCallback(
    async (
      response: AxiosResponse<any>,
      toAddress: string,
      requestHash: string,
      originChainId: number,
      fromChainId: number,
      toChainId: number,
      index: number,
      originToken: string,
      amount: string,
      handleTransactionResponse: (response: TransactionResponse) => void,
    ): Promise<any> => {
      const sign = response.data
      const { name, symbol, decimals, r, s, v } = sign
      const chainIdData = [originChainId, fromChainId, toChainId, index]

      if (bridgeContract) {
        if (ledgerAddress != '') {
          const provider = new ethers.providers.JsonRpcProvider(currentNetwork?.rpcURL)
          const bridgeContractEth = new Contract(bridgeAddress ?? '', BRIDGE_ABI, provider)
          const { data } = await bridgeContractEth.populateTransaction[
            'claimToken(address,address,uint256,uint256[],bytes32,bytes32[],bytes32[],uint8[],string,string,uint8)'
          ](originToken, toAddress, amount, chainIdData, requestHash, r, s, v, name, symbol, decimals)

          const unsignedTx = {
            to: bridgeAddress,
            gasPrice: (await provider.getGasPrice())._hex,
            gasLimit: ethers.utils.hexlify(500000),
            value: BigNumber.from(0),
            nonce: await provider.getTransactionCount(toAddress, 'latest'),
            chainId: toChainId,
            data,
          }
          const transport = await TransportWebUSB.openConnected()
          if (transport != null && ledgerApp && ledgerApp instanceof EthApp) {
            const serializedTx = ethers.utils.serializeTransaction(unsignedTx).slice(2)

            const _signature = await ledgerApp.signTransaction(ledgerPath, serializedTx)
            const signature = {
              r: '0x' + _signature.r,
              s: '0x' + _signature.s,
              v: parseInt('0x' + _signature.v),
              from: toAddress,
            }
            const signedTx = ethers.utils.serializeTransaction(unsignedTx, signature)
            return provider.sendTransaction(signedTx).then((txResponse: TransactionResponse) => {
              handleTransactionResponse(txResponse)
            })
          }
        } else {
          return bridgeContract.claimToken(
            originToken,
            toAddress,
            amount,
            chainIdData,
            requestHash,
            r,
            s,
            v,
            name,
            symbol,
            decimals,
          )
        }
      }
    },
    [chainId, bridgeContract],
  )

  return callback
}

export function useClaimTokenOriginCasperCallback(
  tokenAddress: string | undefined,
): (
  response: AxiosResponse<any>,
  toAddress: string,
  txHash: string,
  originChainId: number,
  fromChainId: number,
  toChainId: number,
  index: number,
  amount: string,
  handleTransactionResponse: (response: TransactionResponse) => void,
) => Promise<any> {
  const { ledgerAddress, ledgerApp, ledgerPath } = useContext(BridgeAppContext)
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()

  const tokenContract = useERC20CasperContract(tokenAddress)

  const callback = useCallback(
    async (
      response: AxiosResponse<any>,
      toAddress: string,
      txHash: string,
      originChainId: number,
      fromChainId: number,
      toChainId: number,
      index: number,
      amount: string,
      handleTransactionResponse: (response: TransactionResponse) => void,
    ): Promise<any> => {
      const sign = response.data
      const { r, s, v } = sign
      const chainIdData = [originChainId, fromChainId, toChainId, index]

      if (tokenContract) {
        if (ledgerAddress != '') {
          const provider = new ethers.providers.JsonRpcProvider(currentNetwork?.rpcURL)
          const bridgeContractEth = new Contract(tokenAddress ?? '', BRIDGE_ABI, provider)
          const { data } = await bridgeContractEth.populateTransaction[
            'claimToken(address,uint256,uint256[],bytes32,bytes32[],bytes32[],uint8[])'
          ](toAddress, amount, chainIdData, txHash, r, s, v)

          const unsignedTx = {
            to: tokenAddress,
            gasPrice: (await provider.getGasPrice())._hex,
            gasLimit: ethers.utils.hexlify(500000),
            value: BigNumber.from(0),
            nonce: await provider.getTransactionCount(toAddress, 'latest'),
            chainId: toChainId,
            data,
          }
          const transport = await TransportWebUSB.openConnected()
          if (transport != null && ledgerApp && ledgerApp instanceof EthApp) {
            const serializedTx = ethers.utils.serializeTransaction(unsignedTx).slice(2)

            const _signature = await ledgerApp.signTransaction(ledgerPath, serializedTx)
            const signature = {
              r: '0x' + _signature.r,
              s: '0x' + _signature.s,
              v: parseInt('0x' + _signature.v),
              from: toAddress,
            }
            const signedTx = ethers.utils.serializeTransaction(unsignedTx, signature)
            return provider.sendTransaction(signedTx).then((txResponse: TransactionResponse) => {
              handleTransactionResponse(txResponse)
            })
          }
        } else {
          return tokenContract.claimToken(toAddress, amount, chainIdData, txHash, r, s, v)
        }
      } else {
        throw Error('Invalid contract address')
      }
    },
    [chainId, tokenContract],
  )

  return callback
}
