import { useCallback, useState } from 'react'
import { EuiButton } from '@elastic/eui'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { connectorLocalStorageKey, ConnectorNames } from '../../constants'
import { useCurrentNetwork, useNetworkInfo, useNFTContract } from 'hooks'
import { useChainId, useConnectWalletCallback, useDeactivateCallback } from 'state/wallet/hooks'
import { setupNetwork } from 'utils'
import { updateSourceNetwork, updateTargetNetwork } from 'state/application/actions'
import { NFTType } from 'type/NFTCollection'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from 'state/transactions/hooks'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'

const getNFTAddress = (chainId: number | undefined): string => {
  switch (chainId) {
    case 5: // Goerli
      return '0x6F6721c1F1E7C35aae4C59352B436E62bA04bD62'
    case 97: // BSC Testnet
      return '0xAFCCAD0624A148377953D39c6785817a6f25683a'
    case 80001: // Mumbai Matic
      return '0xc1257078d620eC14c435B089Af938E77662Ff027'
    case 43113: // Avax Testnet
      return '0x564D49e680DE8C34d3aB34c0976eb8FF0272a4a8'
    default:
      return ''
  }
}

function MintButtonEVM(): JSX.Element {
  const [minting, setMinting] = useState(false)

  const chainId = useChainId()
  const nftContract = useNFTContract(getNFTAddress(chainId), NFTType.ERC721)
  const network = useCurrentNetwork()
  const connectWalletCallback = useConnectWalletCallback()
  const deactivate = useDeactivateCallback()
  const avaxNetwork = useNetworkInfo(43113)

  const dispatch = useDispatch<AppDispatch>()

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const supportedChainIds = [5, 97, 80001, 43113]

  const onMintNFt = async () => {
    try {
      let hasSetup = false

      if (network && avaxNetwork && chainId && !supportedChainIds.includes(chainId)) {
        window.localStorage.removeItem(connectorLocalStorageKey)

        if (network.notEVM) {
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)
          await deactivate()
          await connectWalletCallback(ConnectorNames.Injected)
          window.location.reload()
        } else {
          hasSetup = await setupNetwork(avaxNetwork)

          if (hasSetup) {
            // @ts-ignore
            window.ethereum.on('chainChanged', () => {
              if (chainId === avaxNetwork.chainId) {
                dispatch(updateTargetNetwork({ network }))
              }
              dispatch(updateSourceNetwork({ network: avaxNetwork }))
            })
          }
        }
      }

      if (nftContract && chainId && supportedChainIds.includes(chainId)) {
        setMinting(true)
        setShowConfirm(true)
        setAttemptingTxn(true)

        nftContract
          .mint()
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Mint NFT`,
            })

            setTxHash(response.hash)

            response.wait().then(async () => {
              setAttemptingTxn(false)
              setMinting(false)
            })
          })
          .catch((error: any) => {
            setShowConfirm(false)
            setAttemptingTxn(false)
            // we only care if the error is something _other_ than the user rejected the tx
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    } finally {
      setMinting(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <EuiButton onClick={onMintNFt} isLoading={minting}>
        Mint
      </EuiButton>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Mint NFT"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default MintButtonEVM
