import { EuiFlexGroup } from '@elastic/eui'
import { BiLinkExternal } from 'react-icons/bi'
import { useLocation } from 'react-router-dom'
import NAV_ITEMS from './items'
import { MenuA, MenuItem, MenuLink, SubText } from './Styled'

function DesktopNav(): JSX.Element {
  const location = useLocation()
  const { pathname } = location
  let realPathName = pathname.substring(1)

  if (pathname == '/') {
    realPathName = 'home'
  }

  return (
    <EuiFlexGroup
      gutterSize="none"
      alignItems="center"
      justifyContent="center"
      className="eui-showFor--l--flex eui-showFor--xl--flex"
    >
      {NAV_ITEMS.map(navItem => (
        <MenuItem key={navItem.label} grow={false}>
          {navItem.href && (
            <MenuA
              href={navItem.href}
              target={navItem.target ?? '_self'}
              className={`${pathname.includes(navItem.href) && 'active'}`}
            >
              {navItem.label}
              {navItem.target === '_blank' && <BiLinkExternal />}
            </MenuA>
          )}
          {navItem.to && (
            <MenuLink to={navItem.to} className={`${realPathName.includes(navItem.slug ?? '') && 'active'}`}>
              {navItem.label}
            </MenuLink>
          )}
          {navItem.subLabel && <SubText>{navItem.subLabel}</SubText>}
        </MenuItem>
      ))}
    </EuiFlexGroup>
  )
}

export default DesktopNav
