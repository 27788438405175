import { CommonProps } from '@elastic/eui'
import { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

const StyledContainer = styled.div`
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1280px;

  > .euiFlexGroup {
    height: 100%;
  }
`

export function Container(props: CommonProps & HTMLAttributes<HTMLDivElement | HTMLSpanElement>): JSX.Element {
  return <StyledContainer {...props}>{props.children}</StyledContainer>
}
