import { FiChevronRight } from 'react-icons/fi'
import { Row } from 'components/Grid'
import { LatestTransactionsLink, Reminder, StyledButton, RemindText } from './Styled'
import { Link } from 'react-router-dom'
import { BoxTitle } from 'components/Box/Styled'
import TokenSelect from './components/TokenSelect'
import { useCurrentNetwork, useTargetNetwork } from 'hooks'
import NetworkSelect from 'components/NetworkSelect'
import { SwapBtn } from 'pages/nft-bridge/Styled'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { getTokensFromConfigMemoized, setupNetwork } from 'utils'
import { useAccount, useChainId, useConnectWalletCallback } from 'state/wallet/hooks'
import { updateSourceNetwork, updateTargetNetwork } from 'state/application/actions'
import { useEffect, useState } from 'react'
import WalletModal from 'components/WalletModal'
import { BsLightbulb } from 'react-icons/bs'
import { useSelectedToken } from 'state/token/hooks'
import { formatUnits } from 'ethers/lib/utils'
import { Spacer } from 'theme/components'
import { updateSelectedToken, updateTokenAmount } from 'state/token/actions'
import { ConnectorNames, connectorLocalStorageKey } from '../../constants'
import { updateConnector } from 'state/wallet/actions'
import MintWBTC from 'components/FaucetBox/MintWBTC'
import BuyModal from 'components/BuyModal'
import { MdOutlineSwapVert } from 'react-icons/md'

interface Step1Props {
  nextStepCallback: () => void
}

function Step1(props: Step1Props): JSX.Element {
  const { nextStepCallback } = props

  const account = useAccount()
  const chainId = useChainId()
  const dispatch = useDispatch<AppDispatch>()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()
  const selectedToken = useSelectedToken()
  const connectWalletCallback = useConnectWalletCallback()

  const [showWalletModal, setShowWalletModal] = useState(false)
  const [showBuyModal, setShowBuyModal] = useState(false)
  const [disableButton, setDisableButton] = useState(
    sourceNetwork === undefined || targetNetwork === undefined || selectedToken === undefined,
  )
  const [minBridge, setMinBridge] = useState('0')

  const swapSourceAndTarget = async () => {
    if (!sourceNetwork || !targetNetwork) {
      return
    }

    // if swap between EVM chains
    if (!sourceNetwork?.notEVM && !targetNetwork.notEVM) {
      const hasSetup = await setupNetwork(targetNetwork)

      if (hasSetup) {
        // @ts-ignore
        window.ethereum.on('chainChanged', () => {
          dispatch(updateSourceNetwork({ network: targetNetwork }))
          dispatch(updateTargetNetwork({ network: sourceNetwork }))

          if (!selectedToken?.supportedChainIds?.includes(targetNetwork.chainId)) {
            dispatch(updateSelectedToken({ token: undefined }))
          }
          dispatch(updateTokenAmount({ amount: '0' }))
        })
      }
    }

    // if swap between EVM & non-EVM chains
    if (sourceNetwork.notEVM != targetNetwork.notEVM) {
      // if target is not EVM
      if (targetNetwork.notEVM) {
        const targetNetworkName = targetNetwork.name.toLowerCase()
        const isBitcoin = targetNetworkName.includes('bitcoin')
        const isCasper = targetNetworkName.includes('casper')

        if (isCasper) {
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.CasperSigner)
          dispatch(updateConnector({ connector: ConnectorNames.CasperSigner }))

          await connectWalletCallback(
            // @ts-ignore
            window.casperDashHelper !== undefined ? ConnectorNames.CasperDash : ConnectorNames.CasperSigner,
          )
        }

        if (isBitcoin) {
          // window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Bitcoin)
          dispatch(updateConnector({ connector: ConnectorNames.Bitcoin }))
        }
      } else {
        // if target is EVM
        window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)
        dispatch(updateConnector({ connector: ConnectorNames.Injected }))
      }

      dispatch(updateSourceNetwork({ network: targetNetwork }))
      dispatch(updateTargetNetwork({ network: sourceNetwork }))

      let _token = undefined
      if (selectedToken?.supportedChainIds?.includes(targetNetwork.chainId)) {
        dispatch(updateSelectedToken({ token: undefined }))
        const tokens = await getTokensFromConfigMemoized(account, targetNetwork.chainId)
        // get token in sourceNetwork match in targetNetwork
        _token = tokens.find(t => t.originChainId === sourceNetwork.chainId)

        if (!_token) {
          _token = tokens.find(t => t.address === selectedToken.originContractAddress)
        }
      }
      dispatch(updateSelectedToken({ token: _token }))
      // window.location.reload()
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      let _disableButton = false

      if (selectedToken === undefined) {
        _disableButton = true
      }
      setDisableButton(_disableButton)
    })()
  }, [chainId, sourceNetwork, targetNetwork, selectedToken])

  useEffect(() => {
    const _minBridge = selectedToken ? selectedToken.minBridge ?? '0' : '0'
    setMinBridge(_minBridge)
  }, [selectedToken])

  return (
    <>
      {!sourceNetwork?.isMainnet && <MintWBTC />}
      <LatestTransactionsLink>
        {account && (
          <Link to="/explorer/my-transactions">
            <span>My transactions</span>
            <FiChevronRight size={24} />
          </Link>
        )}
      </LatestTransactionsLink>
      {/* {!sourceNetwork?.name.toLowerCase().includes('casper') && (
          <LatestTransactionsLink style={{ cursor: 'pointer' }} onClick={() => setShowBuyModal(true)}>
            <span>Buy token</span>
            <FiChevronRight size={24} />
          </LatestTransactionsLink>
        )} */}
      <div>
        <TokenSelect showNativeToken={!sourceNetwork?.name.toLowerCase().includes('casper')} />
        <Spacer />
        <NetworkSelect selectedNetwork={sourceNetwork} otherNetwork={targetNetwork} side="SOURCE" showTitle={true} />
        <Row justifyContent="center">
          <SwapBtn onClick={swapSourceAndTarget}>
            <MdOutlineSwapVert />
          </SwapBtn>
        </Row>
        <NetworkSelect selectedNetwork={targetNetwork} otherNetwork={sourceNetwork} side="TARGET" showTitle={true} />
        {account ? (
          <StyledButton fill disabled={disableButton} onClick={nextStepCallback}>
            {targetNetwork == undefined ? 'Select destination chain' : 'Continue bridging'}
          </StyledButton>
        ) : (
          <>
            <StyledButton fill onClick={() => setShowWalletModal(true)}>
              Connect Wallet
            </StyledButton>
            {showWalletModal && <WalletModal onDismiss={() => setShowWalletModal(false)} />}
          </>
        )}
        <Reminder>
          <div>
            <BsLightbulb color="#727eaa" />
            <span style={{ color: '#727eaa' }}>&nbsp;Reminder:</span>
          </div>
          <ul>
            <RemindText>Bridge Fee: 0.1%</RemindText>
            {selectedToken && minBridge != '0' && !sourceNetwork?.notEVM && (
              <RemindText>
                Minimum Bridge Amount: {formatUnits(minBridge, selectedToken.decimals)}{' '}
                {selectedToken.symbol.toUpperCase()}
              </RemindText>
            )}
            <RemindText>Estimated Time of Crosschain Arrival: 3-10 mins</RemindText>
            {sourceNetwork?.notEVM && targetNetwork && !targetNetwork.notEVM && (
              <RemindText>
                There is no minimum cross-chain transfer amount to {targetNetwork.name}, you should always claim your
                tokens to {targetNetwork.name}
              </RemindText>
            )}
          </ul>
        </Reminder>
      </div>
      {showBuyModal && <BuyModal title="Buy CSPR" onDismiss={() => setShowBuyModal(false)} />}
    </>
  )
}

export default Step1
