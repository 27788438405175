/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'
import EthApp from '@ledgerhq/hw-app-eth'
import CasperApp from '@zondax/ledger-casper'

type BridgeAppContextType = {
  ledgerAddress: string
  setLedgerAddress: (value: string) => void
  ledgerPath: string
  setLedgerPath: (value: string) => void
  ledgerApp: EthApp | CasperApp | undefined
  setLedgerApp: (value: EthApp | CasperApp) => void
}

const BridgeAppContext = createContext<BridgeAppContextType>({
  ledgerAddress: '',
  setLedgerAddress: () => {},
  ledgerPath: '',
  setLedgerPath: () => {},
  ledgerApp: undefined,
  setLedgerApp: () => {},
})
export default BridgeAppContext
