import { EuiHealth, EuiSpacer, EuiTab, EuiTabs, Search } from '@elastic/eui'
import { Col, Container, Row } from 'components/Grid'
import NFTTransactionTable from 'components/TransactionTable/NFTTransactionTable'
import { useAllNetworks, useCurrentNetwork, useMyNFTTransactions, useMyTransactions } from 'hooks'
import { useEffect, useState } from 'react'
import Network from 'type/Network'
import NetworkInfo from 'components/TransactionTable/NetworkInfo'
import TokenTransactionTable from 'components/TransactionTable/TokenTransactionTable'
import { Col20 } from 'components/Grid/Styled'
import { Link } from 'react-router-dom'
import RequestClaimForm from 'components/RequestClaimForm'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { StyledButton } from 'pages/bridge/Styled'
import WalletModal from 'components/WalletModal'
import { parseResponseToNFTTransactions, parseResponseToTransactions } from 'utils/transactions'
import NFTTransaction from 'type/NFTTransaction'
import Transaction from 'type/Transaction'
import Background1 from 'assets/images/bg-1.svg'

function MyTransactions(): JSX.Element {
  // Define search for tables
  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const networks = useAllNetworks(currentNetwork?.isMainnet)

  const [isLoading, setIsLoading] = useState(false)
  const [showWalletModal, setShowWalletModal] = useState(false)
  const [transactions, setTranstractions] = useState<Transaction[]>([])
  const [nftTransactions, setNFTTranstractions] = useState<NFTTransaction[]>([])
  const { data, error: srwError } = useMyTransactions(account)
  const { data: nftData, error: nftSRWError } = useMyNFTTransactions(account)

  const search = {
    box: {
      incremental: true,
      placeholder: 'Enter the transaction hash...',
    },
    filters: [
      {
        type: 'field_value_selection',
        field: 'claimed',
        name: 'Status',
        multiSelect: false,
        options: [
          {
            value: true,
            name: 'Success',
            view: <EuiHealth color="success">Success</EuiHealth>,
          },
          {
            value: false,
            name: 'Awaiting claim',
            view: <EuiHealth color="warning">Awaiting claim</EuiHealth>,
          },
        ],
      },
      {
        type: 'field_value_selection',
        field: 'fromChainId',
        name: 'From Network',
        multiSelect: false,
        options: networks.map((network: Network) => ({
          value: network.chainId,
          name: network.name,
          view: <NetworkInfo network={network} className="networkInfo" />,
        })),
      },
      {
        type: 'field_value_selection',
        field: 'toChainId',
        name: 'To Network',
        multiSelect: false,
        options: networks.map((network: Network) => ({
          value: network.chainId,
          name: network.name,
          view: <NetworkInfo network={network} className="networkInfo" />,
        })),
      },
    ],
  } as Search

  // Build tabs
  const [selectedTabId, setSelectedTabId] = useState('token')

  const tabs = [
    {
      id: 'token',
      name: 'ERC20 Token',
      content: (
        <>
          <EuiSpacer />
          <TokenTransactionTable
            search={search}
            getAllTxns={false}
            transactions={transactions}
            error={srwError}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      id: 'nft',
      name: 'NFT',
      content: (
        <>
          <EuiSpacer />
          <NFTTransactionTable
            search={search}
            getAllTxns={false}
            transactions={nftTransactions}
            error={nftSRWError}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      id: 'tools',
      name: 'Tools',
      content: (
        <>
          <EuiSpacer />
          <RequestClaimForm />
        </>
      ),
    },
  ]

  const selectedTabContent = tabs.find(obj => obj.id === selectedTabId)?.content

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        setIsLoading(true)
        setSelectedTabId('token')
        const _transtractions = await parseResponseToTransactions(data, chainId)
        const _nftTransactions = await parseResponseToNFTTransactions(nftData, chainId)
        setTranstractions(_transtractions)
        setNFTTranstractions(_nftTransactions)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [account, chainId, data, nftData])

  return (
    <Container>
      <Row justifyContent="center" style={{ marginTop: '2rem' }}>
        <>
          {!account ? (
            <Col20>
              <StyledButton fill onClick={() => setShowWalletModal(true)}>
                Connect Wallet
              </StyledButton>
              {showWalletModal && <WalletModal onDismiss={() => setShowWalletModal(false)} />}
            </Col20>
          ) : (
            <Col>
              <EuiTabs
                style={{
                  backgroundImage: `url(${Background1})`,
                  backgroundSize: '170px 150px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right center',
                }}
              >
                <h3>My Transactions</h3>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#4364a5',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}
                >
                  {tabs.map((tab: any, index: number) => (
                    <EuiTab
                      key={index}
                      onClick={() => onSelectedTabChanged(tab.id)}
                      isSelected={tab.id == selectedTabId}
                    >
                      {tab.name}
                    </EuiTab>
                  ))}
                </div>
                <Link to="/explorer">Back to Explorer</Link>
              </EuiTabs>
              {selectedTabContent}
            </Col>
          )}
        </>
      </Row>
    </Container>
  )
}

export default MyTransactions
