import { EuiButton } from '@elastic/eui'
import LedgerModal from 'components/LedgerModal/LedgerModal'
import { ConnectorNames } from '../../constants'
import { useCurrentNetwork } from 'hooks'
import { useState } from 'react'
import { useConnectWalletCallback } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import { setupNetwork } from 'utils'
import { Wallet } from '.'

const StyledButton = styled(EuiButton)`
  margin-bottom: 1rem;
  width: 100%;
  height: auto;
  background-color: #f1f2f6;
  color: rgba(114, 126, 170, 0.6);
  border: 1px solid rgba(23, 53, 163, 0.5);
  border-radius: 8px;

  .euiButton__content {
    padding: 1rem;
    color: #727eaa !important;
  }
  .euiButton__text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    span {
      color: #727eaa;
    }
  }

  &:hover,
  &:focus {
    background-color: #727eaa;
    border-color: #fff;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.euiButton-isDisabled {
    border: 1px solid rgba(23, 53, 163, 0.1);
    .euiButton__text {
      color: #757678;

      span {
        color: #757678;
      }
    }

    img {
      opacity: 0.4;
    }
  }
`

const WalletLogo = styled.img`
  height: 2rem;
  border-radius: 100%;
`

function WalletButton({ wallet, onDismiss }: { wallet: Wallet; onDismiss: () => void }): JSX.Element {
  const sourceNetwork = useCurrentNetwork()
  const connectWalletCallback = useConnectWalletCallback()
  const [loading, setLoading] = useState(false)
  const [showLedgerModal, setShowLedgerModal] = useState(false)

  const onClick = async () => {
    setLoading(true)

    if (sourceNetwork && !sourceNetwork.notEVM && wallet.connector !== ConnectorNames.Ledger) {
      await setupNetwork(sourceNetwork)
    }
    await connectWalletCallback(wallet.connector)
    onDismiss()
  }

  const closeLedgerModal = () => {
    setShowLedgerModal(false)
    setLoading(false)
  }

  return (
    <>
      <StyledButton isDisabled={wallet.disable} isLoading={loading} onClick={onClick}>
        <span>{wallet.name}</span>
        <WalletLogo src={wallet.image} alt={wallet.name} />
      </StyledButton>
      {showLedgerModal && <LedgerModal onDismiss={closeLedgerModal} />}
    </>
  )
}

export default WalletButton
