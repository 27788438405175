import { useState } from 'react'
import { InputTitle, SelectButton, WrappedTag } from 'theme/components'
import NFTCollectionModal from './NFTCollectionModal'
import { NFTCollectionName } from '../Styled'
import { useSelectedNFTCollection } from 'state/nft/hooks'
import { useChainId } from 'state/wallet/hooks'

interface NFTCollectionSelectProps {
  showTitle: boolean
}

function NFTCollectionSelect(props: NFTCollectionSelectProps): JSX.Element {
  const { showTitle } = props

  const nftCollection = useSelectedNFTCollection()
  const chainId = useChainId()

  const [showNFTCollectionModal, setShowNFTCollectionModal] = useState(false)

  return (
    // @ts-ignore
    <div className={props.className}>
      {showTitle && <InputTitle>NFT Collection</InputTitle>}
      <SelectButton iconType="arrowDown" iconSide="right" onClick={() => setShowNFTCollectionModal(true)}>
        {nftCollection ? (
          <NFTCollectionName className={showTitle ? '' : 'ellipsis'}>
            {nftCollection.name}
            {nftCollection.originChainId !== undefined && nftCollection.originChainId !== chainId && (
              <WrappedTag size="s" label="Wrapped" color="accent" />
            )}
          </NFTCollectionName>
        ) : (
          <span>Select an NFT Collection</span>
        )}
      </SelectButton>
      {showNFTCollectionModal && <NFTCollectionModal onDismiss={() => setShowNFTCollectionModal(false)} />}
    </div>
  )
}

export default NFTCollectionSelect
