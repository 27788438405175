/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { useMemo } from 'react'
import { useChainId } from 'state/wallet/hooks'
import useSWR from 'swr'

export const useTransactionHistory = (limit?: number): any => {
  const fetcher = (url: string) => axios.get(url).then(res => res.data)
  const apiUrl = `${process.env.REACT_APP_API_URL}/history?limit=${limit ?? 200}`

  const { data, error } = useSWR(apiUrl, fetcher, {
    refreshInterval: 60000,
    refreshWhenHidden: true,
  })

  return useMemo(() => {
    return {
      data,
      error,
    }
  }, [data, error])
}

export const useMyTransactions = (account: string | null | undefined, limit?: number): any => {
  const chainId = useChainId()
  const fetcher = (url: string) => axios.get(url).then(res => res.data)

  let apiUrl = ''
  if (account) {
    apiUrl = `${process.env.REACT_APP_API_URL}/transactions/${account.toLowerCase()}/${chainId}?limit=${limit ?? 200}`
  }
  const { data, error } = useSWR(apiUrl, fetcher, {
    refreshInterval: 60000,
    refreshWhenHidden: true,
  })

  return useMemo(() => {
    return {
      data,
      error,
    }
  }, [data, error])
}

export const useNFTTransactionHistory = (limit?: number): any => {
  const fetcher = (url: string) => axios.get(url).then(res => res.data)
  const apiUrl = `${process.env.REACT_APP_API_URL}/nft721/history?limit=${limit ?? 200}`
  const { data, error } = useSWR(apiUrl, fetcher, {
    refreshInterval: 6000,
    refreshWhenHidden: true,
  })

  return useMemo(() => {
    return {
      data,
      error,
    }
  }, [data, error])
}

export const useMyNFTTransactions = (account: string | null | undefined, limit?: number): any => {
  const chainId = useChainId()
  const fetcher = (url: string) => axios.get(url).then(res => res.data)

  let apiUrl = ''
  if (account) {
    apiUrl = `${process.env.REACT_APP_API_URL}/nft721/transactions/${account.toLowerCase()}/${chainId}?limit=${
      limit ?? 200
    }`
  }

  const { data, error } = useSWR(apiUrl, fetcher, {
    refreshInterval: 6000,
    refreshWhenHidden: true,
  })

  return useMemo(() => {
    return {
      data,
      error,
    }
  }, [data, error])
}
