import { FooterWrapper, ImageAbsolute, SectionWrapper, InputStyled, LinkStyled } from './Styled'
import ParadisoLogo from 'assets/images/paradiso.svg'
import { FaTelegramPlane, FaTwitter, FaMediumM, FaLinkedin, FaGithub } from 'react-icons/fa'
import FooterImg from 'assets/images/logo bg.svg'

function Footer(): JSX.Element {
  return (
    <FooterWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem' }}>
        <ImageAbsolute src={FooterImg} width={200} />
        <div>
          <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem', color: '#099b91' }}>Our contacts</p>
          <div
            style={{
              height: '7rem',
              width: '5rem',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              gap: '1rem',
              marginTop: '1.5rem',
            }}
          >
            <LinkStyled href="https://t.me/dotoracle" target="_blank">
              <FaTelegramPlane />
              Telegram
            </LinkStyled>
            <LinkStyled href="https://twitter.com/ParadisoIO" target="_blank">
              <FaTwitter />
              Twitter
            </LinkStyled>
            <LinkStyled href="https://github.com/TheArcadiaGroup" target="_blank">
              <FaGithub />
              Github
            </LinkStyled>
          </div>
        </div>
      </div>
      <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '1rem', color: '#777e91', marginTop: '1rem' }}>
        &copy; Paradiso 2023.
      </p>
    </FooterWrapper>
  )
}

export default Footer
