/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react'
import { EuiStepsHorizontal, EuiStepStatus } from '@elastic/eui'
import { MdArrowBackIos } from 'react-icons/md'
import { Container, Row } from 'components/Grid'
import { LeftCol, RightCol, StepsRow, StepsText, HeaderBox } from './Styled'
import { Col60, Col80 } from 'components/Grid/Styled'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import { useCurrentNetwork } from 'hooks'
import Step4 from 'components/Step4'
import { Link, useParams } from 'react-router-dom'
import Box from 'components/Box'
import { BoxTitle } from 'components/Box/Styled'
import { FiChevronRight } from 'react-icons/fi'
import { useAccount } from 'state/wallet/hooks'
import NFT1PNG from 'assets/images/nft-1.png'
import NFT2PNG from 'assets/images/nft-2.png'
import NFT3PNG from 'assets/images/nft-3.png'
import NFT4PNG from 'assets/images/nft-4.png'
import Slider from 'react-slick'
import MintNFT from 'components/FaucetBox/MintNFT'

function NFTBridge(): JSX.Element {
  const { defaultStep } = useParams()
  const [step, setStep] = useState(defaultStep ? Number(defaultStep) : 1)
  const [txHash, setTxHash] = useState('')
  const sourceNetwork = useCurrentNetwork()
  const account = useAccount()

  const getStepStatus = (_step: number): EuiStepStatus => {
    if (step == _step) {
      return 'current'
    }

    if (step > _step) {
      return 'complete'
    }

    return 'disabled'
  }

  const steps = [
    {
      title: '',
      status: getStepStatus(1),
      onClick: () => {
        setStep(1)
      },
    },
    {
      title: '',
      status: getStepStatus(2),
      onClick: () => {
        if (step > 2) {
          setStep(2)
        }
      },
    },
    {
      title: '',
      status: getStepStatus(3),
      onClick: () => {},
    },
    {
      title: '',
      status: getStepStatus(4),
      onClick: () => {},
    },
  ]

  const goToStep4 = (_txHash: string) => {
    setTxHash(_txHash)
    setStep(4)
  }

  return (
    <Container>
      {step == 1 && (
        <Row alignItems="center" justifyContent="center">
          <LeftCol>
            {!sourceNetwork?.isMainnet && <MintNFT />}
            <Box>
              <HeaderBox>
                <BoxTitle>Transfer NFTs between blockchains</BoxTitle>
                <StepsRow justifyContent="center">
                  <div>
                    <EuiStepsHorizontal steps={steps} />
                    <StepsText>
                      {step != 1 && step != 4 ? (
                        <p onClick={() => setStep(step - 1)}>
                          <MdArrowBackIos />
                          <span>Back</span>
                        </p>
                      ) : (
                        <p>NFT Bridge</p>
                      )}
                      <p>
                        <strong>Step {step}</strong> / {steps.length}
                      </p>
                    </StepsText>
                  </div>
                </StepsRow>
              </HeaderBox>
              <div style={{ padding: '1.5rem' }}>
                <Step1 nextStepCallback={() => setStep(2)} />
              </div>
            </Box>
          </LeftCol>
          <RightCol>
            {account && (
              <Link to="/explorer/my-transactions">
                <span>My transactions</span>
                <FiChevronRight size={24} />
              </Link>
            )}
            <div style={{ width: '100%', marginTop: '1rem' }}>
              <Slider dots={true} arrows={false} infinite={true} slidesToShow={1} autoplay={true}>
                <div>
                  <img src={NFT1PNG} alt="nft-1" />
                </div>
                <div>
                  <img src={NFT2PNG} alt="nft-2" />
                </div>
                <div>
                  <img src={NFT3PNG} alt="nft-3" />
                </div>
                <div>
                  <img src={NFT4PNG} alt="nft-4" />
                </div>
              </Slider>
            </div>
          </RightCol>
        </Row>
      )}
      {step == 2 && sourceNetwork && (
        <Row justifyContent="center">
          <Col80>
            <Box>
              <HeaderBox>
                <BoxTitle>Select NFT</BoxTitle>
                <StepsRow justifyContent="center">
                  <div>
                    <EuiStepsHorizontal steps={steps} />
                    <StepsText>
                      <p onClick={() => setStep(step - 1)} style={{ color: '#727eaa' }}>
                        <MdArrowBackIos />
                        <span>Back</span>
                      </p>
                      <p style={{ color: '#727eaa' }}>
                        <strong>Step {step}</strong> / {steps.length}
                      </p>
                    </StepsText>
                  </div>
                </StepsRow>
              </HeaderBox>
              <Step2 network={sourceNetwork} prevStepCallback={() => setStep(1)} nextStepCallback={() => setStep(3)} />
            </Box>
          </Col80>
        </Row>
      )}
      {step == 3 && sourceNetwork && (
        <Row justifyContent="center">
          <Col60>
            <Box>
              <HeaderBox>
                <BoxTitle>Confirm</BoxTitle>
                <StepsRow justifyContent="center">
                  <div>
                    <EuiStepsHorizontal steps={steps} />
                    <StepsText>
                      <p onClick={() => setStep(step - 1)} style={{ color: '#727eaa' }}>
                        <MdArrowBackIos />
                        <span>Back</span>
                      </p>
                      <p style={{ color: '#727eaa' }}>
                        <strong>Step {step}</strong> / {steps.length}
                      </p>
                    </StepsText>
                  </div>
                </StepsRow>
              </HeaderBox>
              <Step3 network={sourceNetwork} prevStepCallback={() => setStep(2)} nextStepCallback={goToStep4} />
            </Box>
          </Col60>
        </Row>
      )}
      {step == 4 && (
        <Row justifyContent="center">
          <Col60>
            <Box>
              <HeaderBox>
                <BoxTitle style={{ color: '#727eaa' }}>Completed</BoxTitle>
                <StepsRow justifyContent="center">
                  <div>
                    <EuiStepsHorizontal steps={steps} />
                    <StepsText>
                      <p style={{ color: '#727eaa' }}>
                        <strong>Step {step}</strong> / {steps.length}
                      </p>
                    </StepsText>
                  </div>
                </StepsRow>
              </HeaderBox>
              <div style={{ padding: '1.5rem' }}>
                <Step4 txHash={txHash} prevStepCallback={() => setStep(1)} />
              </div>
            </Box>
          </Col60>
        </Row>
      )}
    </Container>
  )
}

export default NFTBridge
