import { EuiToolTip } from '@elastic/eui'
import styled from 'styled-components/macro'
import UnknownSVG from 'assets/images/unknown.svg'
import { Flex } from '../Styled'

const ExplorerLogo = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 0.5rem;
`
const Link = styled.a`
  font-size: 0.875rem;
  color: #154ba0;

  &:hover {
    text-decoration: underline;
  }
`

interface RequestLinkProps {
  networkName: string
  explorerLogo: string
  requestHash: string
  requestHashUrl: string
}

function RequestLink(props: RequestLinkProps): JSX.Element {
  const { networkName, explorerLogo, requestHash, requestHashUrl } = props

  return (
    <EuiToolTip content={networkName}>
      <Flex>
        <ExplorerLogo src={explorerLogo ? explorerLogo : UnknownSVG} alt="explorer-logo" />
        <Link href={requestHashUrl} target="__blank" rel="noopener nofollow noreferrer">
          {requestHash}
        </Link>
      </Flex>
    </EuiToolTip>
  )
}

export default RequestLink
