import { useCurrentNetwork } from 'hooks'
import { useCallback, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { CloseButton, FaucetBlockText, FaucetBoxTitle, FaucetBoxWrapper, FaucetButtons } from './Styled'
import { EuiButton } from '@elastic/eui'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { useWBTCContract } from 'hooks/useContract'
import { useAccount } from 'state/wallet/hooks'

function MintWBTC(): JSX.Element {
  const account = useAccount()

  const [hideBlock, setHideBlock] = useState(false)
  const [minting, setMinting] = useState(false)

  const network = useCurrentNetwork()

  const wbtcAddress = '0x163e98bAe55ebAc01bFE63B1EAa9078E548f6a05'
  const wbtcContract = useWBTCContract(wbtcAddress)

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const handleMint = async () => {
    try {
      if (account && wbtcContract) {
        setMinting(true)
        setShowConfirm(true)
        setAttemptingTxn(true)

        wbtcContract.mint(account).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Faucet WBTC`,
          })

          setTxHash(response.hash)

          response
            .wait()
            .then(async () => {
              setAttemptingTxn(false)
              setMinting(false)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        })
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    } finally {
      setMinting(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      {!hideBlock && network?.chainId === 43113 && (
        <>
          <FaucetBoxWrapper>
            <FaucetBoxTitle>
              <p>WBTC Faucet</p>
              <CloseButton onClick={() => setHideBlock(true)}>
                <IoMdClose />
              </CloseButton>
            </FaucetBoxTitle>
            <FaucetBlockText>Get testnet WBTC to test the bridge out.</FaucetBlockText>
            <FaucetButtons>
              {!network?.notEVM && (
                <EuiButton isLoading={minting} onClick={handleMint}>
                  Mint
                </EuiButton>
              )}
            </FaucetButtons>
          </FaucetBoxWrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            title="Mint WBTC"
            attemptingTxn={attemptingTxn}
            hash={txHash}
            pendingText=""
            onDismiss={handleDismissConfirmation}
            content={() => <></>}
          />
        </>
      )}
    </>
  )
}

export default MintWBTC
