import { EuiButton, EuiFlexGroup } from '@elastic/eui'
import Box from 'components/Box'
import MintNFT from 'components/FaucetBox/MintNFT'
import { Col, Container, Row } from 'components/Grid'
import WalletModal from 'components/WalletModal'
import { useCurrentNetwork } from 'hooks'
import { useState } from 'react'
import { useAccount } from 'state/wallet/hooks'
import NFTListEVM from './NFTListEVM'
import NFTListCasper from './NFTListCasper'

function YourNFTs(): JSX.Element {
  const currentNetwork = useCurrentNetwork()
  const account = useAccount()

  const [showWalletModal, setShowWalletModal] = useState(false)

  return (
    <Container>
      <Row style={{ marginTop: '2rem' }}>
        <Col>
          {!currentNetwork?.isMainnet && <MintNFT />}
          {account && currentNetwork ? (
            <>
              {currentNetwork.notEVM ? (
                <>{currentNetwork.key?.includes('casper') && <NFTListCasper />}</>
              ) : (
                <NFTListEVM />
              )}
            </>
          ) : (
            <Box>
              <EuiFlexGroup justifyContent="center">
                <EuiButton fill onClick={() => setShowWalletModal(true)}>
                  Connect Wallet
                </EuiButton>
              </EuiFlexGroup>
              {showWalletModal && <WalletModal onDismiss={() => setShowWalletModal(false)} />}
            </Box>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default YourNFTs
