import { TransactionResponse } from '@ethersproject/providers'
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import axios, { AxiosResponse } from 'axios'
import BridgeAppContext from 'context/BridgeAppContext'
import { ethers, Contract, BigNumber } from 'ethers'
import { useCallback, useContext } from 'react'
import { useNFTBridgeAddress } from 'state/application/hooks'
import { useAccount, useChainId, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { useCurrentNetwork } from './useNetwork'
import NFT_BRIDGE_ABI from '../constants/abi/NFTBridge.abi.json'
import EthApp from '@ledgerhq/hw-app-eth'
import {
  CLAccountHash,
  CLPublicKey,
  CLValueBuilder,
  CasperClient,
  DeployUtil,
  RuntimeArgs,
  decodeBase16,
} from 'casper-js-sdk'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import { getContractVersionOnCasper, getDeployFunction } from 'utils'
import { useActiveWeb3React } from './useWeb3'
import NFTTransaction from 'type/NFTTransaction'
import { useNFTBridgeContract } from './useContract'

export const useRequestWidthrawNFTAPI = (): ((
  requestHash: string,
  fromChainId: number,
  toChainId: number,
  index: number,
) => Promise<AxiosResponse<any>>) => {
  const callback = useCallback(
    async (requestHash: string, fromChainId: number, toChainId: number, index: number): Promise<any> => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/nft721/request-withdraw`,
        {
          requestHash,
          fromChainId,
          toChainId,
          index,
        },
        {
          timeout: 300000,
        },
      )
    },
    [],
  )

  return callback
}

export const useClaimNFTCallback = (): ((
  response: AxiosResponse<any>,
  toAddress: string,
  requestHash: string,
  toChainId: number,
  handleTransactionResponse: (response: TransactionResponse) => void,
) => Promise<any>) => {
  const { ledgerAddress, ledgerApp, ledgerPath } = useContext(BridgeAppContext)
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()

  const nftBridgeAddress = useNFTBridgeAddress()

  const nftBridgeContract = useNFTBridgeContract(nftBridgeAddress)

  const callback = useCallback(
    async (
      response: AxiosResponse<any>,
      toAddress: string,
      requestHash: string,
      toChainId: number,
      handleTransactionResponse: (response: TransactionResponse) => void,
    ): Promise<any> => {
      const sign = response.data
      const { originToken, tokenIds, originTokenIds, chainIdsIndex, tokenUris, name, symbol, r, s, v } = sign

      if (nftBridgeContract) {
        if (ledgerAddress != '') {
          const legerProvider = new ethers.providers.JsonRpcProvider(currentNetwork?.rpcURL)
          const bridgeContractEth = new Contract(nftBridgeAddress ?? '', NFT_BRIDGE_ABI, legerProvider)
          const { data } = await bridgeContractEth.populateTransaction[
            'claimMultiNFT721Token(bytes32[],address,uint256[],string[],uint256[],bytes32,bytes32[],bytes32[],uint8[],string[2],string[])'
          ](
            originToken,
            toAddress,
            tokenIds,
            originTokenIds,
            chainIdsIndex,
            requestHash,
            r,
            s,
            v,
            [name, symbol],
            tokenUris,
          )

          const unsignedTx = {
            to: nftBridgeAddress,
            gasPrice: (await legerProvider.getGasPrice())._hex,
            gasLimit: ethers.utils.hexlify(500000),
            value: BigNumber.from(0),
            nonce: await legerProvider.getTransactionCount(toAddress, 'latest'),
            chainId: toChainId,
            data,
          }
          const transport = await TransportWebUSB.openConnected()
          if (transport != null && ledgerApp && ledgerApp instanceof EthApp) {
            const serializedTx = ethers.utils.serializeTransaction(unsignedTx).slice(2)

            const _signature = await ledgerApp.signTransaction(ledgerPath, serializedTx)
            const signature = {
              r: '0x' + _signature.r,
              s: '0x' + _signature.s,
              v: parseInt('0x' + _signature.v),
              from: toAddress,
            }
            const signedTx = ethers.utils.serializeTransaction(unsignedTx, signature)
            return legerProvider.sendTransaction(signedTx).then((txResponse: TransactionResponse) => {
              handleTransactionResponse(txResponse)
            })
          }
        } else {
          return nftBridgeContract.claimMultiNFT721Token(
            originToken,
            toAddress,
            tokenIds,
            originTokenIds,
            chainIdsIndex,
            requestHash,
            r,
            s,
            v,
            [name, symbol],
            tokenUris,
          )
        }
      }
    },
    [chainId, nftBridgeContract],
  )

  return callback
}

export const useClaimNFTOriginCasperCallback = (): ((
  toAddress: string,
  itemsCount: number,
  claimFor: boolean,
) => Promise<any>) => {
  const account = useAccount()
  const { connector } = useActiveWeb3React()
  const currentNetwork = useCurrentNetwork()
  const nftBridgeAddress = useNFTBridgeAddress()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const callback = useCallback(
    async (toAddress: string, itemsCount: number, claimFor: boolean) => {
      if (!account) {
        return
      }

      if (currentNetwork) {
        const deployParams = new DeployUtil.DeployParams(
          CLPublicKey.fromHex(account),
          currentNetwork.key ?? 'casper-test',
          1,
          1800000,
        )
        // get account hash if claim by yourself
        const accountHash = claimFor ? toAddress : CLPublicKey.fromHex(toAddress).toAccountHashStr().substring(13)
        const runtimeArgs = RuntimeArgs.fromMap({
          user: createRecipientAddress(new CLAccountHash(decodeBase16(accountHash))),
        })
        const fee = itemsCount > 0 ? itemsCount * 30000000000 : 30000000000

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            decodeBase16(nftBridgeAddress),
            'claim_unlock_nft',
            runtimeArgs,
          ),
          DeployUtil.standardPayment(fee),
        )

        if (deploy && provider && account) {
          // @ts-ignore
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          return getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)
        }
      }
    },
    [account, nftBridgeAddress],
  )
  return callback
}

export const useClaimNFTCasperCallback = (): ((
  item: NFTTransaction,
  toAddress: string,
  claimFor: boolean,
) => Promise<any>) => {
  const account = useAccount()
  const { connector } = useActiveWeb3React()
  const currentNetwork = useCurrentNetwork()
  const nftBridgeAddress = useNFTBridgeAddress()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const callback = useCallback(
    async (item: NFTTransaction, toAddress: string, claimFor: boolean) => {
      if (!account) {
        return
      }

      if (currentNetwork) {
        let nftContractAddress = ''
        if (item.contractHash) {
          nftContractAddress = item.contractHash
        } else if (item.contractPackageHash) {
          nftContractAddress = await getContractVersionOnCasper(item.contractPackageHash, currentNetwork)
        }

        if (!nftContractAddress) {
          throw new Error('nft contract address is missing')
        }

        const deployParams = new DeployUtil.DeployParams(
          CLPublicKey.fromHex(account),
          currentNetwork.key ?? 'casper-test',
          1,
          1800000,
        )
        // get account hash if claim by yourself
        const accountHash = claimFor ? toAddress : CLPublicKey.fromHex(toAddress).toAccountHashStr().substring(13)
        const runtimeArgs = RuntimeArgs.fromMap({
          user: createRecipientAddress(new CLAccountHash(decodeBase16(accountHash))),
          mint_ids_count: CLValueBuilder.u64(item.tokenIds.length),
        })

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            decodeBase16(nftContractAddress),
            'claim',
            runtimeArgs,
          ),
          DeployUtil.standardPayment(15000000000),
        )

        if (deploy && provider && account) {
          // @ts-ignore
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          return getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)
        }
      }
    },
    [account, nftBridgeAddress],
  )
  return callback
}
