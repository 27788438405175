/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import networksMainnet from 'config/networks.mainnet.json'
import networksTestnet from 'config/networks.testnet.json'
import Network from 'type/Network'
import { useChainId } from 'state/wallet/hooks'
import { AppState } from 'state'
import { SUPPORTED_NETWORKS } from '../constants'

export const useAllNetworks = (isMainnet?: boolean): Network[] => {
  return useMemo(() => {
    return (isMainnet ? networksMainnet : networksTestnet) as Network[]
  }, [isMainnet])
}

export const useOtherNetworks = (
  network?: Network,
  account?: string | null | undefined,
  chainId?: number,
): Network[] => {
  const _networks = useAllNetworks(network ? network.isMainnet : false)
  return useMemo(() => {
    const _chainId = network ? network.chainId : chainId ? chainId : process.env.REACT_APP_CHAIN_ID
    return _networks.filter(n => n.chainId !== _chainId)
  }, [network, _networks, account, chainId])
}

export const useNetworkInfo = (chainId?: number): Network | undefined => {
  return useMemo(() => {
    if (chainId) {
      return SUPPORTED_NETWORKS.find(n => n.chainId === chainId) as Network | undefined
    }
    return undefined
  }, [SUPPORTED_NETWORKS, chainId])
}

export const useCurrentNetwork = (): Network | undefined => {
  const chainId = useChainId()
  const currentNetwork = useNetworkInfo(chainId)

  return useMemo(() => currentNetwork, [chainId])
}

export const useTargetNetwork = (): Network | undefined => {
  const targetNetwork = useSelector((state: AppState) => state.application.targetNetwork)
  return useMemo(() => targetNetwork, [targetNetwork])
}

export const useRecipientAddress = (): string => {
  const address = useSelector((state: AppState) => state.application.recipientAddress)
  return useMemo(() => address, [address])
}
