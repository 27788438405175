import { useEffect, useState } from 'react'
import { EuiButtonEmpty } from '@elastic/eui'
import { InputError, InputTitle } from 'theme/components'
import { ethers } from 'ethers'
import { DarkerInputField, InputField } from './Styled'
import { Row } from 'components/Grid'
import { useAccount, useConnectorId } from 'state/wallet/hooks'
import { ConnectorNames, SUPPORTED_WALLETS } from '../../constants'
import { useTargetNetwork } from 'hooks'
import { useCurrentNetwork, useRecipientAddress } from 'hooks/useNetwork'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateRecipientAddress } from 'state/application/actions'

interface AddressInputProps {
  title: string
  placeholder: string
  callback: (valid: boolean, address: string) => void
  darker?: boolean
  showMyAccountBtn?: boolean
  inSameNetwork?: boolean
}

function AddressInput(props: AddressInputProps): JSX.Element {
  const { title, placeholder, callback, darker, showMyAccountBtn, inSameNetwork } = props
  const dispatch = useDispatch<AppDispatch>()

  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()
  const recipientAddress = useRecipientAddress()

  const [toAddress, setToAddress] = useState(recipientAddress)
  const [isValid, setValid] = useState(false)

  const validateAddress = (text: string): boolean => {
    let result = false
    const network = inSameNetwork ? currentNetwork : targetNetwork

    if (network?.notEVM) {
      switch (network?.chainId) {
        case 131614895977472: // Casper Mainnet
        case 96945816564243: // Caspet Testnet
          result = /^0(1[0-9a-fA-F]{64}|2[0-9a-fA-F]{66})$/.test(text)
          break
        case 13116011111351: // Bitcoin Testnet
        case 13116011111351: // Bitcoin Mainnet
          result =
            /^((bc|tb)(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,87})|([13]|[mn2])[a-km-zA-HJ-NP-Z1-9]{25,39})$/.test(
              text,
            )
          break
        default:
          result = true
          break
      }
    } else {
      result = ethers.utils.isAddress(text)
    }
    return result
  }

  const onChanged = (e: any) => {
    const address = e.target.value
    setToAddress(address)
    dispatch(updateRecipientAddress({ address }))
    const _isValid = validateAddress(address)
    setValid(_isValid)
    callback(_isValid, address)
  }

  const onPaste = async () => {
    const text = await navigator.clipboard.readText()
    setToAddress(text)
    dispatch(updateRecipientAddress({ address: text }))
    const _isValid = validateAddress(text)
    setValid(_isValid)
    callback(_isValid, text)
  }

  const onUseMyAccount = () => {
    setToAddress(account ?? '')
    dispatch(updateRecipientAddress({ address: account ?? '' }))
    setValid(true)
    callback(true, account ?? '')
  }

  useEffect(() => {
    const _isValid = validateAddress(toAddress)
    setValid(_isValid)
    callback(_isValid, toAddress)
  }, [targetNetwork, toAddress])

  // @ts-ignore
  const { ethereum } = window
  const isMetaMask = !!(ethereum && ethereum.isMetaMask)
  const connectorId = useConnectorId()
  const currentConnector = Object.keys(SUPPORTED_WALLETS)
    .filter(k => SUPPORTED_WALLETS[k].connectorId === connectorId && isMetaMask === (k === 'METAMASK'))
    .map(k => SUPPORTED_WALLETS[k].name)[0]

  return (
    // @ts-ignore
    <div className={props.className}>
      <Row justifyContent="spaceBetween" style={{ margin: 0 }}>
        <InputTitle>{title}</InputTitle>
        <>
          {showMyAccountBtn && (
            <>
              {((currentConnector && currentConnector.toLowerCase() === 'metamask') ||
                connectorId === ConnectorNames.Bitcoin) &&
                !targetNetwork?.notEVM && <EuiButtonEmpty onClick={onUseMyAccount}>My Account</EuiButtonEmpty>}
            </>
          )}
        </>
      </Row>
      {darker ? (
        <DarkerInputField>
          <InputField
            placeholder={placeholder}
            value={toAddress}
            onChange={onChanged}
            append={<EuiButtonEmpty onClick={onPaste}>Paste</EuiButtonEmpty>}
            autoFocus={true}
            style={{
              backgroundColor: '#f1f2f6',
              color: '#000',
            }}
          />
        </DarkerInputField>
      ) : (
        <InputField
          placeholder={placeholder}
          value={toAddress}
          onChange={onChanged}
          append={<EuiButtonEmpty onClick={onPaste}>Paste</EuiButtonEmpty>}
          autoFocus={true}
          style={{ backgroundColor: '#f1f2f6', color: '#000' }}
        />
      )}
      {!isValid && toAddress && <InputError>Invalid address</InputError>}
    </div>
  )
}

export default AddressInput
