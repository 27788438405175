import { TransactionResponse } from '@ethersproject/providers'
import axios, { AxiosError } from 'axios'
import { useCurrentNetwork } from 'hooks'
import { useCallback, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useChainId, useConnectWalletCallback, useDeactivateCallback } from 'state/wallet/hooks'
import Transaction from 'type/Transaction'
import { connectorLocalStorageKey, ConnectorNames } from '../../../../constants'
import toast from 'react-hot-toast'
import { sleep, setupNetwork, shortenAddress } from 'utils'
import { ConfirmMessage, Dots, StyledClaimButton } from 'theme/components'
import { EuiButton, EuiButtonEmpty, EuiConfirmModal } from '@elastic/eui'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import Network from 'type/Network'
import {
  useRequestWidthrawAPI,
  useClaimTokenCallback,
  useClaimTokenOriginCasperCallback,
} from 'hooks/useClaimTokensCallback'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateSourceNetwork, updateTargetNetwork } from 'state/application/actions'
import { updateSelectedToken, updateTokenAmount } from 'state/token/actions'
import { updateConnector } from 'state/wallet/actions'
import NetworkInfo from '../../NetworkInfo'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal/TransactionSubmittedContent'
import { ConfirmationButtons } from 'pages/nft-bridge/Styled'

function ClaimTokenForButton({ item, inline }: { item: Transaction; inline?: boolean }): JSX.Element {
  const deactivate = useDeactivateCallback()

  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()

  const [isClaiming, setIsClaiming] = useState(false)
  const [toNetwork, setToNetwork] = useState<Network>()
  const [showNetworkModal, setShowNetworkModal] = useState(false)

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const requestWithdrawAPI = useRequestWidthrawAPI()
  const claimToken = useClaimTokenCallback()
  const claimTokenOriginCasper = useClaimTokenOriginCasperCallback(item.tokenAddress)

  const addTransaction = useTransactionAdder()
  const connectWalletCallback = useConnectWalletCallback()
  const dispatch = useDispatch<AppDispatch>()

  const handleTransactionResponse = (response: TransactionResponse) => {
    addTransaction(response, {
      summary: `Claim token from bridge for ${shortenAddress(item.account)}`,
    })

    setTxHash(response.hash)

    response.wait().then(() => {
      setAttemptingTxn(false)
      setIsClaiming(false)
    })
  }

  const onClaimToken = async () => {
    const { requestHash, originChainId, fromChainId, toChainId, index, originToken, amount } = item

    setToNetwork(item.toNetwork)

    // Ask user if the currentChainId is different than the toChainId
    if (chainId !== toChainId) {
      setShowNetworkModal(true)
    } else {
      try {
        setIsClaiming(true)

        let response = undefined

        let tryTimes = 10
        while (tryTimes > 0) {
          try {
            response = await requestWithdrawAPI(requestHash, fromChainId, toChainId, index)
            if (response && response.status === 200 && response.data) {
              break
            }
            await sleep(300)
          } catch (error) {
            tryTimes--
          }
        }

        if (response && response.status === 200 && response.data) {
          setShowConfirm(true)
          setAttemptingTxn(true)

          if (item.originNetwork?.key?.includes('casper')) {
            await claimTokenOriginCasper(
              response,
              item.account,
              requestHash,
              originChainId,
              fromChainId,
              toChainId,
              index,
              amount,
              handleTransactionResponse,
            )
              .then((txResponse: TransactionResponse) => {
                handleTransactionResponse(txResponse)
              })
              .catch((error: any) => {
                setIsClaiming(false)
                setShowConfirm(false)
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                  console.error(error)
                }
              })
          } else {
            await claimToken(
              response,
              item.account,
              requestHash,
              originChainId,
              fromChainId,
              toChainId,
              index,
              originToken,
              amount,
              handleTransactionResponse,
            )
              .then((txResponse: TransactionResponse) => {
                handleTransactionResponse(txResponse)
              })
              .catch((error: any) => {
                setIsClaiming(false)
                setShowConfirm(false)
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                  console.error(error)
                }
              })
          }
        }
      } catch (error) {
        setIsClaiming(false)
        setShowConfirm(false)
        setAttemptingTxn(false)
        console.error(error)

        if (axios.isAxiosError(error)) {
          const _e = error as AxiosError
          const message = _e.response?.data.errors
          toast.error(message ?? 'Could not claim. Please try again.', {
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
              fontSize: '14px',
            },
            duration: 5000,
          })
        }
      }
    }
  }

  const changeToEVMChain = async (network: Network) => {
    const hasSetup = await setupNetwork(network)

    if (hasSetup) {
      // @ts-ignore
      window.ethereum.on('chainChanged', () => {
        if (chainId === network.chainId && currentNetwork) {
          dispatch(updateTargetNetwork({ network: currentNetwork }))
        }
        // dispatch(updateSourceNetwork({ network }))
        dispatch(updateSelectedToken({ token: undefined }))
        dispatch(updateTokenAmount({ amount: '' }))
      })
    }
  }

  const onSetupNetwork = async (network: Network) => {
    try {
      const isBitcoin = network.name.toLowerCase().includes('bitcoin')
      const isCasper = network.name.toLowerCase().includes('casper')

      // if current network is EVM
      if (!currentNetwork?.notEVM) {
        // if change to EVM
        if (!network.notEVM) {
          await changeToEVMChain(network)
        } else if (isCasper) {
          const casperConnector =
            // @ts-ignore
            window.CasperWalletProvider !== undefined
              ? ConnectorNames.CasperWallet
              : // @ts-ignore
              window.casperDashHelper != undefined
              ? ConnectorNames.CasperDash
              : ConnectorNames.CasperSigner
          window.localStorage.setItem(connectorLocalStorageKey, casperConnector)
          dispatch(updateConnector({ connector: casperConnector }))

          await deactivate()
          await connectWalletCallback(ConnectorNames.CasperDash)
          window.location.reload()
        } else if (isBitcoin) {
          // window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Bitcoin)
          dispatch(updateConnector({ connector: ConnectorNames.Bitcoin }))
        }
      } else {
        // from Casper to EVM
        if (currentNetwork?.name.toLowerCase().includes('casper') && !network.notEVM) {
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))

          await deactivate()
          await changeToEVMChain(network)
          window.location.reload()
        }
        // from Bitcoin to EVM
        if (currentNetwork?.name.toLowerCase().includes('bitcoin') && !network.notEVM) {
          window.localStorage.removeItem(connectorLocalStorageKey)
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))
          await connectWalletCallback(ConnectorNames.Injected)
        }
      }

      dispatch(updateSourceNetwork({ network }))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error('Could not setup network')
        console.error(error)
      }
    } finally {
      setShowNetworkModal(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setIsClaiming(false)
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const confirmModalTopContent = () => {
    return (
      <p style={{ color: '#727eaa' }}>
        Are you sure you want to claim for a different wallet? Please be aware that there may be transaction fees
        associated with this process, and the tokens will be sent to the wallet address associated with the recorded
        transaction.
      </p>
    )
  }

  const confirmModalBottomContent = () => {
    return (
      <ConfirmationButtons>
        <EuiButtonEmpty onClick={handleDismissConfirmation}>Cancel</EuiButtonEmpty>
        <EuiButton fill isDisabled={isClaiming} onClick={onClaimToken}>
          {isClaiming ? <Dots>Verifying</Dots> : <span>Yes, I understand</span>}
        </EuiButton>
      </ConfirmationButtons>
    )
  }

  return (
    <>
      {!item.claimed && item.index >= 0 && (
        <>
          {item.toNetwork?.notEVM ? (
            <>
              <span style={{ color: '#154ba0' }}>Processing</span>
            </>
          ) : (
            <>
              {inline ? (
                <StyledClaimButton isDisabled={isClaiming} onClick={() => setShowConfirm(true)}>
                  {isClaiming ? <Dots>Verifying</Dots> : <span>Claim for</span>}
                </StyledClaimButton>
              ) : (
                <EuiButton isDisabled={isClaiming} onClick={() => setShowConfirm(true)}>
                  Claim for
                </EuiButton>
              )}
            </>
          )}
        </>
      )}
      {showNetworkModal && toNetwork && (
        <EuiConfirmModal
          title="Important!"
          onCancel={() => setShowNetworkModal(false)}
          onConfirm={() => onSetupNetwork(toNetwork)}
          cancelButtonText="Cancel"
          confirmButtonText="Change network"
          defaultFocusedButton="confirm"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p style={{ color: '#727eaa' }}>You are connecting to</p>
            <NetworkInfo network={currentNetwork}></NetworkInfo>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p style={{ color: '#727eaa' }}>Please change the network to</p>
            <NetworkInfo network={toNetwork}></NetworkInfo>
          </div>
        </EuiConfirmModal>
      )}

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Claim tokens"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => (
          <ConfirmationModalContent topContent={confirmModalTopContent} bottomContent={confirmModalBottomContent} />
        )}
      />
    </>
  )
}

export default ClaimTokenForButton
