import { useCurrentNetwork } from 'hooks'
import { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { CloseButton, FaucetBlockText, FaucetBoxTitle, FaucetBoxWrapper, FaucetButtons } from './Styled'
import MintButtonCasper from './MintButtonCasper'
import MintButtonEVM from './MintButtonEVM'

function MintNFT(): JSX.Element {
  const [hideBlock, setHideBlock] = useState(false)

  const network = useCurrentNetwork()

  return (
    <>
      {!hideBlock && (
        <FaucetBoxWrapper>
          <FaucetBoxTitle>
            <p>Mint NFT</p>
            <CloseButton onClick={() => setHideBlock(true)}>
              <IoMdClose />
            </CloseButton>
          </FaucetBoxTitle>
          <FaucetBlockText>Get testnet NFTs to test the bridge out.</FaucetBlockText>
          <FaucetButtons>{network?.notEVM ? <MintButtonCasper /> : <MintButtonEVM />}</FaucetButtons>
        </FaucetBoxWrapper>
      )}
    </>
  )
}

export default MintNFT
