import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { InjectedConnector as OKXConnector } from 'web3-react-okexchain-connector'
import { NetworkConnector } from './NetworkConnector'
import { ConnectorNames, SUPPORTED_CHAINIDS, SUPPORTED_NETWORKS } from '../constants'

//@ts-ignore
const NETWORK_CHAIN_ID = window.ethereum
  ? //@ts-ignore
    Number(window.ethereum.networkVersion)
  : Number(process.env.REACT_APP_CHAIN_ID)
const NETWORK_URL = SUPPORTED_NETWORKS.find(n => n.chainId == NETWORK_CHAIN_ID)?.rpcURL ?? process.env.REACT_APP_RPC_URL

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINIDS,
})

export const okxConnector = new OKXConnector({
  supportedChainIds: SUPPORTED_CHAINIDS,
})

export const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

// const CASPER_CHAINS = {
//   CASPER_MAINNET: 'casper',
//   CASPER_TESTNET: 'casper-test',
// }

// const SUPPORTED_NETWORKS = {
//   [CASPER_CHAINS.CASPER_MAINNET]: {
//     blockExplorerUrl: 'https://cspr.live',
//     chainId: '131614895977472',
//     displayName: 'Casper Mainnet',
//     logo: 'https://cspr.live/assets/icons/logos/cspr-live-full.svg',
//     rpcTarget: 'https://casper-node.tor.us',
//     ticker: 'CSPR',
//     tickerName: 'Casper Token',
//     networkKey: CASPER_CHAINS.CASPER_MAINNET,
//   },
//   [CASPER_CHAINS.CASPER_TESTNET]: {
//     blockExplorerUrl: 'https://testnet.cspr.live',
//     chainId: '96945816564243',
//     displayName: 'Casper Testnet',
//     logo: 'https://testnet.cspr.live/assets/icons/logos/cspr-live-full.svg',
//     rpcTarget: 'https://testnet.casper-node.tor.us',
//     ticker: 'CSPR',
//     tickerName: 'Casper Token',
//     networkKey: CASPER_CHAINS.CASPER_TESTNET,
//   },
// }

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.OKXWallet]: okxConnector,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.CasperWallet]: 'casperwalelt',
  [ConnectorNames.CasperSigner]: 'caspersigner',
  [ConnectorNames.Ledger]: 'ledger',
  [ConnectorNames.CasperDash]: 'casperdash',
  [ConnectorNames.Bitcoin]: 'bitcoin',
}

// https://github.com/make-software/casper-wallet-sdk
export type CasperWalletState = {
  isLocked: boolean
  isConnected: boolean
  activeKey: string | null
}

export enum CasperWalletEventTypes {
  Connected = 'casper-wallet:connected',
  Disconnected = 'casper-wallet:disconnected',
  TabChanged = 'casper-wallet:tabChanged',
  ActiveKeyChanged = 'casper-wallet:activeKeyChanged',
  Locked = 'casper-wallet:locked',
  Unlocked = 'casper-wallet:unlocked',
}
