import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiOutsideClickDetector } from '@elastic/eui'
import ConfirmationPendingContent from './ConfirmationPendingContent'
import TransactionSubmittedContent from './TransactionSubmittedContent'

interface TransactionConfirmationModalProps {
  isOpen: boolean
  title: string
  attemptingTxn: boolean
  hash: string | undefined
  pendingText: string
  content: () => React.ReactNode
  onDismiss: () => void
}

function TransactionConfirmationModal(props: TransactionConfirmationModalProps): JSX.Element {
  const { isOpen, title, attemptingTxn, hash, pendingText, content, onDismiss } = props

  return (
    <>
      {isOpen && (
        <EuiOutsideClickDetector onOutsideClick={onDismiss}>
          <EuiModal onClose={onDismiss}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>{attemptingTxn ? '' : title}</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody className="no-overflow">
              {attemptingTxn ? (
                <ConfirmationPendingContent pendingText={pendingText} />
              ) : hash ? (
                <TransactionSubmittedContent hash={hash} />
              ) : (
                content()
              )}
            </EuiModalBody>
          </EuiModal>
        </EuiOutsideClickDetector>
      )}
    </>
  )
}

export default TransactionConfirmationModal
