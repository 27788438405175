/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'
import { CasperClient, CLPublicKey } from 'casper-js-sdk'
import { ERC20Client } from 'casper-erc20-js-client'
import { useTokenContract } from './useContract'
import { NATIVE_TOKEN_ADDERSS } from '../constants'
import NetworkInfo from 'type/Network'
import { formatUnits } from 'ethers/lib/utils'
import { useBlockNumber } from 'state/application/hooks'

export const useTokenBalanceCallback = (
  tokenAddress: string | undefined,
  decimals: number | undefined,
  account: string | null | undefined,
  library: any | undefined,
  network?: NetworkInfo,
): [number, boolean] => {
  const tokenContract = useTokenContract(network && network.notEVM ? undefined : tokenAddress)
  const [balance, setBalance] = useState(0)
  const [loading, setLoading] = useState(false)
  const blockNumber = useBlockNumber()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      let _balance = 0
      setLoading(true)

      try {
        if (account && network) {
          if (tokenAddress === NATIVE_TOKEN_ADDERSS) {
            if (network.notEVM) {
              const casper = new CasperClient(network.rpcURL)
              const casperBalance = await casper.balanceOfByPublicKey(CLPublicKey.fromHex(account))
              _balance = casperBalance.toNumber()
            } else {
              const ethBalance = await library.getBalance(account)
              _balance = Number(ethBalance)
            }
          } else {
            if (network.notEVM && network.name.toLowerCase().includes('casper')) {
              const erc20 = new ERC20Client(network.rpcURL, network.key ?? '', network.eventStream)
              await erc20.setContractHash(tokenAddress ?? '')
              _balance = await erc20.balanceOf(CLPublicKey.fromHex(account))
            } else {
              if (tokenContract) {
                _balance = await tokenContract.balanceOf(account)
              }
            }
          }
        }

        if (_balance >= 0 && decimals) {
          const _balanceStr = formatUnits(_balance.toString(), decimals)
          _balance = Number(_balanceStr)
        }
      } catch (error) {
        _balance = 0
      } finally {
        setLoading(false)
      }

      setBalance(_balance)
    })()
  }, [tokenAddress, account, network, blockNumber])

  return useMemo(() => [balance, loading], [balance, loading, tokenAddress, account, network, blockNumber])
}
