import { useDispatch } from 'react-redux'
import NFT from 'type/NFT'
import { MdClose } from 'react-icons/md'
import { NFTImageList, NFTListItemWrapper, NFTNameList } from '../Styled'
import { EuiButtonEmpty } from '@elastic/eui'
import { AppDispatch } from 'state'
import { updateSelectedNFTs } from 'state/nft/actions'
import { useSelectedNFTCollection, useSelectedNFTs } from 'state/nft/hooks'

interface NFTListItemProps {
  nft: NFT
}

function NFTListItem(props: NFTListItemProps): JSX.Element {
  const { nft } = props

  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelectedNFTCollection()
  const selectedNFTs = useSelectedNFTs()

  const onRemoveNFT = () => {
    if (selectedNFTs) {
      const newList = selectedNFTs.filter(n => n.tokenId != nft.tokenId)
      dispatch(updateSelectedNFTs({ selectedNFTs: newList }))
    }
  }

  return (
    <NFTListItemWrapper>
      <div>
        <NFTImageList src={nft.image} alt={nft.name ?? nftCollection?.name} />
        <NFTNameList>
          {nft.name ?? nftCollection?.name} #{nft.tokenId}
        </NFTNameList>
      </div>
      <span>
        <EuiButtonEmpty onClick={onRemoveNFT}>
          <MdClose style={{ display: 'inline-block', verticalAlign: 'middle' }} color="#777e90" size={24} />
        </EuiButtonEmpty>
      </span>
    </NFTListItemWrapper>
  )
}

export default NFTListItem
