import styled from 'styled-components/macro'
import { RiErrorWarningFill } from 'react-icons/ri'

const WarningMessageWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #ffd16633;
  border-radius: 0.5rem;

  @media (min-width: 75rem) {
    margin-bottom: 2.5rem;
  }
`
const WarningTitle = styled.h4`
  color: #ffd166;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
`
const WarningDescription = styled.p`
  color: #b1b5b3;
  font-size: 0.875rem;
  line-height: 1.5;
`

function WarningMessage(): JSX.Element {
  return (
    <WarningMessageWrapper>
      <WarningTitle>
        <RiErrorWarningFill size={22} />
        <span>Note</span>
      </WarningTitle>
      <WarningDescription>
        Please <strong>DO NOT</strong> send you assets to Centralized Exchange (like Binance) wallet.
        <br />
        Only send to wallets where you control private keys!
      </WarningDescription>
    </WarningMessageWrapper>
  )
}

export default WarningMessage
