import { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { EuiOutsideClickDetector, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody } from '@elastic/eui'
import { useCurrentNetwork } from 'hooks'
import { BiLinkExternal } from 'react-icons/bi'
import { BsXDiamondFill } from 'react-icons/bs'
import { SUPPORTED_WALLETS, ConnectorNames } from '../../constants'
import { getExplorerLink, shortenAddress } from 'utils'
import { clearNFTCache } from 'state/nft/actions'
import {
  AccountControl,
  AccountGroupingRow,
  AddressLink,
  IconWrapper,
  InfoCard,
  LowerSection,
  TransactionListWrapper,
  WalletAction,
  WalletName,
  YourNFTsLink,
} from './Styled'
import Identicon from './Identicon'
import Copy from '../Copy'
import { isMyTransaction, isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import Transaction from './Transaction'
import { clearAllTransactions } from 'state/transactions/actions'
import { Row } from 'components/Grid'
import { StyledLink } from 'theme/components'
import { useAccount, useChainId, useConnectorId, useDeactivateCallback } from 'state/wallet/hooks'

interface AccountInfoModalProps {
  onDismiss: () => void
}

function AccountInfoModal(props: AccountInfoModalProps): JSX.Element {
  const { onDismiss } = props

  const connectorId = useConnectorId()
  const account = useAccount()
  const chainId = useChainId()
  const networkInfo = useCurrentNetwork()
  const deactivate = useDeactivateCallback()

  const dispatch = useDispatch<AppDispatch>()
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs
      .filter(tx => isTransactionRecent(tx) && isMyTransaction(tx, account ?? ''))
      .sort((a, b) => b.addedTime - a.addedTime)
  }, [allTransactions])

  const pendingTransactions = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmedTransactions = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  const formatConnectorName = () => {
    // @ts-ignore
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)

    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k =>
          SUPPORTED_WALLETS[k].connectorId === connectorId &&
          (connectorId !== ConnectorNames.Injected || isMetaMask === (k === 'METAMASK')),
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return <>{name && <WalletName>{`Connected with ${name}`}</WalletName>}</>
  }

  const getStatusIcon = () => {
    if (connectorId === ConnectorNames.Injected) {
      return (
        <IconWrapper size={16}>
          <Identicon />
        </IconWrapper>
      )
    }
    return null
  }

  const renderTransactions = (transactions: string[]) => {
    return (
      <TransactionListWrapper>
        {transactions.map((hash, i) => {
          return <Transaction key={i} hash={hash} />
        })}
      </TransactionListWrapper>
    )
  }

  const onLogout = async () => {
    // Clear data
    await deactivate()
    onDismiss()

    dispatch(clearNFTCache())
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      {account && (
        <EuiOutsideClickDetector onOutsideClick={onDismiss}>
          <EuiModal onClose={onDismiss}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h1>Your Account</h1>
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <InfoCard>
                <AccountGroupingRow>
                  {formatConnectorName()}
                  <div>
                    <WalletAction
                      style={{ fontSize: '.825rem', fontWeight: 400 }}
                      onClick={() => {
                        onLogout()
                      }}
                    >
                      Disconnect
                    </WalletAction>
                  </div>
                </AccountGroupingRow>
                <AccountGroupingRow id="web3-account-identifier-row">
                  <AccountControl>
                    <div>
                      {getStatusIcon()}
                      <p style={{ color: '#154ba0' }}> {account && shortenAddress(account)}</p>
                    </div>
                  </AccountControl>
                </AccountGroupingRow>
                <AccountGroupingRow>
                  <AccountControl>
                    <>
                      {account && (
                        <Copy toCopy={account} iconPosition="left" showCopied={true}>
                          <span style={{ marginLeft: '4px' }}>Copy</span>
                        </Copy>
                      )}
                      {chainId && account && (
                        <AddressLink href={getExplorerLink(networkInfo, account, 'address')}>
                          <BiLinkExternal size={18} />
                          <span>View on explorer</span>
                        </AddressLink>
                      )}
                      <YourNFTsLink to="/your-nfts" onClick={onDismiss}>
                        <BsXDiamondFill />
                        <span>Your NFTs</span>
                      </YourNFTsLink>
                    </>
                  </AccountControl>
                </AccountGroupingRow>
              </InfoCard>
              {!!pendingTransactions.length || !!confirmedTransactions.length ? (
                <LowerSection>
                  <Row justifyContent="spaceBetween" style={{ margin: 0 }}>
                    <p>Recent Transactions</p>
                    <StyledLink onClick={clearAllTransactionsCallback}>Clear All</StyledLink>
                  </Row>
                  {renderTransactions(pendingTransactions)}
                  {renderTransactions(confirmedTransactions)}
                </LowerSection>
              ) : (
                <LowerSection>
                  <p>Your transactions will appear here...</p>
                </LowerSection>
              )}
            </EuiModalBody>
          </EuiModal>
        </EuiOutsideClickDetector>
      )}
    </>
  )
}

export default AccountInfoModal
