import {
  EuiFieldSearch,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalFooter,
  EuiModalHeaderTitle,
  EuiOutsideClickDetector,
  EuiWindowEvent,
} from '@elastic/eui'
import { isAddress } from 'ethers/lib/utils'
import { useDebounce } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { HiOutlineRefresh } from 'react-icons/hi'
import { IconButton, ModalBody, ModalHeader, NoResultsFound, RotatingAnimation } from 'theme/components'
import Token from 'type/Token'
import { filterTokens } from './filtering'
import TokenList from './TokenList'
// import ImportRow from './ImportRow'
// import { useToken } from 'state/token/hooks'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { getTokensFromConfig, getTokensFromConfigMemoized, getPayTokensFromConfig } from 'utils'
import { NATIVE_TOKEN_ADDERSS } from '../../../../constants'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'

const LoadingWrap = styled.div`
  padding: 0 8px;

  @media (min-width: 768px) {
    padding: 0 30px;
  }
`
const StyledEuiModal = styled(EuiModal)`
  .euiFormControlLayout__childrenWrapper {
    height: auto;
  }
`
const StyledLink = styled(Link)`
  color: #888d9b;
  display: flex;
  align-items: center;
  gap: 4px;
`
const ModalFooter = styled(EuiModalFooter)`
  justify-content: space-between;
`
export const RefreshButton = styled(IconButton)`
  &.is-loading svg {
    animation: ${RotatingAnimation} 0.75s linear infinite;
  }
`

interface TokenModalProps {
  showNativeToken: boolean
  onDismiss: () => void
  isListTokenSwap: boolean
}

function TokenModal(props: TokenModalProps): JSX.Element {
  const { showNativeToken, onDismiss, isListTokenSwap } = props

  const account = useAccount()
  const chainId = useChainId()

  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedQuery = useDebounce(searchQuery, 200)
  const [tokenList, setTokenList] = useState<Token[]>([])
  // const searchToken = useToken(isAddress(debouncedQuery) ? debouncedQuery : undefined)

  const fetchData = async () => {
    try {
      setLoading(true)
      let _tokenList = await getTokensFromConfig(account, chainId, true)
      const _tokenList1 = await getPayTokensFromConfig(account, chainId)

      if (!showNativeToken) {
        _tokenList = _tokenList.filter(t => t.address !== NATIVE_TOKEN_ADDERSS)
      }

      isListTokenSwap ? setTokenList(_tokenList1) : setTokenList(_tokenList)
      //setTokenList(_tokenList)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [chainId, account])

  const handleInput = useCallback(event => {
    const input = event.target.value
    const checksummedInput = isAddress(input) ? input : false
    setSearchQuery(checksummedInput || input)
  }, [])

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(tokenList, debouncedQuery)
  }, [tokenList, debouncedQuery])

  const handleSelect = () => {
    onDismiss()
  }

  // const onAddCustomToken = async () => {
  //   let _tokens = [] as Token[]
  //   const data = localStorage.getItem(`tokens_${account}_${chainId}`)

  //   if (data) {
  //     _tokens = JSON.parse(data) as Token[]
  //   }

  //   if (searchToken) {
  //     _tokens.unshift(searchToken)
  //   }

  //   if (_tokens.length) {
  //     localStorage.setItem(`tokens_${account}_${chainId}`, JSON.stringify(_tokens))
  //   }

  //   setSearchQuery('')
  //   setTokenList(await getTokensFromConfig(account, chainId))
  // }

  const handleRemoveCustomToken = async () => {
    setSearchQuery('')
    setTokenList(await getTokensFromConfigMemoized(account, chainId))
  }

  const onEscKeydown = (e: React.KeyboardEvent) => {
    if (e.key === '27') {
      onDismiss()
    }
  }

  const onRefresh = async () => {
    localStorage.removeItem('token_logos')
    await fetchData()
  }

  return (
    <>
      <EuiOutsideClickDetector onOutsideClick={onDismiss}>
        <StyledEuiModal onClose={onDismiss}>
          <ModalHeader>
            <EuiModalHeaderTitle>
              <h1>Select a token</h1>
            </EuiModalHeaderTitle>
          </ModalHeader>

          <ModalBody>
            <EuiFieldSearch
              name="search-token"
              value={searchQuery}
              autoComplete="off"
              placeholder="Search name or paste address"
              onChange={handleInput}
            />
            {loading ? (
              <LoadingWrap>
                <EuiLoadingSpinner size="l" />
              </LoadingWrap>
            ) : (
              <>
                {filteredTokens.length > 0 ? (
                  <TokenList
                    tokenList={filteredTokens}
                    onSelectToken={handleSelect}
                    onRemoveCustomToken={handleRemoveCustomToken}
                  />
                ) : (
                  // ) : searchToken ? (
                  // <ImportRow token={searchToken} onAddCustomToken={onAddCustomToken} />
                  <NoResultsFound>No results found.</NoResultsFound>
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <RefreshButton onClick={onRefresh} className={loading ? 'is-loading' : ''}>
              <HiOutlineRefresh color="#888d9b" size={18} />
            </RefreshButton>
            <StyledLink to="supported-tokens" target="_blank">
              <span>Supported Tokens</span>
              <BiLinkExternal />
            </StyledLink>
          </ModalFooter>
        </StyledEuiModal>
      </EuiOutsideClickDetector>
      {/* @ts-ignore */}
      <EuiWindowEvent event="keydown" handler={onEscKeydown} />
    </>
  )
}

export default TokenModal
