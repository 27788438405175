import { useMemo, useCallback } from 'react'
import { MaxUint256 } from '@ethersproject/constants'
import { useTokenContract } from './useContract'
import Token from '../type/Token'
import { NATIVE_TOKEN_ADDERSS } from '../constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useAccount } from 'state/wallet/hooks'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { useSelectedToken } from 'state/token/hooks'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
  LOADING,
}

const useTokenAllowance = (token?: Token, owner?: string, spender?: string): BigNumber | undefined => {
  const contract = useTokenContract(token?.address)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result
  const pendingApproval = useHasPendingApproval(token?.address, spender)

  return useMemo(
    () => (token && allowance ? BigNumber.from(allowance.toString()) : undefined),
    [token, allowance, pendingApproval],
  )
}

export const useApproveCallback = (
  amount: number,
  spender?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [ApprovalState, () => Promise<any>] => {
  const account = useAccount()
  const selectedToken = useSelectedToken()
  const addTransaction = useTransactionAdder()
  const currentAllowance = useTokenAllowance(selectedToken, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(selectedToken?.address, spender)
  const amountToApprove = parseUnits(amount.toString(), selectedToken?.decimals)

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    // native token
    if (selectedToken?.address === NATIVE_TOKEN_ADDERSS) return ApprovalState.APPROVED

    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN

    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance || amountToApprove.toString() === '0') return ApprovalState.UNKNOWN
    return currentAllowance.lt(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [selectedToken, amountToApprove, currentAllowance, spender, pendingApproval])

  const tokenContract = useTokenContract(selectedToken?.address)

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }

    if (!selectedToken) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!account) {
      console.error('no account')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    return tokenContract
      .approve(spender, MaxUint256)
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Approve ${selectedToken?.symbol}`,
          approval: { tokenAddress: selectedToken.address, spender: spender },
        })
      })
      .catch((error: Error) => {
        console.error('Failed to approve token', error)
      })
  }, [approvalState, tokenContract, amountToApprove, spender, addTransaction])

  return [approvalState, approve]
}
