import Network from 'type/Network'
import Step2Casper from './Step2Casper'
import Step2EVM from './Step2EVM'

interface Step2Props {
  network: Network
  prevStepCallback: () => void
  nextStepCallback: () => void
}

function Step2(props: Step2Props): JSX.Element {
  const { network, prevStepCallback, nextStepCallback } = props
  return (
    <>
      {network.notEVM ? (
        <>
          {network.key?.includes('casper') && (
            <Step2Casper prevStepCallback={prevStepCallback} nextStepCallback={nextStepCallback} />
          )}
        </>
      ) : (
        <Step2EVM prevStepCallback={prevStepCallback} nextStepCallback={nextStepCallback} />
      )}
    </>
  )
}

export default Step2
