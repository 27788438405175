import { EuiButton } from '@elastic/eui'
import styled from 'styled-components/macro'

export const TableWrap = styled.div`
  .euiTableRowCell:nth-child(4) .euiTableCellContent {
    gap: 8px;

    @media (min-width: 75rem) {
      justify-content: flex-end;
    }
  }

  @media (min-width: 48rem) {
    margin-top: -64px;

    .euiTable.euiTable--responsive .euiTableRow {
      justify-content: flex-start;
    }
  }

  @media (min-width: 75rem) {
    margin-top: -78px;
  }

  @media (max-width: 1199px) {
    .euiTableHeaderCell:nth-child(3),
    .euiTableRowCell:nth-child(3) {
      width: 100% !important;
    }
  }

  @media (min-width: 767px) and (max-width: 1199px) {
    .euiTableHeaderCell:nth-child(1),
    .euiTableRowCell:nth-child(1),
    .euiTableHeaderCell:nth-child(2),
    .euiTableRowCell:nth-child(2) {
      width: 50% !important;
    }
  }

  > div > .euiFlexGroup {
    justify-content: flex-end;
  }

  .euiSearchBar__searchHolder {
    display: none;
  }
`
export const ContractAddressText = styled.span`
  color: #777e90;
`
export const StyledButton = styled(EuiButton)`
  padding: 0;
  min-width: 40px;
  border-radius: 5px;
  padding: 8px;
  background-color: #154ba0;

  .euiButton__text a {
    color: #fff;
  }

  img,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`
