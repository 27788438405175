import React, { useContext } from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { BiCheckCircle } from 'react-icons/bi'
import styled, { ThemeContext } from 'styled-components/macro'
import { useNetworkInfo } from 'hooks'
import { Col, Row } from 'components/Grid'
import { ExternalLink } from 'theme/components'
import { getExplorerLink } from 'utils'
import { useChainId } from 'state/wallet/hooks'

const LeftCol = styled(Col)`
  width: 15%;
  flex: 0 0 15%;
  padding-right: 0;
`
const RightCol = styled(Col)`
  width: 85%;
  flex: 0 0 85%;

  p {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`
export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}): JSX.Element {
  const chainId = useChainId()
  const networkInfo = useNetworkInfo(chainId)

  const theme = useContext(ThemeContext)

  return (
    <Row alignItems="center">
      <LeftCol>
        {success ? (
          <BiCheckCircle color={theme.green} size={30} />
        ) : (
          <IoAlertCircleOutline color={theme.red} size={30} />
        )}
      </LeftCol>
      <RightCol>
        <p>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</p>
        {chainId && (
          <ExternalLink href={getExplorerLink(networkInfo, hash, 'transaction')}>View on explorer</ExternalLink>
        )}
      </RightCol>
    </Row>
  )
}
