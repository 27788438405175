import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  NFTCheckbox,
  NFTDescription,
  NFTId,
  NFTImage,
  NFTItemInner,
  NFTItemWrapper,
  NFTName,
  TokenInfoButton,
} from '../Styled'
import { EuiToolTip } from '@elastic/eui'
import { IoIosInformationCircle } from 'react-icons/io'
import { HiOutlineCheck } from 'react-icons/hi'
import NFT from 'type/NFT'
import { AppDispatch } from 'state'
import { updateSelectedNFTs } from 'state/nft/actions'
import NFTInfoModal from './NFTInfoModal'
import { useSelectedNFTCollection, useSelectedNFTs } from 'state/nft/hooks'

interface NFTItemProps {
  nft: NFT
  isSelected: boolean
}

function NFTItem(props: NFTItemProps): JSX.Element {
  const { nft, isSelected } = props

  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelectedNFTCollection()
  const selectedNFTs = useSelectedNFTs()

  const [showNFTInfoModal, setShowNFTInfoModal] = useState(false)

  const onSelect = () => {
    if (selectedNFTs) {
      let list = [...selectedNFTs]
      if (!isSelected) {
        const _nft = list.find(n => n.tokenId === nft.tokenId)
        if (_nft == undefined) {
          list.push(nft)
        }
      } else {
        list = selectedNFTs.filter(n => n.tokenId !== nft.tokenId)
      }
      dispatch(updateSelectedNFTs({ selectedNFTs: list }))
    }
  }

  return (
    <NFTItemWrapper className={isSelected ? 'is-selected' : ''} onClick={onSelect}>
      <NFTItemInner>
        <NFTImage>
          <img src={nft.image} alt={nft.name ?? nftCollection?.name} />
          <NFTCheckbox className="nft-checkbox">
            <HiOutlineCheck />
          </NFTCheckbox>
        </NFTImage>
        <NFTDescription>
          <NFTName>
            <div>{nft.name ?? nftCollection?.name}</div>
            <TokenInfoButton onClick={() => setShowNFTInfoModal(true)}>
              <EuiToolTip content="Token Info">
                <IoIosInformationCircle />
              </EuiToolTip>
            </TokenInfoButton>
          </NFTName>
          <NFTId>{nft.tokenId}</NFTId>
        </NFTDescription>
      </NFTItemInner>
      {showNFTInfoModal && <NFTInfoModal nft={nft} onDismiss={() => setShowNFTInfoModal(false)} />}
    </NFTItemWrapper>
  )
}

export default NFTItem
