import { useState } from 'react'
import { InputTitle, SelectButton, WrappedTag } from 'theme/components'
import { TokenName } from '../Styled'
import TokenModal from './TokenModal'
import { useSelectedToken } from 'state/token/hooks'
import { useChainId } from 'state/wallet/hooks'
import AssetLogo from 'components/AssetLogo'

interface ITokenSelect {
  showNativeToken: boolean
  isListTokenSwap?: boolean
}

function TokenSelect(props: ITokenSelect): JSX.Element {
  const { showNativeToken, isListTokenSwap } = props
  const [showTokenListModal, setShowTokenListModal] = useState(false)

  const selectedToken = useSelectedToken()
  const chainId = useChainId()

  return (
    // @ts-ignore
    <div className={props.className}>
      <InputTitle>Asset</InputTitle>
      <SelectButton iconType="arrowDown" iconSide="right" onClick={() => setShowTokenListModal(true)}>
        {selectedToken ? (
          <>
            <AssetLogo src={selectedToken.logoURI} text={selectedToken.symbol} size="32px" marginRight="16px" />
            <TokenName>
              {selectedToken.name}
              {selectedToken.originChainId !== undefined && selectedToken.originChainId !== chainId && (
                <WrappedTag size="s" label="Wrapped" color="accent" />
              )}
            </TokenName>
          </>
        ) : (
          <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 32C7.1632 32 0 24.8368 0 16C0 7.1632 7.1632 0 16 0C24.8368 0 32 7.1632 32 16C32 24.8368 24.8368 32 16 32ZM16 9.2112L9.2112 16L16 22.7888L22.7888 16L16 9.2112Z"
                fill="currentColor"
              />
            </svg>
            <span style={{ color: '#154ba0' }}>Select a token</span>
          </>
        )}
      </SelectButton>
      {showTokenListModal && (
        <TokenModal
          showNativeToken={showNativeToken}
          onDismiss={() => setShowTokenListModal(false)}
          isListTokenSwap={isListTokenSwap ? true : false}
        />
      )}
    </div>
  )
}

export default TokenSelect
