import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiOutsideClickDetector } from '@elastic/eui'
import { Row } from 'components/Grid'
import { Col50 } from 'components/Grid/Styled'
import NFT from 'type/NFT'
import { NFTImageInfo, NFTInfoList } from '../Styled'

interface NFTInfoModalProps {
  nft: NFT
  onDismiss: () => void
}

function NFTInfoModal(props: NFTInfoModalProps): JSX.Element {
  const { nft, onDismiss } = props

  return (
    <EuiOutsideClickDetector onOutsideClick={onDismiss}>
      <EuiModal onClose={onDismiss}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>NFT Details</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <Row style={{ margin: 0 }}>
            <Col50 style={{ padding: 0 }}>
              <NFTImageInfo src={nft.image} alt={nft.name} />
            </Col50>
            <Col50 style={{ paddingRight: 0 }}>
              <NFTInfoList className="eui-yScroll">
                <p>
                  <strong>Name:</strong> {nft.name}
                </p>
                <p>
                  <strong>Token ID:</strong> {nft.tokenId}
                </p>
                <p>
                  <strong>Collection Name:</strong> {nft.collectionName}
                </p>
                <p>
                  <strong>Symbol:</strong> {nft.collectionSymbol}
                </p>
                {nft.attributes && (
                  <>
                    {nft.attributes.map((attr: any) => (
                      <p key={attr.trait_type}>
                        <strong>{attr.trait_type}:</strong> {attr.value}
                      </p>
                    ))}
                  </>
                )}
              </NFTInfoList>
            </Col50>
          </Row>
        </EuiModalBody>
      </EuiModal>
    </EuiOutsideClickDetector>
  )
}

export default NFTInfoModal
