import { EuiHealth, EuiSpacer, EuiSwitch, EuiTab, EuiTabs, Search } from '@elastic/eui'
import { Col, Container, Row } from 'components/Grid'
import RequestClaimForm from 'components/RequestClaimForm'
import NetworkInfo from 'components/TransactionTable/NetworkInfo'
import NFTTransactionTable from 'components/TransactionTable/NFTTransactionTable'
import TokenTransactionTable from 'components/TransactionTable/TokenTransactionTable'
import { useAllNetworks, useCurrentNetwork, useNFTTransactionHistory, useTransactionHistory } from 'hooks'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAccount, useChainId } from 'state/wallet/hooks'
import Network from 'type/Network'
import NFTTransaction from 'type/NFTTransaction'
import { parseResponseToNFTTransactions, parseResponseToTransactions } from 'utils/transactions'
import Transaction from 'type/Transaction'
import { getAllTokensFromConfig } from 'utils'
import Background1 from 'assets/images/bg-1.svg'

function Explorer(): JSX.Element {
  // Define search for tables
  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const networks = useAllNetworks(currentNetwork?.isMainnet)

  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(true)
  // use to save data
  const [transactions, setTransactions] = useState<Transaction[]>([])
  // use to render data
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])
  const [nftTransactions, setNFTTransactions] = useState<NFTTransaction[]>([])
  const { data, error: srwError } = useTransactionHistory()
  const { data: nftData, error: nftSRWError } = useNFTTransactionHistory()

  const search = {
    box: {
      incremental: true,
      placeholder: 'Enter the transaction hash...',
    },
    filters: [
      {
        type: 'field_value_selection',
        field: 'claimed',
        name: 'Status',
        multiSelect: false,
        options: [
          {
            value: true,
            name: 'Success',
            view: <EuiHealth color="success">Success</EuiHealth>,
          },
          {
            value: false,
            name: 'Awaiting claim',
            view: <EuiHealth color="warning">Awaiting claim</EuiHealth>,
          },
        ],
      },
      {
        type: 'field_value_selection',
        field: 'fromChainId',
        name: 'From Network',
        multiSelect: false,
        options: networks.map((network: Network) => ({
          value: network.chainId,
          name: network.name,
          view: <NetworkInfo network={network} className="networkInfo" />,
        })),
      },
      {
        type: 'field_value_selection',
        field: 'toChainId',
        name: 'To Network',
        multiSelect: false,
        options: networks.map((network: Network) => ({
          value: network.chainId,
          name: network.name,
          view: <NetworkInfo network={network} className="networkInfo" />,
        })),
      },
    ],
  } as Search

  // Build tabs
  const [selectedTabId, setSelectedTabId] = useState('token')

  const tabs = [
    {
      id: 'token',
      name: 'ERC20 Token',
      content: (
        <>
          <EuiSpacer />
          <TokenTransactionTable
            search={search}
            getAllTxns={true}
            transactions={transactionsData}
            error={srwError}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      id: 'nft',
      name: 'NFT',
      content: (
        <>
          <EuiSpacer />
          <NFTTransactionTable
            search={search}
            getAllTxns={true}
            transactions={nftTransactions}
            error={nftSRWError}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      id: 'tools',
      name: 'Tools',
      content: (
        <>
          <EuiSpacer style={{ backgroundColor: 'rgba(255, 255, 255, 0' }} />
          <RequestClaimForm />
        </>
      ),
    },
  ]

  const selectedTabContent = tabs.find(obj => obj.id === selectedTabId)?.content

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id)
  }

  const onChange = async (e: any) => {
    setChecked(e.target.checked)
    const _transactions = transactions
    if (e.target.checked) {
      filterSmallTx(_transactions)
    } else {
      setTransactionsData(_transactions)
    }
  }

  const filterSmallTx = async (_transactions: Transaction[]) => {
    const _transactionsFilter = []
    const _allTokens = await getAllTokensFromConfig()
    for (let i = 0; i < _transactions.length; i++) {
      let _minBridge = 0
      const _tokenList = _allTokens[`${_transactions[i].fromNetwork?.nativeCurrency.symbol.toLowerCase()}`]
      _tokenList.forEach((t: any) => {
        if (t.symbol.toString().toLowerCase() === _transactions[i].tokenSymbol.toLowerCase()) {
          _minBridge = t.minBridge ? t.minBridge : 0
        }
      })
      if (Number(_transactions[i].amount) > _minBridge) {
        _transactionsFilter.push(_transactions[i])
      }
    }
    setTransactionsData(_transactionsFilter)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        setIsLoading(true)
        const _transactions = await parseResponseToTransactions(data, chainId)
        const _nftTransactions = await parseResponseToNFTTransactions(nftData, chainId)
        setTransactions(_transactions)
        filterSmallTx(_transactions)
        setNFTTransactions(_nftTransactions)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [account, chainId, data, nftData])

  return (
    <Container>
      <Row style={{ marginTop: '2rem' }}>
        <Col>
          <EuiTabs
            style={{
              backgroundImage: `url(${Background1})`,
              backgroundSize: '170px 150px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right center',
            }}
          >
            <h3 style={{ color: '#fff' }}>Explorer</h3>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#4364a5',
                borderRadius: '0.5rem 0.5rem 0 0',
              }}
            >
              {tabs.map((tab: any, index: number) => (
                <EuiTab key={index} onClick={() => onSelectedTabChanged(tab.id)} isSelected={tab.id == selectedTabId}>
                  {tab.name}
                </EuiTab>
              ))}
            </div>
            {account ? <Link to="/explorer/my-transactions">My Transactions</Link> : <p>{''}</p>}
          </EuiTabs>
          <div style={{ backgroundColor: '#fff' }}>
            {selectedTabId == 'token' && (
              <EuiSwitch
                label={checked ? 'Show small tx' : 'Hide small tx'}
                checked={checked}
                onChange={(e: any) => onChange(e)}
                compressed
              />
            )}
          </div>
          {selectedTabContent}
        </Col>
      </Row>
    </Container>
  )
}

export default Explorer
