import { ContainerFullWidth, Row } from 'components/Grid'
import { Link } from 'react-router-dom'
import LogoPNG from 'assets/images/paradiso.svg'
import LogoMobilePNG from 'assets/images/mobile-logo.svg'
import { HeaderWrapper, LogoDekstop, LogoMobile } from './Styled'
import { EuiHeaderSectionItem } from '@elastic/eui'
import AccountButton from 'components/AccountButton'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'

function Header(): JSX.Element {
  return (
    <HeaderWrapper>
      <ContainerFullWidth>
        <Row alignItems="center" justifyContent="spaceBetween" gutterSize="none">
          <Link to="/">
            <LogoDekstop src={LogoPNG} alt="Paradiso Bridge" />
            <LogoMobile src={LogoMobilePNG} alt="Paradiso Bridge" />
          </Link>
          <DesktopNav />
          <EuiHeaderSectionItem border="none">
            <AccountButton />
            <MobileNav />
          </EuiHeaderSectionItem>
        </Row>
      </ContainerFullWidth>
    </HeaderWrapper>
  )
}

export default Header
