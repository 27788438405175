import React from 'react'
import styled from 'styled-components/macro'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { BiCheckCircle } from 'react-icons/bi'

import { useNetworkInfo } from 'hooks'
import { useAllTransactions } from 'state/transactions/hooks'
import { ExternalLink } from 'theme/components'
import { getExplorerLink } from 'utils'
import Loader from 'components/Loader'
import { Row } from 'components/Grid'
import { useChainId } from 'state/wallet/hooks'

const TransactionWrapper = styled.div`
  margin-top: 0.25rem;
`

const TransactionStatusText = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: calc(100% - 24px);
  :hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: #fff;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? '#27ae60' : '#ff6871')};
`

export default function Transaction({ hash }: { hash: string }): JSX.Element {
  const chainId = useChainId()
  const allTransactions = useAllTransactions()
  const tx = allTransactions?.[hash]
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  const networkInfo = useNetworkInfo(chainId)

  return (
    <TransactionWrapper>
      <TransactionState href={getExplorerLink(networkInfo, hash, 'transaction')} pending={pending} success={success}>
        <Row style={{ margin: 0, gap: '8px' }}>
          <IconWrapper pending={pending} success={success}>
            {pending ? <Loader /> : success ? <BiCheckCircle size={16} /> : <IoAlertCircleOutline size={16} />}
          </IconWrapper>
          <TransactionStatusText>{summary ?? hash}</TransactionStatusText>
        </Row>
      </TransactionState>
    </TransactionWrapper>
  )
}
