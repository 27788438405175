import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { EuiCollapsibleNav } from '@elastic/eui'
import { BiLinkExternal } from 'react-icons/bi'
import NAV_ITEMS from './items'
import { MenuToggle, MobileMenuA, MobileMenuItem, MobileMenuLink, SubText } from './Styled'

function MobileNav(): JSX.Element {
  const [navIsOpen, setNavIsOpen] = useState(false)

  const location = useLocation()
  const { pathname } = location

  return (
    <div className="eui-hideFor--l eui-hideFor--xl">
      <EuiCollapsibleNav
        isOpen={navIsOpen}
        onClose={() => setNavIsOpen(false)}
        closeButtonPosition="inside"
        button={
          <MenuToggle onClick={() => setNavIsOpen(!navIsOpen)}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 16 16"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
              role="img"
              aria-hidden="true"
            >
              <path d="M0 2h16v2H0V2zm0 5h16v2H0V7zm16 5H0v2h16v-2z"></path>
            </svg>
          </MenuToggle>
        }
      >
        <ul>
          {NAV_ITEMS.map(navItem => (
            <MobileMenuItem key={navItem.label}>
              {navItem.href && (
                <MobileMenuA
                  href={navItem.href}
                  target={navItem.target ?? '_self'}
                  className={`${pathname.includes(navItem.href) && 'active'}`}
                >
                  {navItem.label}
                  {navItem.target === '_blank' && <BiLinkExternal />}
                </MobileMenuA>
              )}
              {navItem.to && (
                <MobileMenuLink to={navItem.to} className={`${pathname.includes(navItem.to) && 'active'}`}>
                  {navItem.label}
                </MobileMenuLink>
              )}
              {navItem.subLabel && <SubText>{navItem.subLabel}</SubText>}
            </MobileMenuItem>
          ))}
        </ul>
      </EuiCollapsibleNav>
    </div>
  )
}

export default MobileNav
