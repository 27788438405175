import { EuiButton } from '@elastic/eui'
import styled from 'styled-components/macro'

export const WalletAddress = styled.button`
  font-size: 12px;
  border-radius: 100px;
  margin-left: 1rem;
  padding: 5px 22px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  transition: all 0.5s ease;
  background-color: rgba(255, 255, 255, 0);
  color: #fff;
  border: none;

  @media (min-width: 26.5625rem) {
    font-size: 1rem;
  }
`
export const NetworkButton = styled(EuiButton)`
  padding: 0.5rem;
  margin-right: 1rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #fff;
  min-width: 40px;

  @media (min-width: 90rem) {
    padding: 4px 20px;
    min-width: 110px;
  }

  @media (min-width: 992px) and (max-width: 1140px) {
    border: 1px solid #154ba0;
  }
`
export const NetworkButtonInner = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  text-transform: none;

  span {
    display: none;

    @media (min-width: 90rem) {
      display: block;
    }
  }
`
export const NetworkLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 0;

  @media (min-width: 90rem) {
    margin-right: 0.5rem;
  }
`
export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100px;

  @media (min-width: 992px) and (max-width: 1140px) {
    border: 1px solid #154ba0;
  }
`
export const TokenBalance = styled.div`
  display: none;
  padding: 5px 1rem;
  margin-right: -1.5rem;
  background-color: #727eaa;
  color: #fff;
  border-radius: 100px 0px 0px 100px;

  @media (min-width: 48rem) {
    display: block;
  }
`
export const Address = styled.span`
  color: #fff;

  @media (min-width: 992px) and (max-width: 1140px) {
    color: #154ba0;
  }
`
export const WrongNetwork = styled(EuiButton)`
  background-color: #a01c1b !important;

  .euiButton__text {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }
`
