import { EuiToolTip } from '@elastic/eui'
import { formatDistanceToNow, lightFormat, toDate } from 'date-fns'
import { StyledSpan } from '../Styled'

function RequestTime({ time }: { time: number }): JSX.Element {
  return (
    <EuiToolTip content={lightFormat(toDate(time * 1000), 'yyyy-MM-dd HH:mm:ss')}>
      <StyledSpan>{formatDistanceToNow(time * 1000, { addSuffix: true })}</StyledSpan>
    </EuiToolTip>
  )
}

export default RequestTime
