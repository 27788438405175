import styled from 'styled-components/macro'
import { HiOutlineCheck } from 'react-icons/hi'
import Network from 'type/Network'
import UnknownSVG from 'assets/images/unknown.svg'
import clsx from 'clsx'

const Row = styled.div`
  position: relative;
  display: grid;
  border-radius: 8px;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0px, 30px);
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  margin-bottom: 0.25rem;
  height: 56px;
  cursor: pointer;
  color: #fcfcfd;
  transition: all 0.5s ease;
  div {
    span {
      color: #727eaa;
    }
  }

  &:hover {
    background-color: #727eaa;
    div {
      span {
        color: #fff;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: initial;
  }

  &.selected {
    cursor: initial;
    background-color: #727eaa;
    div {
      span {
        color: #fff;
      }
    }
  }
`
const NetworkLogo = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
`
const NetworkNameWrap = styled.div`
  display: flex;
  align-items: center;
`
const NetworkName = styled.span`
  display: block;

  a {
    text-decoration: underline;
    color: #6c7284;

    &:hover {
      color: #fcfcfd;
    }
  }
`

interface NetworkRowProps {
  network: Network
  isSelected: boolean
  isDisabled: boolean
  onSelect: () => void
}

function NetworkRow(props: NetworkRowProps): JSX.Element {
  const { network, isSelected, isDisabled, onSelect } = props

  return (
    <Row
      className={clsx({
        ['selected']: isSelected,
        ['disabled']: isDisabled,
      })}
      onClick={() => (isSelected || isDisabled ? null : onSelect())}
    >
      <NetworkLogo src={network.logoURI ? network.logoURI : UnknownSVG} alt={network.name} />
      <NetworkNameWrap>
        <NetworkName>{network.name}</NetworkName>
      </NetworkNameWrap>
      {isSelected && <HiOutlineCheck size={24} color="#154ba0" />}
    </Row>
  )
}

export default NetworkRow
