import Network from 'type/Network'
import Step3Casper from './Step3Casper'
import Step3EVM from './Step3EVM'

interface Step3Props {
  network: Network
  prevStepCallback: () => void
  nextStepCallback: (txHash: string) => void
}

function Step3(props: Step3Props): JSX.Element {
  const { network, prevStepCallback, nextStepCallback } = props
  return (
    <>
      {network.notEVM ? (
        <>
          {network.key?.includes('casper') && (
            <Step3Casper prevStepCallback={prevStepCallback} nextStepCallback={nextStepCallback} />
          )}
        </>
      ) : (
        <Step3EVM prevStepCallback={prevStepCallback} nextStepCallback={nextStepCallback} />
      )}
    </>
  )
}

export default Step3
