import { MutableRefObject } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components/macro'
import TokenRow from './TokenRow'
import Token from 'type/Token'
import { useSelectedToken } from 'state/token/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateSelectedToken, updateTokenAmount } from 'state/token/actions'

const TokenListDiv = styled.div`
  height: 90%;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 280px;
  }
`

interface ITokenListProps {
  tokenList: Token[]
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  onSelectToken: (token: Token) => void
  onRemoveCustomToken: (token: Token) => void
}

function TokenList(props: ITokenListProps): JSX.Element {
  const { tokenList, onSelectToken, onRemoveCustomToken } = props

  const selectedToken = useSelectedToken()
  const dispatch = useDispatch<AppDispatch>()

  return (
    <>
      {tokenList.length > 0 && (
        <TokenListDiv>
          <div className="eui-yScroll">
            {tokenList.map(token => (
              <TokenRow
                key={token.address}
                token={token}
                isSelected={Boolean(token && selectedToken && token.address === selectedToken.address)}
                onSelect={() => {
                  if (token) {
                    onSelectToken(token)
                    dispatch(updateSelectedToken({ token }))
                    dispatch(updateTokenAmount({ amount: '' }))
                  }
                }}
                onRemoveCustomToken={() => {
                  if (token) {
                    onRemoveCustomToken(token)
                    dispatch(updateTokenAmount({ amount: '' }))
                    dispatch(updateSelectedToken({ token: undefined }))
                  }
                }}
              />
            ))}
          </div>
        </TokenListDiv>
      )}
    </>
  )
}

export default TokenList
