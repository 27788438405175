import { EuiBasicTableColumn, EuiFlexGroup, EuiInMemoryTable, EuiToolTip, Pagination, Search } from '@elastic/eui'
import { StyledButton, ContractAddressText, TableWrap } from './Styled'
import { useState } from 'react'
import TokenMap, { MapInfo } from 'type/TokenMap'
import Network from 'type/Network'
import { NetworkLogo } from 'theme/components'
import { NATIVE_TOKEN_ADDERSS, SUPPORTED_NETWORKS } from '../../constants'
import Copy from 'components/Copy'
import MetaMaskLogo from 'assets/images/metamask-small.png'
import { setupNetwork, shortenAddress } from 'utils'
import { BiLinkExternal } from 'react-icons/bi'
import { useChainId } from 'state/wallet/hooks'
import { useAllNetworks, useCurrentNetwork } from 'hooks'
import NetworkInfo from 'components/TransactionTable/NetworkInfo'
import AssetLogo from 'components/AssetLogo'

function TokensTable({ items, tokenMap }: { items: MapInfo[]; tokenMap: TokenMap[] }): JSX.Element {
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const allNetworks = useAllNetworks(currentNetwork?.isMainnet)

  const columns: EuiBasicTableColumn<any>[] = [
    {
      field: 'symbolTable',
      name: 'Symbol',
      sortable: true,
      width: '15%',
      render: function ({ logoURI, symbol }: { logoURI: string; symbol: string }): JSX.Element {
        return (
          <>
            <AssetLogo src={logoURI} text={symbol} size="18px" marginRight="1rem" />
            <span style={{ fontSize: 14, color: '#154ba0' }}>{symbol}</span>
          </>
        )
      },
    },
    {
      field: 'network',
      name: 'Chain',
      sortable: true,
      width: '20%',
      render: function (network: Network): JSX.Element {
        return (
          <>
            {network && (
              <>
                <NetworkLogo src={network.logoURI} alt={network.name} />
                <span style={{ fontSize: 14, color: '#154ba0' }}>{network.name}</span>
              </>
            )}
          </>
        )
      },
    },
    {
      field: 'address',
      name: 'Contract address',
      sortable: true,
      width: '50%',
      render: function (address: string): JSX.Element {
        const newAddress = address.length > 42 ? shortenAddress(address, 20) : address
        return (
          <div>
            {address === NATIVE_TOKEN_ADDERSS ? (
              <ContractAddressText>NATIVE</ContractAddressText>
            ) : (
              <Copy toCopy={address} iconPosition="right" showCopied={false} color="#154ba0" fontSize="14px">
                <span style={{ color: '#154ba0' }}>{newAddress}</span>
              </Copy>
            )}
          </div>
        )
      },
    },
    {
      field: 'metamaskInfo',
      name: '',
      sortable: true,
      width: '15%',
      render: function ({
        address,
        symbol,
        decimals,
        logoURI,
        network,
        explorerLink,
      }: {
        address: string
        symbol: string
        decimals: number
        logoURI: string
        network: Network
        explorerLink: string
      }): JSX.Element {
        // @ts-ignore
        const { ethereum } = window
        const isMetaMask = !!(ethereum && ethereum.isMetaMask)

        const addToMetaMask = async () => {
          if (chainId !== network.chainId) {
            try {
              const toNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === network.chainId) as Network
              await setupNetwork(toNetwork)
            } catch (error: any) {
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            }
          }

          try {
            await ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address,
                  symbol,
                  decimals,
                  image: logoURI,
                },
              },
            })
          } catch (error: any) {
            // we only care if the error is something _other_ than the user rejected the tx
            if (error?.code !== 4001) {
              console.error(error)
            }
          }
        }

        return (
          <>
            {address !== NATIVE_TOKEN_ADDERSS && isMetaMask && !currentNetwork?.notEVM && !network.notEVM && (
              <EuiToolTip position="top" content="Add to MetaMask">
                <StyledButton onClick={addToMetaMask}>
                  <img src={MetaMaskLogo} alt="Add to MetaMask" />
                </StyledButton>
              </EuiToolTip>
            )}
            <StyledButton>
              <a href={explorerLink} target="_blank">
                <BiLinkExternal size={18} />
              </a>
            </StyledButton>
          </>
        )
      },
    },
  ]

  // pagination
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: 5,
    pageSizeOptions: [20, 50, 100],
  } as Pagination

  // Sorting
  const [sortField, setSortField] = useState('name')
  const [sortDirection, setSortDirection] = useState('desc')
  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  // Search
  const search = {
    filters: [
      {
        type: 'field_value_selection',
        field: 'networkId',
        name: 'Chain',
        multiSelect: false,
        options: allNetworks.map((network: Network) => ({
          value: network.chainId,
          name: network.name,
          view: <NetworkInfo network={network} className="networkInfo" />,
        })),
      },
      {
        type: 'field_value_selection',
        field: 'originKey',
        name: 'Asset',
        multiSelect: false,
        options: tokenMap.map((tm: TokenMap) => ({
          value: tm.originKey,
          name: tm.name,
          view: (
            <EuiFlexGroup alignItems="center" gutterSize="none">
              <AssetLogo src={tm.logoURI} text={tm.name} size="18px" marginRight="8px" />
              <span>{tm.name}</span>
            </EuiFlexGroup>
          ),
        })),
      },
    ],
  } as Search

  const onTableChange = ({ page = {}, sort = {} }) => {
    // @ts-ignore
    const { index: _pageIndex, size: _pageSize } = page

    setPageIndex(_pageIndex)
    setPageSize(_pageSize)

    // @ts-ignore
    const { field: _sortField, direction: _sortDirection } = sort
    setSortField(_sortField)
    setSortDirection(_sortDirection)
  }

  return (
    <TableWrap>
      <EuiInMemoryTable
        itemId="_id"
        items={items}
        columns={columns}
        tableLayout="fixed"
        pagination={pagination}
        // @ts-ignore
        sorting={sorting}
        search={search}
        onTableChange={onTableChange}
      />
    </TableWrap>
  )
}

export default TokensTable
