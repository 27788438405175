import { TransactionResponse } from '@ethersproject/providers'
import { EuiButton, EuiConfirmModal, EuiFieldText } from '@elastic/eui'
import NetworkSelect from 'components/NetworkSelect'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { useActiveWeb3React, useCurrentNetwork } from 'hooks'
import { useRequestWidthrawAPI, useClaimTokenCallback } from 'hooks/useClaimTokensCallback'
import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useAccount, useChainId } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import { ConfirmMessage, InputTitle, Spacer } from 'theme/components'
import Network from 'type/Network'
import { useTransactionAdder } from 'state/transactions/hooks'
import axios, { AxiosError } from 'axios'
import { injected } from 'connectors'
import { ConnectorNames, connectorLocalStorageKey } from '../../constants'
import { sleep, setupNetwork } from 'utils'
import NetworkInfo from 'components/TransactionTable/NetworkInfo'

const BoxWrapper = styled.div<{ width?: number }>`
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 1px solid #dadfee;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;

  p {
    color: #727eaa;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    width: ${props => props.width}%;
  }
`
const InputField = styled(EuiFieldText)`
  background-color: #f1f2f6;
  padding: 1.25rem 1rem;
`

function RequestClaimForm(): JSX.Element {
  const { deactivate, activate } = useActiveWeb3React()

  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()

  const [sourceNetwork, setSourceNetwork] = useState<Network | undefined>(undefined)
  const [targetNetwork, setTargetNetwork] = useState<Network | undefined>(undefined)
  const [isClaiming, setIsClaiming] = useState(false)
  const [showNetworkModal, setShowNetworkModal] = useState(false)

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const requestWithdrawAPI = useRequestWidthrawAPI()
  const claimToken = useClaimTokenCallback()

  const addTransaction = useTransactionAdder()

  const onTxHashChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setTxHash(value)
  }

  const onSourceNetworkChange = (network: Network) => {
    setSourceNetwork(network)
  }

  const onTargetNetworkChange = (network: Network) => {
    setTargetNetwork(network)
  }

  const handleTransactionResponse = (response: TransactionResponse) => {
    addTransaction(response, {
      summary: 'Claim token from bridge',
    })

    setTxHash(response.hash)

    response.wait().then(() => {
      setAttemptingTxn(false)
      setIsClaiming(false)
    })
  }

  const onClaimToken = async () => {
    const fromChainId = sourceNetwork?.chainId
    const toChainId = targetNetwork?.chainId
    const index = 0

    if (chainId !== toChainId) {
      setShowNetworkModal(true)
    } else {
      if (fromChainId && toChainId) {
        try {
          setIsClaiming(true)

          let response = undefined
          let tryTimes = 10
          while (tryTimes > 0) {
            try {
              response = await requestWithdrawAPI(txHash, fromChainId, toChainId, index)
              if (response && response.status === 200 && response.data) {
                break
              }
              await sleep(300)
            } catch (error) {
              tryTimes--
            }
          }

          if (response && response.status === 200 && response.data && account) {
            setShowConfirm(true)
            setAttemptingTxn(true)

            await claimToken(
              response,
              account,
              txHash,
              fromChainId,
              fromChainId,
              toChainId,
              index,
              'originToken',
              '1',
              handleTransactionResponse,
            )
              .then((txResponse: TransactionResponse) => {
                handleTransactionResponse(txResponse)
              })
              .catch((error: any) => {
                setIsClaiming(false)
                setShowConfirm(false)
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                  console.error(error)
                }
              })
          }
        } catch (error) {
          setIsClaiming(false)
          setShowConfirm(false)
          setAttemptingTxn(false)
          console.error(error)

          if (axios.isAxiosError(error)) {
            const _e = error as AxiosError
            const message = _e.response?.data.errors
            toast.error(message ?? 'Could not claim. Please try again.', {
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                fontSize: '14px',
              },
              duration: 5000,
            })
          }
        }
      }
    }
  }

  const onSetupNetwork = async () => {
    try {
      let hasSetup = false

      if (targetNetwork) {
        if (currentNetwork?.notEVM) {
          window.localStorage.removeItem(connectorLocalStorageKey)
          deactivate()

          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)
          await activate(injected, async (error: Error) => {
            console.error(error)
          })

          window.location.reload()
        }
        hasSetup = await setupNetwork(targetNetwork)

        if (!targetNetwork || !hasSetup) {
          console.error('Could not setup network')
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        console.error(error)
      }
    } finally {
      setShowNetworkModal(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <div>
      <BoxWrapper>
        <p>
          Due to the decentralized nature of the DotOracle protocol and occasional technical issues with various
          blockchain networks, it's possible that your cross-chain transaction may not be visible in the DotOracle
          explorer.
        </p>
        <p>Don't fret, your assets are secure. To resolve this issue, you can use the provided tool.</p>
        <p>
          The DotOracle team will register your transaction promptly. Please allow 5 minutes after using the tool before
          checking for your transaction in the DotOracle explorer.
        </p>
      </BoxWrapper>
      <BoxWrapper width={60}>
        <div style={{ marginBottom: '2rem' }}>
          <InputTitle>Transaction Hash</InputTitle>
          <InputField style={{ color: '#000' }} value={txHash} autoFocus={true} onChange={onTxHashChange} />
        </div>
        <NetworkSelect
          selectedNetwork={sourceNetwork}
          otherNetwork={targetNetwork}
          side="SOURCE"
          showTitle={true}
          sourceText="From Chain"
          requestClaim={true}
          onChange={onSourceNetworkChange}
        />
        <Spacer height={1} />
        <NetworkSelect
          selectedNetwork={targetNetwork}
          otherNetwork={sourceNetwork}
          side="TARGET"
          showTitle={true}
          sourceText="To Chain"
          requestClaim={true}
          onChange={onTargetNetworkChange}
        />
        <Spacer height={1} />
        <EuiButton
          fill
          isDisabled={!sourceNetwork || !targetNetwork || !txHash}
          isLoading={isClaiming}
          onClick={onClaimToken}
          fullWidth
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          Request Claim
        </EuiButton>
      </BoxWrapper>
      {showNetworkModal && targetNetwork && (
        <EuiConfirmModal
          title="Important!"
          onCancel={() => setShowNetworkModal(false)}
          onConfirm={onSetupNetwork}
          cancelButtonText="Cancel"
          confirmButtonText="Change network"
          defaultFocusedButton="confirm"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p style={{ color: '#727eaa' }}>You are connecting to</p>
            <NetworkInfo network={currentNetwork}></NetworkInfo>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p style={{ color: '#727eaa' }}>Please change the network to</p>
            <NetworkInfo network={targetNetwork}></NetworkInfo>
            <p style={{ color: '#727eaa' }}>to claim your tokens / NFTs.</p>
          </div>
        </EuiConfirmModal>
      )}

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Claim your tokens"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </div>
  )
}

export default RequestClaimForm
