import { createAction } from '@reduxjs/toolkit'
import Network from 'type/Network'

export type PopupContent = {
  txn: {
    hash: string
    success: boolean
    summary?: string
  }
}

export const updateBlockNumber = createAction<{ chainId: number; blockNumber: number }>('application/updateBlockNumber')
export const addPopup = createAction<{ key?: string; removeAfterMs?: number | null; content: PopupContent }>(
  'application/addPopup',
)
export const removePopup = createAction<{ key: string }>('application/removePopup')
export const updateSourceNetwork = createAction<{ network: Network }>('application/updateSourceNetwork')
export const updateTargetNetwork = createAction<{ network: Network | undefined }>('application/updateTargetNetwork')
export const updateRecipientAddress = createAction<{ address: string }>('application/updateRecipientAddress')
export const updateBridgeAddress = createAction<{ address: string }>('application/updateBridgeAddress')
export const updateNFTBridgeAddress = createAction<{ address: string }>('application/updateNFTBridgeAddress')
