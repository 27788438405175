const theme = {
  primary: '#154ba0',
  secondary: '#4bc9f0',
  headerBg: '#1f1f1f',
  footerBg: '#000000',
  green: '#27ae60',
  red: '#ff6871',
}

export default theme
