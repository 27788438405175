import { useState, useEffect } from 'react'
import { Contract } from 'ethers'

const useNativeFee = (contract: Contract | null): [string, boolean] => {
  const [nativeFee, setNativeFee] = useState('0')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        if (contract) {
          setLoading(true)
          const _nativeFee = await contract.nativeFee()
          setNativeFee(_nativeFee)
        }
      } catch (error) {
        setNativeFee('0')
      } finally {
        setLoading(false)
      }
    })()
  }, [contract])

  return [nativeFee, loading]
}

export default useNativeFee
