import { useContext, useMemo } from 'react'
import { Contract } from 'ethers'
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import { useActiveWeb3React } from './useWeb3'
import { getContract } from '../utils'

// ABI
import ERC20_ABI from '../constants/abi/ERC20.abi.json'
import FTERC20_ABI from '../constants/abi/FTERC20.abi.json'
import ERC721_ABI from '../constants/abi/ERC721.abi.json'
import ERC1155_ABI from '../constants/abi/ERC1155.abi.json'
import BRIDGE_ABI from '../constants/abi/GenericBridge.abi.json'
import NFT_BRIDGE_ABI from '../constants/abi/NFTBridge.abi.json'
import MULTICALL_ABI from '../constants/abi/Multicall.abi.json'
import WBTC_ABI from '../constants/abi/WBTC.abi.json'
import BridgeAppContext from 'context/BridgeAppContext'
import { useCurrentNetwork, useNetworkInfo } from './useNetwork'
import { NFTType } from 'type/NFTCollection'
import { useAccount, useChainId } from 'state/wallet/hooks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useContract = (address?: string, abi?: any, withSignerIfPossible = true) => {
  const { ledgerAddress } = useContext(BridgeAppContext)
  const { library: web3Library } = useActiveWeb3React()
  const account = useAccount()
  const networkInfo = useCurrentNetwork()
  const library = ledgerAddress !== '' ? new JsonRpcProvider(networkInfo?.rpcURL ?? '') : (web3Library as Web3Provider)

  return useMemo(() => {
    if (!address || !abi || !web3Library) return null

    try {
      return getContract(address, abi, web3Library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, abi, library, withSignerIfPossible, account])
}

export const useTokenContract = (address?: string): Contract | null => {
  return useContract(address, ERC20_ABI)
}

export const useERC20CasperContract = (address?: string): Contract | null => {
  return useContract(address, FTERC20_ABI)
}

export const useNFTContract = (address?: string, type?: NFTType, withSignerIfPossible?: boolean): Contract | null => {
  const abi = type == NFTType.ERC721 ? ERC721_ABI : ERC1155_ABI
  return useContract(address, abi, withSignerIfPossible)
}

export const useBridgeContract = (address?: string): Contract | null => {
  return useContract(address, BRIDGE_ABI)
}

export const useNFTBridgeContract = (address?: string): Contract | null => {
  return useContract(address, NFT_BRIDGE_ABI)
}

export function useMulticallContract(): Contract | null {
  const chainId = useChainId()
  const network = useNetworkInfo(chainId)
  return useContract(network?.multicall, MULTICALL_ABI, false)
}

export const useWBTCContract = (address?: string): Contract | null => {
  return useContract(address, WBTC_ABI)
}
