import { EuiBetaBadge, EuiButton, EuiButtonEmpty, EuiModalBody, EuiModalHeader } from '@elastic/eui'
import { HTMLProps } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { BiLinkExternal } from 'react-icons/bi'

export const ModalHeader = styled(EuiModalHeader)`
  padding: 8px 8px 0;

  @media (min-width: 768px) {
    padding: 30px 30px 0;
  }
`
export const ModalBody = styled(EuiModalBody)`
  min-height: 50%;
  padding: 0;

  .euiModalBody__overflow {
    overflow: hidden;
  }

  .euiFormControlLayout {
    padding: 0 8px;
  }

  @media (min-width: 768px) {
    min-height: 370px;

    .euiFormControlLayout {
      padding: 0 30px;
    }
  }
`
export const BreakLine = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`
export const NoResultsFound = styled.p`
  margin-top: 1.5rem;
  text-align: center;
  color: #6c7284;
`
export const InputTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  font-size: 12px;
  font-weigth: 600;
  line-height: 1.5;
  color: #727eaa;
`
export const InputError = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #b50000;
`
export const SelectButton = styled(EuiButton)`
  margin-bottom: 1.5rem;
  border: 0;
  border-radius: 0.5rem;
  background-color: #f1f2f6;
  width: 100%;
  padding: 1rem;
  color: #353945;

  .euiButtonContent {
    justify-content: space-between;
  }

  .euiButton__text {
    display: flex;
    align-items: center;
    overflow: initial;

    svg {
      margin-right: 1rem;
    }
  }

  .euiBetaBadge {
    color: ${props => props.theme.primary};
  }
`
export const Dots = styled.span<{ position?: 'before' | 'after' }>`
  &::${({ position }) => position ?? 'after'} {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
export const NetworkLogo = styled.img`
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  height: 18px;
  width: 18px;
  border-radius: 100%;
`
export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.primary};
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`
export function ExternalLink({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  children,
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & { href: string }): JSX.Element {
  return (
    <StyledLink target={target} rel={rel} href={href} {...rest}>
      <span>{children}</span>
      <BiLinkExternal style={{ marginLeft: '0.5rem', display: 'inline-block', verticalAlign: 'middle' }} />
    </StyledLink>
  )
}
export const StyledClaimButton = styled(props => <EuiButtonEmpty {...props} />)`
  padding: 0;
  height: auto;
  font-size: 0.875rem;

  &.euiButtonEmpty {
    color: #fff;
    background-color: #154ba0;
  }

  &:disabled {
    color: #4c4e51;
  }
`
export const ConfirmMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  img {
    width: 24px !important;
    height: 24px !important;
    margin-bottom: 0 !important;
  }

  > div {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
`
export const WrappedTag = styled(EuiBetaBadge)`
  margin-left: 1rem;
  background-color: ${props => props.theme.primary}33;
  vertical-align: middle;
  text-transform: none;
  font-size: 10px;
  font-weight: 500;
  box-shadow: none;

  @media (min-width: 768px) {
    font-size: 12px;
  }
`
export const Spacer = styled.div<{ height?: number }>`
  margin-bottom: ${props => props.height ?? 2}rem;
`
export const RotatingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`
export const IconButton = styled(EuiButtonEmpty)`
  border: none;
  height: 3rem;
  color: #777e91;

  &:hover {
    color: #fff;
  }

  .euiButtonContent {
    line-height: 3rem;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s ease;
  }

  .euiButtonEmpty__text {
    line-height: 3rem;
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    height: 3rem;
  }
`
