import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import styled from 'styled-components/macro'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Popups from 'components/Popups'
import Web3ReactManager from 'components/Web3ReactManager'
import PageBackgroundPNG from 'assets/images/bg_full.svg'
import BridgeAppContext from 'context/BridgeAppContext'
import '@elastic/eui/dist/eui_theme_dark.css'
import 'assets/scss/style.scss'
import { useState } from 'react'
import EthApp from '@ledgerhq/hw-app-eth'
import CasperApp from '@zondax/ledger-casper'
import Bridge from 'pages/bridge'
import NFTBridge from 'pages/nft-bridge'
import Explorer from 'pages/explorer'
import MyTransactions from 'pages/my-transactions'
import { Toaster } from 'react-hot-toast'
import theme from 'theme'
import YourNFTs from 'pages/your-nfts'
import SupportedTokens from 'pages/supported-tokens'

const PageContainer = styled.div`
  display: flex;
  align-items: start;
  min-height: calc(100vh - 160px);
  background-color: #f1f2f6;
  background-image: url(${PageBackgroundPNG});
  background-size: cover;
  background-attachment: fixed;
  padding-top: 3rem;
  padding-bottom: 3rem;

  > div {
    flex: 0 100%;
    width: 100%;
  }
`
function App(): JSX.Element {
  const [ledgerAddress, setLedgerAddress] = useState('')
  const [ledgerPath, setLedgerPath] = useState('')
  const [ledgerApp, setLedgerApp] = useState<EthApp | CasperApp>()

  return (
    <Web3ReactManager>
      <BridgeAppContext.Provider
        value={{
          ledgerAddress,
          setLedgerAddress,
          ledgerPath,
          setLedgerPath,
          ledgerApp,
          setLedgerApp,
        }}
      >
        <Router>
          <Header />
          <PageContainer>
            <Routes>
              <Route path="/" element={<Bridge />} />
              <Route path="/nft-bridge" element={<NFTBridge />} />
              <Route path="/nft-bridge/:defaultStep" element={<NFTBridge />} />
              <Route path="/explorer" element={<Explorer />} />
              <Route path="/explorer/my-transactions" element={<MyTransactions />} />
              <Route path="/your-nfts" element={<YourNFTs />} />
              <Route path="/supported-tokens" element={<SupportedTokens />} />
            </Routes>
          </PageContainer>
          <Footer />
          <Popups />
        </Router>
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              backgroundColor: theme.headerBg,
              color: '#fff',
            },
          }}
        />
      </BridgeAppContext.Provider>
    </Web3ReactManager>
  )
}

export default App
