import styled from 'styled-components/macro'
import { HiOutlineCheck } from 'react-icons/hi'
import NFTCollection from 'type/NFTCollection'
// import { useCurrentNetwork } from 'hooks'
import { WrappedTag } from 'theme/components'
import { useChainId } from 'state/wallet/hooks'
import AssetLogo from 'components/AssetLogo'

const Row = styled.div`
  position: relative;
  display: grid;
  border-radius: 8px;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0px, 5rem);
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 2rem;
  margin-bottom: 0.25rem;
  height: 56px;
  cursor: pointer;
  color: #fcfcfd;
  transition: all 0.5s ease;

  &:hover {
    background-color: #727eaa;
  }

  &.disabled {
    opacity: 0.5;
    cursor: initial;
  }

  &.selected {
    cursor: initial;
    background-color: #e2007a1a;
  }
`
const CollectionName = styled.span`
  position: relative;
  color: #154ba0;

  .euiBetaBadge {
    color: ${props => props.theme.primary};
  }
`

interface NFTCollectionRowProps {
  collection: NFTCollection
  isSelected: boolean
  onSelect: () => void
}

function NFTCollectionRow(props: NFTCollectionRowProps): JSX.Element {
  const { collection, isSelected, onSelect } = props
  const chainId = useChainId()

  return (
    <Row className={isSelected ? 'selected' : ''} onClick={() => (isSelected ? null : onSelect())}>
      <AssetLogo src={collection.logoURI} text={collection.name} size="32px" />
      <CollectionName>
        {collection.name}
        {collection.originChainId !== undefined && collection.originChainId !== chainId && (
          <WrappedTag size="s" label="Wrapped" color="accent" />
        )}
      </CollectionName>
      {isSelected && <HiOutlineCheck size={24} color="#e2007a" />}
    </Row>
  )
}

export default NFTCollectionRow
