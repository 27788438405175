import React from 'react'
import styled from 'styled-components/macro'
import { useCopyClipboard } from 'hooks'

import { MdContentCopy } from 'react-icons/md'
import { BiCheckCircle } from 'react-icons/bi'

const CopyWrapper = styled.div<{ color?: string; fontSize?: string }>`
  color: ${({ color }) => color ?? '#888d9b'};
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  font-size: ${({ fontSize }) => fontSize ?? '0.825rem'};
  transition: all 0.5s ease;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: #fff;
  }

  svg {
    diplay: inline-block;
    vertical-align: middle;
  }
`
const TransactionStatusText = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
  font-size: 0.825rem;
`

export default function CopyHelper(props: {
  toCopy: string
  iconPosition: 'left' | 'right'
  showCopied: boolean
  children?: React.ReactNode
  color?: string
  fontSize?: string
}): JSX.Element {
  const [isCopied, setCopied] = useCopyClipboard(!props.showCopied)

  return (
    <CopyWrapper onClick={() => setCopied(props.toCopy)} color={props.color} fontSize={props.fontSize}>
      {props.iconPosition === 'left' && (
        <>
          {isCopied ? (
            <TransactionStatusText>
              <BiCheckCircle size={18} />
              {props.showCopied && <TransactionStatusText>Copied</TransactionStatusText>}
            </TransactionStatusText>
          ) : (
            <TransactionStatusText>
              <MdContentCopy size={18} />
            </TransactionStatusText>
          )}
        </>
      )}
      {isCopied && props.showCopied ? '' : props.children}
      {props.iconPosition === 'right' && (
        <>
          {isCopied ? (
            <TransactionStatusText>
              <BiCheckCircle size={18} />
              {props.showCopied && <TransactionStatusText>Copied</TransactionStatusText>}
            </TransactionStatusText>
          ) : (
            <TransactionStatusText>
              <MdContentCopy size={18} />
            </TransactionStatusText>
          )}
        </>
      )}
    </CopyWrapper>
  )
}
