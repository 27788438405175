import { EuiFlexGroup, EuiFlexGroupProps } from '@elastic/eui'
import styled from 'styled-components/macro'

const StyledRow = styled(EuiFlexGroup)`
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;

  > div {
    height: 100%;
  }

  &.euiFlexGroup--gutterLarge {
    margin-left: -15px;
    margin-right: -15px;
  }
`

export function Row(props: EuiFlexGroupProps): JSX.Element {
  return <StyledRow {...props}>{props.children}</StyledRow>
}
