import { EuiLoadingSpinner } from '@elastic/eui'
import React from 'react'

interface ConfirmationPendingContentProps {
  pendingText: string
}

function ConfirmationPendingContent(props: ConfirmationPendingContentProps): JSX.Element {
  const { pendingText } = props

  return (
    <div className="text-center">
      <EuiLoadingSpinner size="xxl" className="mb-2" />
      <h3 style={{ color: '#154ba0' }}>Waiting For Confirmation</h3>
      <p style={{ color: '#154ba0' }}>{pendingText}</p>
      <p style={{ color: '#154ba0' }}>
        <small>Confirm this transaction in your wallet</small>
      </p>
    </div>
  )
}

export default ConfirmationPendingContent
