import { EuiButtonEmpty } from '@elastic/eui'
import Box from 'components/Box'
import { BoxTitle } from 'components/Box/Styled'
import { useCurrentNetwork, useNetworkInfo, useTargetNetwork } from 'hooks'
import { useSelectedToken, useTokenAmount } from 'state/token/hooks'
import {
  BoxTitleWrap,
  InfoTitle,
  InfoValue,
  PreviousBox,
  PrevInfo,
  ConfirmationText,
  TokenAmount,
  Step3Title,
  NetworkLogo,
  RecipientAddress,
} from './Styled'
import UnknownSVG from 'assets/images/unknown.svg'
import { NATIVE_TOKEN_ADDERSS } from '../../constants'
import { useRecipientAddress } from 'hooks/useNetwork'
import SendButton from './SendButton'
import { shortenAddress } from 'utils'

interface Step3Props {
  backtoStep1Callback: () => void
  prevStepCallback: () => void
  nextStepCallback: (txHash: string) => void
}

function Step3(props: Step3Props): JSX.Element {
  const { backtoStep1Callback, prevStepCallback, nextStepCallback } = props

  const selectedToken = useSelectedToken()
  const tokenAmount = useTokenAmount()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()
  const originNetwork = useNetworkInfo(selectedToken?.originChainId)
  const recipientAddress = useRecipientAddress()

  return (
    <>
      <div>
        <PreviousBox>
          <BoxTitleWrap>
            <BoxTitle style={{ color: '#727eaa' }}>1. Choose blockchain & asset</BoxTitle>
            <EuiButtonEmpty onClick={backtoStep1Callback}>Edit</EuiButtonEmpty>
          </BoxTitleWrap>
          <PrevInfo>
            <div>
              <InfoTitle>From:</InfoTitle>
              <InfoValue>{sourceNetwork?.name}</InfoValue>
            </div>
            <div>
              <InfoTitle>To:</InfoTitle>
              <InfoValue>{targetNetwork?.name}</InfoValue>
            </div>
            <div>
              <InfoTitle>Asset:</InfoTitle>
              <InfoValue>{selectedToken?.symbol}</InfoValue>
            </div>
          </PrevInfo>
        </PreviousBox>
        <PreviousBox>
          <BoxTitleWrap>
            <BoxTitle style={{ color: '#727eaa' }}>2. Amount and address</BoxTitle>
            <EuiButtonEmpty onClick={prevStepCallback}>Edit</EuiButtonEmpty>
          </BoxTitleWrap>
          <PrevInfo>
            <div>
              <InfoTitle>Amount to send:</InfoTitle>
              <InfoValue>
                {tokenAmount} {selectedToken?.symbol}
              </InfoValue>
            </div>
            <div>
              <InfoTitle>To:</InfoTitle>
              <InfoValue>{targetNetwork?.name}</InfoValue>
            </div>
            <div>
              <InfoTitle>Asset:</InfoTitle>
              <InfoValue>{selectedToken?.symbol}</InfoValue>
            </div>
          </PrevInfo>
        </PreviousBox>
        {selectedToken && tokenAmount > 0 && originNetwork && sourceNetwork && targetNetwork && (
          <Box>
            <Step3Title>3. Confirmation</Step3Title>
            <ConfirmationText>
              You are transfering{' '}
              <TokenAmount>
                {tokenAmount} {selectedToken.symbol}
              </TokenAmount>{' '}
              from{' '}
              <strong>
                <NetworkLogo src={sourceNetwork.logoURI ? sourceNetwork.logoURI : UnknownSVG}></NetworkLogo>
                {sourceNetwork.name}
              </strong>{' '}
              to{' '}
              <strong>
                <NetworkLogo src={targetNetwork.logoURI ? targetNetwork.logoURI : UnknownSVG}></NetworkLogo>
                {targetNetwork.name}
              </strong>
              .
              <br />
              <>
                {originNetwork.chainId != targetNetwork.chainId && (
                  <>
                    {selectedToken.address != NATIVE_TOKEN_ADDERSS ? (
                      <span style={{ color: '#727eaa' }}>
                        This token was deployed originally on
                        <strong>
                          <NetworkLogo src={originNetwork.logoURI ? originNetwork.logoURI : UnknownSVG}></NetworkLogo>
                          {originNetwork.name}
                        </strong>
                        , you will receive DotOracle Wrapped {selectedToken.symbol} on
                        <strong>
                          <NetworkLogo src={targetNetwork.logoURI ? targetNetwork.logoURI : UnknownSVG}></NetworkLogo>
                          {targetNetwork.name}
                        </strong>
                      </span>
                    ) : (
                      <span style={{ color: '#727eaa' }}>
                        You will receive DotOracle Wrapped {originNetwork.nativeCurrency.symbol} on
                        <strong>
                          <NetworkLogo src={targetNetwork.logoURI ? targetNetwork.logoURI : UnknownSVG}></NetworkLogo>
                          {targetNetwork.name}
                        </strong>
                      </span>
                    )}
                    <br />
                    <br />
                  </>
                )}
              </>
              Recipient Address: <RecipientAddress>{shortenAddress(recipientAddress)}</RecipientAddress>
            </ConfirmationText>
            <SendButton nextStepCallback={nextStepCallback} />
          </Box>
        )}
      </div>
    </>
  )
}

export default Step3
