import { useCallback, useState } from 'react'
import { TransactionResponse } from '@ethersproject/providers'
import { EuiButton, EuiButtonEmpty, EuiConfirmModal, EuiHealth } from '@elastic/eui'
import NFTTransaction from 'type/NFTTransaction'
import { useCurrentNetwork } from 'hooks'
import Network from 'type/Network'
import NetworkInfo from '../../NetworkInfo'
import { connectorLocalStorageKey, ConnectorNames } from '../../../../constants'
import { setupNetwork, shortenAddress, sleep } from 'utils'
import axios, { AxiosError } from 'axios'
import { ConfirmMessage, Dots, StyledClaimButton } from 'theme/components'
import { useTransactionAdder } from 'state/transactions/hooks'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { useChainId, useConnectWalletCallback, useDeactivateCallback } from 'state/wallet/hooks'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateSourceNetwork, updateTargetNetwork } from 'state/application/actions'
import { updateSelectedToken, updateTokenAmount } from 'state/token/actions'
import { updateConnector } from 'state/wallet/actions'
import { useClaimNFTCallback, useRequestWidthrawNFTAPI } from 'hooks/useClaimNFTsCallback'
import { ConfirmationButtons } from 'pages/nft-bridge/Styled'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal/TransactionSubmittedContent'

function ClaimNFTForButtonEVM({ item, inline }: { item: NFTTransaction; inline?: boolean }): JSX.Element {
  const deactivate = useDeactivateCallback()

  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()

  const connectWalletCallback = useConnectWalletCallback()
  const dispatch = useDispatch<AppDispatch>()

  const [isClaiming, setIsClaiming] = useState(false)
  const [toNetwork, setToNetwork] = useState<Network>()
  const [showNetworkModal, setShowNetworkModal] = useState(false)

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const requestWithdrawNFTAPI = useRequestWidthrawNFTAPI()
  const claimNFT = useClaimNFTCallback()

  const addTransaction = useTransactionAdder()

  const handleTransactionResponse = (response: TransactionResponse) => {
    addTransaction(response, {
      summary: `Claim NFT from bridge for ${shortenAddress(item.account)}`,
    })

    setTxHash(response.hash)

    response.wait().then(() => {
      setAttemptingTxn(false)
      setIsClaiming(false)
    })
  }

  const handleClaimNFTs = async () => {
    const { requestHash, fromChainId, toChainId, index } = item

    setToNetwork(item.toNetwork)

    // Ask user if the currentChainId is different than the toChainId
    if (chainId !== toChainId) {
      setShowNetworkModal(true)
    } else {
      try {
        setIsClaiming(true)

        let response = undefined

        let tryTimes = 10
        while (tryTimes > 0) {
          try {
            response = await requestWithdrawNFTAPI(requestHash, fromChainId, toChainId, index)
            if (response && response.status === 200 && response.data) {
              break
            }
            await sleep(300)
          } catch (error) {
            tryTimes--
          }
        }

        if (response && response.status === 200 && response.data) {
          setShowConfirm(true)
          setAttemptingTxn(true)

          claimNFT(response, item.account, requestHash, toChainId, handleTransactionResponse)
            .then((txResponse: TransactionResponse) => {
              handleTransactionResponse(txResponse)
            })
            .catch((error: any) => {
              setIsClaiming(false)
              setShowConfirm(false)
              setAttemptingTxn(false)
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        }
      } catch (error) {
        setIsClaiming(false)
        setShowConfirm(false)
        setAttemptingTxn(false)
        console.error(error)

        if (axios.isAxiosError(error)) {
          const _e = error as AxiosError
          const message = _e.response?.data.errors
          toast.error(message ?? 'Could not claim. Please try again.', {
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
              fontSize: '14px',
            },
            duration: 5000,
          })
        }
      }
    }
  }

  const changeToEVMChain = async (network: Network) => {
    const hasSetup = await setupNetwork(network)

    if (hasSetup) {
      // @ts-ignore
      window.ethereum.on('chainChanged', () => {
        if (chainId === network.chainId && currentNetwork) {
          dispatch(updateTargetNetwork({ network: currentNetwork }))
        }
        // dispatch(updateSourceNetwork({ network }))
        dispatch(updateSelectedToken({ token: undefined }))
        dispatch(updateTokenAmount({ amount: '' }))
      })
    }
  }

  const onSetupNetwork = async (network: Network | undefined) => {
    if (!network) return
    try {
      const isBitcoin = network.name.toLowerCase().includes('bitcoin')
      const _isCasper = network.name.toLowerCase().includes('casper')

      // if current network is EVM
      if (!currentNetwork?.notEVM) {
        // if change to EVM
        if (!network.notEVM) {
          await changeToEVMChain(network)
        } else if (_isCasper) {
          const casperConnector =
            // @ts-ignore
            window.CasperWalletProvider !== undefined
              ? ConnectorNames.CasperWallet
              : // @ts-ignore
              window.casperDashHelper != undefined
              ? ConnectorNames.CasperDash
              : ConnectorNames.CasperSigner
          window.localStorage.setItem(connectorLocalStorageKey, casperConnector)
          dispatch(updateConnector({ connector: casperConnector }))

          await deactivate()
          await connectWalletCallback(ConnectorNames.CasperDash)
          window.location.reload()
        } else if (isBitcoin) {
          // window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Bitcoin)
          dispatch(updateConnector({ connector: ConnectorNames.Bitcoin }))
        }
      } else {
        // from Casper to EVM
        if (currentNetwork?.name.toLowerCase().includes('casper') && !network.notEVM) {
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))

          await deactivate()
          await changeToEVMChain(network)
          window.location.reload()
        }
        // from Bitcoin to EVM
        if (currentNetwork?.name.toLowerCase().includes('bitcoin') && !network.notEVM) {
          window.localStorage.removeItem(connectorLocalStorageKey)
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))
          await connectWalletCallback(ConnectorNames.Injected)
        }
      }

      dispatch(updateSourceNetwork({ network }))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error('Could not setup network')
        console.error(error)
      }
    } finally {
      setShowNetworkModal(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setIsClaiming(false)
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const confirmModalTopContent = () => {
    return (
      <p>
        Are you sure you want to claim for a different wallet? Please be aware that there may be transaction fees
        associated with this process, and the NFTs will be sent to the wallet address associated with the recorded
        transaction.
      </p>
    )
  }

  const confirmModalBottomContent = () => {
    return (
      <ConfirmationButtons>
        <EuiButtonEmpty onClick={handleDismissConfirmation}>Cancel</EuiButtonEmpty>
        <EuiButton fill isDisabled={isClaiming} onClick={handleClaimNFTs}>
          {isClaiming ? <Dots>Verifying</Dots> : <span>Yes, I understand</span>}
        </EuiButton>
      </ConfirmationButtons>
    )
  }

  return (
    <>
      {item.claimed && item.index >= 0 ? (
        <EuiHealth color="success">Success</EuiHealth>
      ) : (
        <>
          {inline ? (
            <StyledClaimButton isDisabled={isClaiming} onClick={() => setShowConfirm(true)}>
              {isClaiming ? <Dots>Verifying</Dots> : <span>Claim for</span>}
            </StyledClaimButton>
          ) : (
            <EuiButton isDisabled={isClaiming} onClick={() => setShowConfirm(true)}>
              Claim for
            </EuiButton>
          )}
        </>
      )}
      {showNetworkModal && toNetwork && (
        <EuiConfirmModal
          title="Important!"
          onCancel={() => setShowNetworkModal(false)}
          onConfirm={() => onSetupNetwork(toNetwork)}
          cancelButtonText="Cancel"
          confirmButtonText="Change network"
          defaultFocusedButton="confirm"
        >
          <ConfirmMessage>
            You are connecting to <NetworkInfo network={currentNetwork}></NetworkInfo>
          </ConfirmMessage>
          <ConfirmMessage>
            Please change the network to <NetworkInfo network={toNetwork}></NetworkInfo> to claim your NFTs.
          </ConfirmMessage>
        </EuiConfirmModal>
      )}

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Claim NFTs"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => (
          <ConfirmationModalContent topContent={confirmModalTopContent} bottomContent={confirmModalBottomContent} />
        )}
      />
    </>
  )
}

export default ClaimNFTForButtonEVM
