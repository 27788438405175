import { EuiButton } from '@elastic/eui'
import Box from 'components/Box'
import { BoxTitle } from 'components/Box/Styled'
import styled from 'styled-components/macro'
import Background1 from 'assets/images/bg-1.svg'

export const LatestTransactionsLink = styled.div`
  text-align: right;
  margin-bottom: 1rem;

  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`
export const TokenName = styled.span`
  color: #154ba0;
  max-width: 8rem;
`

// Step 1
export const StyledButton = styled(EuiButton)`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  @media (min-width: 62rem) {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
`
export const Reminder = styled.div`
  > div {
    text-transform: uppercase;
    font-weight: 500;
    color: ${props => props.theme.primary};
  }

  ul {
    margin-top: 1rem;
    padding-left: 1.5rem;
    font-size: 0.875rem;
  }

  li {
    list-style-type: disc;
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }
`

// Step 2
export const BoxTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  h3 {
    margin-bottom: 0;
  }
`
export const PreviousBox = styled(Box)`
  margin-bottom: 1rem;
`
export const PrevInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem;

  @media (min-width: 62rem) {
    gap: 3rem;
  }
`
export const InfoTitle = styled.span`
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #777e90;
  margin-right: 0.5rem;
`
export const InfoValue = styled.span`
  font-size: 0.875rem;
  color: #727eaa;
`
export const Step2Button = styled(EuiButton)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  margin-top: 1.5rem;
`

// Step 3
export const Step3Title = styled(BoxTitle)`
  margin-bottom: 1rem;
  color: #727eaa;
`
export const ConfirmationText = styled.div`
  margin-bottom: 2rem;
  color: #727eaa;
`
export const TokenAmount = styled.span`
  color: #154ba0;
  line-height: 2;
  font-weight: 500;
`
export const NetworkLogo = styled.img`
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  height: 18px;
  width: 18px;
`
export const ConfirmButton = styled(EuiButton)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
`
export const RecipientAddress = styled.span`
  color: #154ba0;
  font-weight: 500;
`
export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #154ba0;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 1.5rem 0;
  background-image: url(${Background1});
  background-size: 170px 150px;
  background-repeat: no-repeat;
  background-position: right center;
`
export const RemindText = styled.li`
  color: #727eaa;
`
