import { CommonProps } from '@elastic/eui'
import { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

const StyledCol = styled.div`
  padding: 0 15px;
  flex: 0 0 auto;
  width: 100%;
`

export function Col(props: CommonProps & HTMLAttributes<HTMLDivElement | HTMLSpanElement>): JSX.Element {
  return <StyledCol {...props}>{props.children}</StyledCol>
}
