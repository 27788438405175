import { formatEther } from 'ethers/lib/utils'
import { useCurrentNetwork } from 'hooks'
import { useERC20CasperContract } from 'hooks/useContract'
import useNativeFee from 'hooks/useNativeFee'
import { useSelectedToken, useTokenAmount } from 'state/token/hooks'
import styled from 'styled-components/macro'
import { Dots } from 'theme/components'

const FeeBoxWrapper = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border: 1px solid #23262f;
  border-radius: 0.5rem;
  padding: 1rem;

  h4 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 1rem;
  }

  a {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  @media (min-width: 48rem) {
    display: flex;

    > div {
      flex: 0 0 50%;
      width: 50%;
    }

    a {
      margin-top: 0;
    }
  }
`
const FeeFlex = styled.div`
  display: flex;
  gap: 1rem;
`
const FeeTitle = styled.p`
  margin-bottom: 0;
  color: #727eaa;
  font-size: 0.75rem;
  line-height: 1.5rem;
`
const FeeValue = styled.p`
  margin-bottom: 0;
  color: #000;
  font-size: 0.875rem;
  line-height: 1.5rem;
`

function FeeBox(): JSX.Element {
  const network = useCurrentNetwork()
  const selectedToken = useSelectedToken()
  const tokenAmount = useTokenAmount()
  const tokenContract = useERC20CasperContract(selectedToken?.address)
  const [nativeFee, isLoadingFee] = useNativeFee(tokenContract)
  const nativeFeeNum = Number(formatEther(nativeFee))

  return (
    <>
      {selectedToken && network && (
        <FeeBoxWrapper>
          <div>
            <h4 style={{ color: '#727eaa' }}>Fee</h4>
            <FeeFlex>
              <div>
                <FeeTitle>Bridge Fee (0.1%)</FeeTitle>
                <FeeValue>
                  {Number((tokenAmount * 0.001).toFixed(5))} {selectedToken?.symbol}
                </FeeValue>
              </div>
              {nativeFeeNum > 0 && (
                <div>
                  <FeeTitle>Native Fee</FeeTitle>
                  {isLoadingFee ? (
                    <Dots position="before">{network?.nativeCurrency.symbol}</Dots>
                  ) : (
                    <FeeValue>
                      {parseFloat(nativeFeeNum.toFixed(6))} {network?.nativeCurrency.symbol}
                    </FeeValue>
                  )}
                </div>
              )}
            </FeeFlex>
          </div>
          <div>
            <a href="#" target="_blank">
              How the bridge fee works?
            </a>
            <FeeTitle>You will receive</FeeTitle>
            <FeeValue>
              {Number((tokenAmount * 0.999).toFixed(5))}
              {selectedToken?.originChainId === network?.chainId
                ? ` d${selectedToken?.symbol}`
                : ` ${selectedToken?.originSymbol}`}
            </FeeValue>
          </div>
        </FeeBoxWrapper>
      )}
    </>
  )
}

export default FeeBox
