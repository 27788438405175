import { useEffect, useState } from 'react'
import { BoxTitle } from 'components/Box/Styled'
import { Row } from 'components/Grid'
import NetworkSelect from 'components/NetworkSelect'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppDispatch } from 'state'
import { StyledButton, StyledNote, SwapBtn } from './Styled'
import { setupNetwork } from 'utils'
import WalletModal from 'components/WalletModal'
import { AiFillInfoCircle, AiFillVideoCamera } from 'react-icons/ai'
import NFTCollectionSelect from './components/NFTCollectionSelect'
import { updateSourceNetwork, updateTargetNetwork } from 'state/application/actions'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { useCurrentNetwork, useTargetNetwork } from 'hooks'
import { MdOutlineSwapVert } from 'react-icons/md'

interface Step1Props {
  nextStepCallback: () => void
}

function Step1(props: Step1Props): JSX.Element {
  const { nextStepCallback } = props

  const account = useAccount()
  const chainId = useChainId()
  const sourceNetwork = useCurrentNetwork()

  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelector((state: AppState) => state.nft.nftCollection)
  const targetNetwork = useTargetNetwork()

  // const supportedChainIdsCallback = useSupportedChainIds(sourceNetwork?.nftBridge ?? '', nftCollection?.address ?? '')

  const [showWalletModal, setShowWalletModal] = useState(false)
  // const [supportedChainIds, setSupportedChainIds] = useState<number[]>([])
  const [disableButton, setDisableButton] = useState(
    sourceNetwork === undefined || targetNetwork === undefined || nftCollection === undefined,
  )

  const swapSourceAndTarget = async () => {
    if (targetNetwork) {
      const hasSetup = await setupNetwork(targetNetwork)

      if (hasSetup) {
        // @ts-ignore
        window.ethereum.on('chainChanged', () => {
          if (chainId == sourceNetwork?.chainId && sourceNetwork) {
            dispatch(updateSourceNetwork({ network: targetNetwork }))
            dispatch(updateTargetNetwork({ network: sourceNetwork }))
          }
        })
      }
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      // const _supportedChainIds = await supportedChainIdsCallback()
      // setSupportedChainIds(_supportedChainIds)

      let _disableButton = false

      if (nftCollection === undefined) {
        _disableButton = true
      }

      // if (targetNetwork !== undefined) {
      //   if (_supportedChainIds.length > 0 && !_supportedChainIds.includes(targetNetwork?.chainId)) {
      //     _disableButton = true
      //   }
      // } else {
      //   _disableButton = true
      // }
      setDisableButton(_disableButton)
    })()
  }, [chainId, sourceNetwork, targetNetwork, nftCollection])

  // useEffect(() => {
  //   if (nftCollection) {
  //     dispatch(
  //       updateNFTCollection({
  //         nftCollection: {
  //           ...nftCollection,
  //           supportedChainIds: supportedChainIds,
  //         },
  //       }),
  //     )
  //   }
  // }, [targetNetwork])

  return (
    <>
      <BoxTitle>Transfer NFTs between blockchains</BoxTitle>
      {sourceNetwork && (
        <div style={{ marginBottom: '2rem' }}>
          <NFTCollectionSelect showTitle={true} />
        </div>
      )}
      <NetworkSelect
        selectedNetwork={sourceNetwork}
        otherNetwork={targetNetwork}
        side="SOURCE"
        showTitle={true}
        nftBridge={true}
      />
      <Row justifyContent="center">
        <SwapBtn onClick={swapSourceAndTarget}>
          <MdOutlineSwapVert />
        </SwapBtn>
      </Row>
      <NetworkSelect
        selectedNetwork={targetNetwork}
        otherNetwork={sourceNetwork}
        side="TARGET"
        showTitle={true}
        nftBridge={true}
      />
      {/* {targetNetwork !== undefined &&
            supportedChainIds.length > 0 &&
            !supportedChainIds.includes(targetNetwork?.chainId) && (
              <InputError style={{ marginTop: '-1rem' }}>Unsupported Network</InputError>
            )} */}
      {account ? (
        <StyledButton fill disabled={disableButton} onClick={nextStepCallback}>
          {targetNetwork == undefined ? 'Select destination chain' : 'Continue bridging'}
        </StyledButton>
      ) : (
        <>
          <StyledButton fill onClick={() => setShowWalletModal(true)}>
            Connect Wallet
          </StyledButton>
          {showWalletModal && <WalletModal onDismiss={() => setShowWalletModal(false)} />}
        </>
      )}
      <div>
        <StyledNote>
          <AiFillVideoCamera size={26} color="#727eaa" />
          <a href="#" style={{ color: '#727eaa' }}>
            How to use
          </a>
        </StyledNote>
        <StyledNote>
          <AiFillInfoCircle size={26} color="#727eaa" />
          <a href="#" style={{ color: '#727eaa' }}>
            What are NFTs?
          </a>
        </StyledNote>
      </div>
    </>
  )
}

export default Step1
