import { EuiButtonEmpty } from '@elastic/eui'
import AddressInput from 'components/AddressInput'
import { InputField } from 'components/AddressInput/Styled'
import AmountInput from 'components/AmountInput'
import Box from 'components/Box'
import { BoxTitle } from 'components/Box/Styled'
import WalletModal from 'components/WalletModal'
import BridgeAppContext from 'context/BridgeAppContext'
import { ethers } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useActiveWeb3React, useCurrentNetwork, useTargetNetwork, useTokenBalanceCallback } from 'hooks'
import { BoxTitleWrap, InfoTitle, InfoValue, Step2Button, PrevInfo, PreviousBox } from 'pages/bridge/Styled'
import { useContext, useEffect, useState } from 'react'
import { useSelectedToken, useTokenAmount } from 'state/token/hooks'
import { useAccount } from 'state/wallet/hooks'
import { Dots, InputTitle } from 'theme/components'
import FeeBox from './components/FeeBox'
import WarningMessage from './components/WarningMessage'
import { toast } from 'react-hot-toast'

interface Step2Props {
  prevStepCallback: () => void
  nextStepCallback: () => void
}

function Step2(props: Step2Props): JSX.Element {
  const { prevStepCallback, nextStepCallback } = props

  const { ledgerAddress } = useContext(BridgeAppContext)
  const { library: web3Library } = useActiveWeb3React()

  const account = useAccount()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()
  const selectedToken = useSelectedToken()
  const tokenAmount = useTokenAmount()

  const library = ledgerAddress !== '' ? new ethers.providers.JsonRpcProvider(sourceNetwork?.rpcURL ?? '') : web3Library

  const [isValidAddress, setIsValidAddress] = useState(false)
  const [showWalletModal, setShowWalletModal] = useState(false)

  const [tokenBalance, loading] = useTokenBalanceCallback(
    selectedToken ? selectedToken.address : undefined,
    selectedToken ? selectedToken.decimals : undefined,
    account,
    library,
    sourceNetwork,
  )

  const [minBridge, setMinBridge] = useState(0)
  const [buttonText, setButtonText] = useState('Next')
  const [disableButton, setDisableButton] = useState(
    sourceNetwork === undefined || targetNetwork === undefined || selectedToken === undefined || !isValidAddress,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addressInputCallback = (valid: boolean) => {
    setIsValidAddress(valid)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      let _disableButton = false

      if (selectedToken === undefined) {
        _disableButton = true
      }

      if (tokenAmount <= 0 || !isValidAddress || tokenAmount > tokenBalance) {
        _disableButton = true
      }
      setDisableButton(_disableButton)
    })()
  }, [sourceNetwork, targetNetwork, selectedToken, isValidAddress, tokenAmount, tokenBalance, loading])

  useEffect(() => {
    if (selectedToken) {
      const _minBridge = selectedToken ? Number(formatUnits(selectedToken.minBridge ?? '0', selectedToken.decimals)) : 0
      setMinBridge(_minBridge)
    }

    if (loading) {
      setButtonText('Loading')
      setDisableButton(true)
    } else {
      if (tokenAmount <= 0 || tokenAmount < minBridge) {
        setButtonText(minBridge ? `Minimum Bridge Amount: ${minBridge} ${selectedToken?.symbol}` : 'Next')
        setDisableButton(true)
      } else if (tokenAmount > tokenBalance) {
        setButtonText('Insufficient Balance')
        setDisableButton(true)
      } else if (!isValidAddress) {
        setButtonText('Invalid address')
        setDisableButton(true)
      } else {
        setButtonText('Next')
        setDisableButton(false)
      }
    }
  }, [selectedToken, isValidAddress, tokenAmount, minBridge, tokenBalance, loading])

  const moveToNextStep = () => {
    if (!minBridge) {
      nextStepCallback()
    } else {
      tokenAmount <= 0 || tokenAmount < minBridge
        ? toast.error(
            `Minimum Bridge Amount: ${minBridge} ${selectedToken?.symbol}. Please enter amount greater than ${minBridge} ${minBridge} ${selectedToken?.symbol}`,
          )
        : nextStepCallback()
    }
  }

  return (
    <>
      <div>
        <PreviousBox>
          <BoxTitleWrap>
            <BoxTitle style={{ color: '#154ba0' }}>1. Choose blockchain & asset</BoxTitle>
            <EuiButtonEmpty onClick={prevStepCallback}>Edit</EuiButtonEmpty>
          </BoxTitleWrap>
          <PrevInfo>
            <div>
              <InfoTitle>From:</InfoTitle>
              <InfoValue>{sourceNetwork?.name}</InfoValue>
            </div>
            <div>
              <InfoTitle>To:</InfoTitle>
              <InfoValue>{targetNetwork?.name}</InfoValue>
            </div>
            <div>
              <InfoTitle>Asset:</InfoTitle>
              <InfoValue>{selectedToken?.symbol}</InfoValue>
            </div>
          </PrevInfo>
        </PreviousBox>
        <Box>
          {sourceNetwork?.name.toLowerCase().includes('bitcoin') && (
            <div style={{ marginBottom: 32 }}>
              <InputTitle>From Address (One-time Address)</InputTitle>
              <InputField value="3F8XDahWxtSqyQs2L8WdJDuiPPkaYwe1SX" disabled />
            </div>
          )}
          <AddressInput
            title="DESTINATION ADDRESS"
            placeholder="Enter the destination wallet address"
            callback={addressInputCallback}
            showMyAccountBtn
          />
          <WarningMessage />
          <AmountInput />
          <FeeBox />
          {account ? (
            <Step2Button fill disabled={disableButton} onClick={moveToNextStep}>
              {buttonText}
              {loading && <Dots />}
            </Step2Button>
          ) : (
            <>
              <Step2Button fill onClick={() => setShowWalletModal(true)}>
                Connect Wallet
              </Step2Button>
              {showWalletModal && <WalletModal onDismiss={() => setShowWalletModal(false)} />}
            </>
          )}
        </Box>
      </div>
    </>
  )
}

export default Step2
