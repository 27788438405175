import { AiTwotoneCopy } from 'react-icons/ai'
import { FiExternalLink } from 'react-icons/fi'
import { MdCheckCircle } from 'react-icons/md'
import { EuiButton, EuiButtonEmpty, EuiToolTip } from '@elastic/eui'
import { useCopyClipboard, useCurrentNetwork } from 'hooks'
import { getExplorerLink } from 'utils'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

// Step4
const Step4Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const ResultMessage = styled.h3`
  margin-top: 1rem;
  margin-bottom: 0;
  color: #154ba0;

  @media (min-width: 48rem) {
    margin-top: 1.5rem;
  }
`
const TransactionWrapper = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #dadfee;
  border-radius: 8px;
  color: #777e90;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
  }

  .euiButtonEmpty--primary:enabled:focus {
    background-color: transparent;
  }

  .euiButtonEmpty,
  .euiButtonContent {
    padding: 0;
    border: 0;
  }
`
const TxIDSpan = styled.span`
  color: #154ba0;
  margin-left: 0.25rem;
`
const Step4Buttons = styled.div`
  margin-top: 2rem;
  width: 100%;
  text-align: center;

  .euiButton {
    margin-bottom: 1rem;
  }

  .euiButtonEmpty,
  .euiButtonContent {
    padding: 0;
    border: 0;
  }

  .euiButtonEmpty__text {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    color: ${props => props.theme.primary};
  }
`

// NFT Info Modal
const StyledButton = styled(EuiButton)`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  @media (min-width: 62rem) {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
`

interface Step4Props {
  txHash: string
  prevStepCallback: () => void
}

function Step4(props: Step4Props): JSX.Element {
  const { txHash, prevStepCallback } = props
  const [isCopied, setCopied] = useCopyClipboard(true)

  const network = useCurrentNetwork()
  const navigate = useNavigate()

  return (
    <>
      <div>
        <Step4Wrapper>
          <MdCheckCircle size={60} color="#154ba0" />
          <ResultMessage>Transaction is submitted</ResultMessage>
          <TransactionWrapper>
            TxID: <TxIDSpan>{`${txHash.substring(0, 6)}...${txHash.substring(txHash.length - 4)}`}</TxIDSpan>
            <EuiToolTip content={isCopied ? 'Copied' : 'Copy'}>
              <EuiButtonEmpty onClick={() => setCopied(txHash)}>
                <AiTwotoneCopy color="#4bC9f0" size={20} />
              </EuiButtonEmpty>
            </EuiToolTip>
            <EuiToolTip content="View on explorer">
              <a
                href={getExplorerLink(network, txHash, 'transaction')}
                target="__blank"
                rel="noopener noreferrer nofollow"
              >
                <FiExternalLink color="#4bC9f0" size={20} />
              </a>
            </EuiToolTip>
          </TransactionWrapper>
          <Step4Buttons>
            <StyledButton fill onClick={() => navigate('/explorer/my-transactions')}>
              Done
            </StyledButton>
            <EuiButtonEmpty onClick={prevStepCallback}>Bridge Again</EuiButtonEmpty>
          </Step4Buttons>
        </Step4Wrapper>
      </div>
    </>
  )
}

export default Step4
