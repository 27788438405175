import styled from 'styled-components/macro'

export const BoxWrapper = styled.div`
  padding: 2rem;
  background-color: #151515;
  border-radius: 0.5rem;
`
export const NetworkLogo = styled.img<{ disabled?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 1rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
export const NetworkName = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? '#515761' : '#154ba0')};
`
