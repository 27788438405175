import styled from 'styled-components/macro'

export const TableWrap = styled.div`
  background-color: #fff;
  padding: 1rem;
  .euiTable {
    img {
      margin-bottom: 0;
    }
  }

  &.historyTable .euiTableRowCell--hasActions .euiTableCellContent {
    @media (min-width: 48rem) {
      justify-content: flex-start;
      text-align: left;
    }
  }
`

export const TokenTableWrap = styled(TableWrap)`
  .euiTable {
    .euiTableHeaderCell:nth-child(2),
    .euiTableRowCell:nth-child(2),
    .euiTableHeaderCell:nth-child(7),
    .euiTableRowCell:nth-child(7) {
      display: none;
    }

    .euiTableRowCell--hasActions {
      width: calc(21% - 50px);
    }
  }
`

export const NFTTableWrap = styled(TableWrap)`
  .euiTable {
    .euiTableHeaderCell:nth-child(3),
    .euiTableRowCell:nth-child(3),
    .euiTableHeaderCell:nth-child(6),
    .euiTableRowCell:nth-child(6) {
      display: none;
    }

    .euiTableRowCell--hasActions {
      width: calc(20% - 50px);
    }
  }
`

// Table
export const StyledSpan = styled.span`
  font-size: 0.875rem;
  color: #154ba0;
  font-weight: 500;
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
`
export const NetworkLogo = styled.img`
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  height: 18px;
  width: 18px;
  border-radius: 100%;
`
export const NetworkName = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  margin: 0 !important;
`
export const CollapseWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #dadfee;
  padding-top: 0.5rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 2;
  color: #154ba0;

  a {
    color: #099b91;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    color: #154ba0;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > span {
      margin-right: 0.25rem;

      + span {
        margin: 0;
      }
    }
  }

  @media (min-width: 48rem) {
    flex-direction: row;
    align-items: center;

    > div {
      margin-right: 0.5rem;
    }
  }
`
