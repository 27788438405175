import { isDev } from 'utils'
import networksMainnet from 'config/networks.mainnet.json'
import networksTestnet from 'config/networks.testnet.json'
import Network from 'type/Network'

export const NetworkContextName = 'NETWORK'
export const connectorLocalStorageKey = 'connectorId'
export const NATIVE_TOKEN_ADDERSS = '0x1111111111111111111111111111111111111111'
export const BRIDGE_ADDRESSES_URL = 'https://raw.githubusercontent.com/dotoracle/bridge-addresses/main'
export const CASPER_TESTNET_API = 'https://event-store-api-clarity-testnet.make.services'
export const CASPER_MAINNET_API = 'https://event-store-api-clarity-mainnet.make.services'

export const SUPPORTED_NETWORKS: Network[] = isDev ? networksTestnet : networksMainnet
export const SUPPORTED_CHAINIDS = Object.values(SUPPORTED_NETWORKS).map(n => n.chainId)

export enum ConnectorNames {
  Injected = 'injected',
  OKXWallet = 'okxwallet',
  WalletConnect = 'walletconnect',
  CasperWallet = 'casperwallet',
  CasperSigner = 'caspersigner',
  CasperDash = 'casperdash',
  Ledger = 'ledger',
  Bitcoin = 'bitcoin',
}

export interface WalletInfo {
  connectorId: string
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connectorId: ConnectorNames.Injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connectorId: ConnectorNames.Injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  CASPERWALLET: {
    connectorId: ConnectorNames.CasperWallet,
    name: 'Casper Wallet',
    iconName: 'casper.png',
    description: '',
    href: null,
    color: '#E8831D',
  },
  CASPERSIGNER: {
    connectorId: ConnectorNames.CasperSigner,
    name: 'Casper Signer',
    iconName: 'casper.png',
    description: '',
    href: null,
    color: '#E8831D',
  },
  CASPERDASH: {
    connectorId: ConnectorNames.CasperDash,
    name: 'CasperDash',
    iconName: 'casper.png',
    description: '',
    href: null,
    color: '#E8831D',
  },
}
