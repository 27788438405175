import { CLPublicKey } from 'casper-js-sdk'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import BridgeAppContext from 'context/BridgeAppContext'
import { Contract, ethers } from 'ethers'
import { ApprovalState, useApproveCallback, useBridgeContract, useCurrentNetwork, useTargetNetwork } from 'hooks'
import { useNetworkInfo, useRecipientAddress } from 'hooks/useNetwork'
import { useCallback, useContext, useState } from 'react'
import { useSelectedToken, useTokenAmount } from 'state/token/hooks'
import { useAccount } from 'state/wallet/hooks'
import { ConfirmButton } from '../Styled'
import BRIDGE_ABI from '../../../constants/abi/GenericBridge.abi.json'
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import EthApp from '@ledgerhq/hw-app-eth'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateRecipientAddress, updateTargetNetwork } from 'state/application/actions'
import { Dots } from 'theme/components'
import { parseUnits } from 'ethers/lib/utils'
import { clearTokenCache } from 'state/token/actions'
import { NATIVE_TOKEN_ADDERSS } from '../../../constants'
import toast from 'react-hot-toast'
import { useBridgeAddress } from 'state/application/hooks'
import { useERC20CasperContract } from 'hooks/useContract'
import useNativeFee from 'hooks/useNativeFee'

function SendButtonEVM({ nextStepCallback }: { nextStepCallback: (txHash: string) => void }): JSX.Element {
  const { ledgerAddress, ledgerPath, ledgerApp } = useContext(BridgeAppContext)

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const account = useAccount()
  const selectedToken = useSelectedToken()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()
  const originNetwork = useNetworkInfo(selectedToken?.originChainId)
  const recipientAddress = useRecipientAddress()
  const tokenAmount = useTokenAmount()

  const bridgeAddress = useBridgeAddress()
  const bridgeContract = useBridgeContract(bridgeAddress)
  const tokenContract = useERC20CasperContract(selectedToken?.address)
  const [nativeFee] = useNativeFee(tokenContract)
  const [approval, approveCallback] = useApproveCallback(tokenAmount, bridgeAddress)

  const dispatch = useDispatch<AppDispatch>()
  const addTransaction = useTransactionAdder()

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const handleTransactionResponse = (response: TransactionResponse) => {
    addTransaction(response, {
      summary: `Send ${tokenAmount} ${selectedToken?.symbol} to bridge`,
    })

    setTxHash(response.hash)

    response.wait().then(async () => {
      dispatch(updateRecipientAddress({ address: '' }))
      dispatch(updateTargetNetwork({ network: undefined }))
      dispatch(clearTokenCache())

      setAttemptingTxn(false)

      nextStepCallback(response.hash)
    })
  }

  const onTransferToBridge = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)

      if (account && selectedToken && sourceNetwork && targetNetwork && bridgeContract) {
        const amount = parseUnits(tokenAmount.toString(), selectedToken.decimals)

        const abiCoder = new ethers.utils.AbiCoder()
        let toAddress = abiCoder.encode(['string'], [recipientAddress.toLowerCase()])
        if (targetNetwork.key?.includes('casper')) {
          const _accountHash = CLPublicKey.fromHex(recipientAddress).toAccountHashStr()
          toAddress = abiCoder.encode(['string'], [_accountHash.toLowerCase()])
        }

        // if user use Ledger
        if (ledgerAddress !== '') {
          const provider = new ethers.providers.JsonRpcProvider(sourceNetwork?.rpcURL)
          const bridgeContractEth = new Contract(bridgeAddress, BRIDGE_ABI, provider)
          const { data } = await bridgeContractEth.populateTransaction['requestBridge(address,bytes,uint256,uint256)'](
            selectedToken.address,
            toAddress,
            amount.toString(),
            targetNetwork.chainId,
          )

          const unsignedTx = {
            to: bridgeAddress,
            gasPrice: (await provider.getGasPrice())._hex,
            gasLimit: ethers.utils.hexlify(500000),
            value: amount,
            nonce: await provider.getTransactionCount(account ?? '', 'latest'),
            chainId: sourceNetwork?.chainId,
            data,
          }

          const transport = await TransportWebUSB.openConnected()

          if (transport != null && ledgerAddress != '' && ledgerPath != '' && ledgerApp instanceof EthApp) {
            const serializedTx = ethers.utils.serializeTransaction(unsignedTx).slice(2)

            const _signature = await ledgerApp.signTransaction(ledgerPath, serializedTx)
            const signature = {
              r: '0x' + _signature.r,
              s: '0x' + _signature.s,
              v: parseInt('0x' + _signature.v),
              from: account,
            }
            const signedTx = ethers.utils.serializeTransaction(unsignedTx, signature)
            provider
              .sendTransaction(signedTx)
              .then((response: TransactionResponse) => {
                handleTransactionResponse(response)
              })
              .catch((error: any) => {
                setShowConfirm(false)
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                  console.error(error)
                }
              })
          }
        } else {
          bridgeContract
            .requestBridge(selectedToken.address, toAddress, amount.toString(), targetNetwork.chainId, {
              value: selectedToken.address === NATIVE_TOKEN_ADDERSS ? amount : 0,
            })
            .then((response: TransactionResponse) => {
              handleTransactionResponse(response)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)

              if (error?.code === 'UNPREDICTABLE_GAS_LIMIT') {
                const errorMsg = error.error.data.message
                toast.error(errorMsg.replace('execution reverted: ', ''))
              } else {
                console.error(error)
              }
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const onRequestOriginTokenOnCasper = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)

      if (account && selectedToken && sourceNetwork && targetNetwork && tokenContract) {
        const amount = parseUnits(tokenAmount.toString(), selectedToken.decimals)

        const abiCoder = new ethers.utils.AbiCoder()
        let toAddress = abiCoder.encode(['string'], [recipientAddress.toLowerCase()])
        if (targetNetwork.key?.includes('casper')) {
          const _accountHash = CLPublicKey.fromHex(recipientAddress).toAccountHashStr()
          toAddress = abiCoder.encode(['string'], [_accountHash.toLowerCase()])
        }

        // if user use Ledger
        if (ledgerAddress !== '') {
          const provider = new ethers.providers.JsonRpcProvider(sourceNetwork?.rpcURL)
          const bridgeContractEth = new Contract(selectedToken.address, BRIDGE_ABI, provider)
          const { data } = await bridgeContractEth.populateTransaction['requestBridge(address,uint256)'](
            toAddress,
            amount.toString(),
          )

          const unsignedTx = {
            to: selectedToken.address,
            gasPrice: (await provider.getGasPrice())._hex,
            gasLimit: ethers.utils.hexlify(500000),
            nonce: await provider.getTransactionCount(account ?? '', 'latest'),
            chainId: sourceNetwork?.chainId,
            data,
            value: nativeFee,
          }

          const transport = await TransportWebUSB.openConnected()

          if (transport != null && ledgerAddress != '' && ledgerPath != '' && ledgerApp instanceof EthApp) {
            const serializedTx = ethers.utils.serializeTransaction(unsignedTx).slice(2)

            const _signature = await ledgerApp.signTransaction(ledgerPath, serializedTx)
            const signature = {
              r: '0x' + _signature.r,
              s: '0x' + _signature.s,
              v: parseInt('0x' + _signature.v),
              from: account,
            }
            const signedTx = ethers.utils.serializeTransaction(unsignedTx, signature)
            provider
              .sendTransaction(signedTx)
              .then((response: TransactionResponse) => {
                handleTransactionResponse(response)
              })
              .catch((error: any) => {
                setShowConfirm(false)
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                  console.error(error)
                }
              })
          }
        } else {
          tokenContract
            .requestBridge(toAddress, amount.toString(), {
              value: nativeFee,
            })
            .then((response: TransactionResponse) => {
              handleTransactionResponse(response)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)

              if (error?.code === 'UNPREDICTABLE_GAS_LIMIT') {
                const errorMsg = error.error.data.message
                toast.error(errorMsg.replace('execution reverted: ', ''))
              } else {
                console.error(error)
              }
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  return (
    <>
      {approval == ApprovalState.NOT_APPROVED || approval == ApprovalState.PENDING ? (
        <ConfirmButton
          fill
          isDisabled={attemptingTxn}
          isLoading={approval == ApprovalState.PENDING}
          onClick={approveCallback}
        >
          {approval === ApprovalState.PENDING ? <Dots>Approving {selectedToken?.symbol}</Dots> : <span>Approve</span>}
        </ConfirmButton>
      ) : (
        <ConfirmButton
          fill
          isDisabled={attemptingTxn}
          isLoading={showConfirm && attemptingTxn}
          onClick={() =>
            originNetwork && originNetwork.key?.includes('casper')
              ? onRequestOriginTokenOnCasper()
              : onTransferToBridge()
          }
        >
          Confirm & Send
        </ConfirmButton>
      )}

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Bridge your assets?"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default SendButtonEVM
