import { useCurrentNetwork } from 'hooks'
import SendButtonCasper from './SendButtonCasper'
import SendButtonEVM from './SendButtonEVM'

function SendButton({ nextStepCallback }: { nextStepCallback: (txHash: string) => void }): JSX.Element {
  const network = useCurrentNetwork()

  return (
    <>
      {network && (
        <>
          {network.notEVM ? (
            <>{network.key?.includes('casper') && <SendButtonCasper nextStepCallback={nextStepCallback} />}</>
          ) : (
            <SendButtonEVM nextStepCallback={nextStepCallback} />
          )}
        </>
      )}
    </>
  )
}
export default SendButton
