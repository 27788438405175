import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import {
  useActiveWeb3React,
  useCurrentNetwork,
  useTargetNetwork,
  useApproveERC20CasperCallback,
  ApprovalState,
} from 'hooks'
import { useDispatch } from 'react-redux'
import { useRecipientAddress } from 'hooks/useNetwork'
import { useCallback, useEffect, useState } from 'react'
import { useSelectedToken, useTokenAmount } from 'state/token/hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { ConfirmButton as StyledConfirmButton } from '../Styled'
import { AppDispatch } from 'state'
import { useTransactionAdder } from 'state/transactions/hooks'
import {
  CasperClient,
  CLByteArray,
  CLPublicKey,
  CLValueBuilder,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { genRanHex, getDeployFunction } from 'utils'
import { contractSimpleGetter, createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import { parseUnits } from 'ethers/lib/utils'
import { updateRecipientAddress, updateTargetNetwork } from 'state/application/actions'
import { clearTokenCache } from 'state/token/actions'
import { Dots } from 'theme/components'
import { useBridgeAddress } from 'state/application/hooks'
import axios from 'axios'

const ConfirmButton = ({
  attemptingTxn,
  showConfirm,
  onRequestBridgeERC20,
  onRequestBridgeBack,
}: {
  attemptingTxn: boolean
  showConfirm: boolean
  onRequestBridgeERC20: () => Promise<void>
  onRequestBridgeBack: () => Promise<void>
}): JSX.Element => {
  const selectedToken = useSelectedToken()

  return (
    <StyledConfirmButton
      fill
      isDisabled={attemptingTxn}
      isLoading={showConfirm && attemptingTxn}
      onClick={
        selectedToken?.originChainId == 96945816564243 || selectedToken?.originChainId == 131614895977472
          ? onRequestBridgeERC20
          : onRequestBridgeBack
      }
    >
      Confirm & Send
    </StyledConfirmButton>
  )
}

function SendButtonCasper({ nextStepCallback }: { nextStepCallback: (txHash: string) => void }): JSX.Element {
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const { connector } = useActiveWeb3React()
  const account = useAccount()
  const selectedToken = useSelectedToken()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()
  const recipientAddress = useRecipientAddress()
  const tokenAmount = useTokenAmount()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const bridgeAddress = useBridgeAddress()
  const [approval, approveCallback] = useApproveERC20CasperCallback(tokenAmount, bridgeAddress)

  const dispatch = useDispatch<AppDispatch>()
  const addTransaction = useTransactionAdder()

  const [wasmData, setWasmData] = useState(undefined)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        const response = await axios.get('/erc20_bridge_session.wasm', {
          responseType: 'arraybuffer',
        })
        if (response.status === 200 && response.data) {
          setWasmData(response.data)
        }
      } catch (error) {
        console.error('Could not get wasm data')
      }
    })()
  }, [])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const onRequestBridgeBack = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)

      if (account && selectedToken && sourceNetwork && targetNetwork) {
        const value = parseUnits(tokenAmount.toString(), selectedToken.decimals)
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, sourceNetwork?.key ?? 'casper-test', 1, 1800000)
        const contractHashAsByteArray = decodeBase16(selectedToken.address)
        const id = genRanHex(64).toLowerCase()
        const fee = await contractSimpleGetter(sourceNetwork.rpcURL, selectedToken.address, ['swap_fee'])

        const runTimeArgs = RuntimeArgs.fromMap({
          amount: CLValueBuilder.u256(value),
          fee: CLValueBuilder.u256(fee),
          to_chainid: CLValueBuilder.u256(targetNetwork?.chainId),
          receiver_address: CLValueBuilder.string(recipientAddress),
          id: CLValueBuilder.string(id),
        })

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'request_bridge_back',
            runTimeArgs,
          ),
          DeployUtil.standardPayment(10000000000),
        )

        if (deploy && provider) {
          // @ts-ignore
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(sourceNetwork.rpcURL)

          const deployFn = getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

          deployFn
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Send ${tokenAmount} ${selectedToken?.symbol} to bridge`,
              })

              setTxHash(hash)
              dispatch(updateRecipientAddress({ address: '' }))
              dispatch(updateTargetNetwork({ network: undefined }))
              dispatch(clearTokenCache())

              setAttemptingTxn(false)

              nextStepCallback(hash)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)
              console.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const onRequestBridgeERC20 = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)

      if (account && selectedToken && sourceNetwork && targetNetwork) {
        const value = parseUnits(tokenAmount.toString(), selectedToken.decimals)
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, sourceNetwork?.key ?? 'casper-test', 1, 1800000)
        const contractHashAsByteArray = decodeBase16(bridgeAddress)
        // const id = genRanHex(64).toLowerCase()
        // const fee = await contractSimpleGetter(sourceNetwork.rpcURL, sourceNetwork.bridge, ['bridge_fee'])

        if (!selectedToken.contractPackageHash) return

        let runtimeArgs: RuntimeArgs

        if (wasmData) {
          runtimeArgs = RuntimeArgs.fromMap({
            erc20_contract_package_hash: CLValueBuilder.key(
              new CLByteArray(decodeBase16(selectedToken.contractPackageHash)),
            ),
            amount: CLValueBuilder.u256(value.toString()),
            receiver_address: CLValueBuilder.string(recipientAddress),
            bridge_package_hash: createRecipientAddress(new CLByteArray(decodeBase16(bridgeAddress))),
          })
        } else {
          runtimeArgs = RuntimeArgs.fromMap({
            erc20_contract_package_hash: CLValueBuilder.key(
              new CLByteArray(decodeBase16(selectedToken.contractPackageHash)),
            ),
            amount: CLValueBuilder.u256(value.toString()),
            receiver_address: CLValueBuilder.string(recipientAddress),
            // fee: CLValueBuilder.u256(fee),
            // to_chainid: CLValueBuilder.u256(targetNetwork ? targetNetwork.chainId.toString() : '42'),
            // id: CLValueBuilder.string(id),
          })
        }

        let deploy: DeployUtil.Deploy | undefined = undefined
        if (wasmData) {
          const moduleBytes = new Uint8Array(Buffer.from(wasmData, 'binary'))
          deploy = DeployUtil.makeDeploy(
            deployParams,
            DeployUtil.ExecutableDeployItem.newModuleBytes(moduleBytes, runtimeArgs),
            DeployUtil.standardPayment(selectedToken.gasFee ?? 10000000000),
          )
        } else {
          deploy = DeployUtil.makeDeploy(
            deployParams,
            DeployUtil.ExecutableDeployItem.newStoredContractByHash(
              contractHashAsByteArray,
              'request_bridge_erc20',
              runtimeArgs,
            ),
            DeployUtil.standardPayment(selectedToken.gasFee ?? 10000000000),
          )
        }

        if (deploy && provider) {
          // @ts-ignore
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(sourceNetwork.rpcURL)

          const deployFn = getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

          deployFn
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Send ${tokenAmount} ${selectedToken?.symbol} to bridge`,
              })

              setTxHash(hash)
              dispatch(updateRecipientAddress({ address: '' }))
              dispatch(updateTargetNetwork({ network: undefined }))
              dispatch(clearTokenCache())

              setShowConfirm(false)
              setAttemptingTxn(false)

              nextStepCallback(hash)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)
              console.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      handleDismissConfirmation()
    }
  }

  return (
    <>
      {wasmData ? (
        <ConfirmButton
          attemptingTxn={attemptingTxn}
          showConfirm={showConfirm}
          onRequestBridgeERC20={onRequestBridgeERC20}
          onRequestBridgeBack={onRequestBridgeBack}
        />
      ) : (
        <>
          {approval == ApprovalState.NOT_APPROVED ||
          approval == ApprovalState.PENDING ||
          approval == ApprovalState.LOADING ? (
            <StyledConfirmButton
              fill
              isDisabled={attemptingTxn || approval == ApprovalState.LOADING}
              isLoading={approval == ApprovalState.PENDING || approval == ApprovalState.LOADING}
              onClick={approveCallback}
            >
              {approval === ApprovalState.PENDING ? (
                <Dots>Approving {selectedToken?.symbol}</Dots>
              ) : (
                <>{approval === ApprovalState.LOADING ? <Dots>Loading</Dots> : <span>Approve</span>}</>
              )}
            </StyledConfirmButton>
          ) : (
            <ConfirmButton
              attemptingTxn={attemptingTxn}
              showConfirm={showConfirm}
              onRequestBridgeERC20={onRequestBridgeERC20}
              onRequestBridgeBack={onRequestBridgeBack}
            />
          )}
        </>
      )}

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Bridge your assets?"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default SendButtonCasper
