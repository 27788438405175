import { EuiButton } from '@elastic/eui'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import { CasperClient, CLPublicKey, decodeBase16, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { useActiveWeb3React, useCurrentNetwork } from 'hooks'
import { useCallback, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { getContractVersionOnCasper, getDeployFunction } from 'utils'

function MintButtonCasper(): JSX.Element {
  const [minting, setMinting] = useState(false)

  const { connector } = useActiveWeb3React()
  const account = useAccount()
  const network = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const onMintNFT = async () => {
    try {
      if (network && account) {
        setShowConfirm(true)
        setAttemptingTxn(true)
        setMinting(true)

        const contractPackageHash = 'efe6c78ee5b585da7105e037855c5f6b26e88da7782c002595fe952b4294baac'
        const contractHash = await getContractVersionOnCasper(contractPackageHash, network)
        const deployParams = new DeployUtil.DeployParams(
          CLPublicKey.fromHex(account),
          network.key ?? 'casper-test',
          1,
          1800000,
        )
        const runtimeArgs = RuntimeArgs.fromMap({
          token_owner: createRecipientAddress(CLPublicKey.fromHex(account)),
        })

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(decodeBase16(contractHash), 'mint', runtimeArgs),
          DeployUtil.standardPayment(15000000000),
        )

        if (deploy && provider) {
          // @ts-ignore
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(network.rpcURL)
          const deployFn = getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

          deployFn
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Mint NFT`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    } finally {
      setMinting(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <EuiButton onClick={onMintNFT} isLoading={minting}>
        Mint
      </EuiButton>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Mint NFT"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default MintButtonCasper
