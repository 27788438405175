import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import theme from 'theme'
import { Web3Provider } from '@ethersproject/providers'
import { ThemeProvider } from 'styled-components/macro'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import store from './state'
import ApplicationUpdater from './state/application/updater'
import TransactionUpdater from './state/transactions/updater'
import MulticallUpdater from './state/multicall/updater'
import App from './App'
import { NetworkContextName } from './constants'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Provider store={store}>
            <Updaters />
            <App />
          </Provider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
