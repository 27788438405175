import NFTTransaction from 'type/NFTTransaction'
import { getExplorerLink } from 'utils'
import NetworkInfo from '../NetworkInfo'
import { CollapseWrap, Row } from '../Styled'
import { useAccount } from 'state/wallet/hooks'
import ClaimNFTForButtonEVM from './ClaimNFTButton/ClaimNFTForButtonEVM'
import { Spacer } from 'theme/components'
import { useCurrentNetwork } from 'hooks'
import { useLocation } from 'react-router-dom'
import { CLPublicKey } from 'casper-js-sdk'
import ClaimNFTForButtonCasper from './ClaimNFTButton/ClaimNFTForButtonCasper'

function NFTIds({ tokenIds }: { tokenIds: number[] }): JSX.Element {
  return (
    <>
      {tokenIds.map((id, index) => (
        <span key={id}>
          #{id}
          {index < tokenIds.length - 1 ? ', ' : ''}
        </span>
      ))}
    </>
  )
}

function NFTTxnDetails({ item }: { item: NFTTransaction }): JSX.Element {
  const account = useAccount()
  const currentNetwork = useCurrentNetwork()

  const text = `${item.tokenIds.length} ${item.originName ?? ''}`
  const path = item.contractPackageHash ? 'contract-package' : item.contractHash ? 'contract' : ''
  const address = item.contractPackageHash ?? item.contractHash ?? ''
  const isCasper = currentNetwork?.name.toLowerCase().includes('casper')
  const accountHash = account && isCasper ? CLPublicKey.fromHex(account).toAccountHashStr().substring(13) : ''

  const url = useLocation()

  return (
    <CollapseWrap>
      <Row>
        <div>
          <span>Transfer</span>
          <span className="text-secondary w-600">
            {item.originNetwork ? (
              <a
                href={getExplorerLink(item.originNetwork, address, path)}
                target="__blank"
                rel="noopener noreferrer nofollow"
              >
                {text}
              </a>
            ) : (
              <span>{text}</span>
            )}{' '}
            (<NFTIds tokenIds={item.tokenIds} />)
          </span>
        </div>
        <div>
          <span>from</span>
          <NetworkInfo network={item.fromNetwork} />
        </div>
        <div>
          <span>to</span>
          <NetworkInfo network={item.toNetwork} />
        </div>
      </Row>
      {item.account !== item.txCreator && (
        <Row>
          {item.toNetwork?.notEVM ? 'Recipient account hash:' : 'Recipient account address:'}&nbsp;
          <a href={`${item.accountUrl}`} target="__blank" rel="noopener noreferrer nofollow">
            {item.account}
          </a>
        </Row>
      )}
      {item.originNetwork && (
        <>
          <Row>
            This token was deployed on <NetworkInfo network={item.originNetwork} />
          </Row>
          {(item.fromNetwork?.key?.includes('casper') || item.toNetwork?.key?.includes('casper')) &&
            item.originNetwork?.key?.includes('casper') &&
            address && (
              <Row>
                Contract hash on <NetworkInfo network={item.fromNetwork?.notEVM ? item.fromNetwork : item.toNetwork} />
                <a
                  href={`${getExplorerLink(item.originNetwork, address, path)}`}
                  target="__blank"
                  rel="noopener noreferrer nofollow"
                >
                  {` ${address}`}
                </a>
              </Row>
            )}
        </>
      )}
      <Spacer height={0.5} />
      {account && !item.claimed && !url.pathname.includes('my-transactions') && (
        <>
          {isCasper && accountHash.toLowerCase() !== item.account.toLowerCase() && (
            <ClaimNFTForButtonCasper item={item} />
          )}
          {!isCasper && account && account.toLowerCase() !== item.account.toLowerCase() && (
            <ClaimNFTForButtonEVM item={item} />
          )}
        </>
      )}
    </CollapseWrap>
  )
}

export default NFTTxnDetails
