import { useMemo, useCallback, useState, useEffect } from 'react'
import Token from '../type/Token'
import { NATIVE_TOKEN_ADDERSS } from '../constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { useSelectedToken } from 'state/token/hooks'
import { BigNumber } from 'ethers'
import { useCurrentNetwork } from './useNetwork'
import { ERC20Client } from 'casper-erc20-js-client'
import {
  CLByteArray,
  CLPublicKey,
  CLValueBuilder,
  CasperClient,
  DeployUtil,
  RuntimeArgs,
  decodeBase16,
} from 'casper-js-sdk'
import { useActiveWeb3React } from './useWeb3'
import { getDeployFunction } from 'utils'
import Network from 'type/Network'
import { ApprovalState } from './useApproveCallback'
import { parseUnits } from 'ethers/lib/utils'
// import { useBlockNumber } from 'state/application/hooks'

const useTokenAllowance = (
  token?: Token,
  owner?: string,
  spender?: string,
  network?: Network,
): [BigNumber | undefined, boolean] => {
  const [allowance, setAllowance] = useState(0)
  const [loading, setLoading] = useState(false)
  // const latestBlockNumber = useBlockNumber()
  const pendingApproval = useHasPendingApproval(token?.address, spender)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (network && token && owner && spender) {
        setLoading(true)
        const erc20 = new ERC20Client(network.rpcURL, network.key ?? '', network.eventStream)
        await erc20.setContractHash(token.address)
        try {
          const _allowance = await erc20.allowances(
            CLPublicKey.fromHex(owner ?? ''),
            new CLByteArray(decodeBase16(spender ?? '')),
          )
          setAllowance(_allowance ?? 0)
        } catch (error) {
          console.error(error)
          setAllowance(0)
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [token, owner, spender, pendingApproval])

  // return useMemo(
  //   () => [token ? BigNumber.from(allowance.toString()) : undefined, loading],
  //   [token, allowance, loading, latestBlockNumber, pendingApproval],
  // )
  return [token ? BigNumber.from(allowance.toString()) : undefined, loading]
}

export const useApproveERC20CasperCallback = (
  amount: number,
  spender?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [ApprovalState, () => Promise<any>] => {
  const account = useAccount()
  const selectedToken = useSelectedToken()
  const { connector } = useActiveWeb3React()
  const provider = useWalletProvider()
  const connectorId = useConnectorId()
  const currentNetwork = useCurrentNetwork()

  const amountToApprove = parseUnits(amount.toString(), selectedToken?.decimals)

  const addTransaction = useTransactionAdder()
  const [currentAllowance, loading] = useTokenAllowance(selectedToken, account ?? undefined, spender, currentNetwork)
  const pendingApproval = useHasPendingApproval(selectedToken?.address, spender)

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    // native token
    if (selectedToken?.address === NATIVE_TOKEN_ADDERSS) return ApprovalState.APPROVED

    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN

    if (loading) return ApprovalState.LOADING

    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance || amountToApprove.toString() === '0') return ApprovalState.UNKNOWN
    return currentAllowance.lt(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, spender, pendingApproval])

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }

    if (!selectedToken) {
      console.error('no token')
      return
    }

    if (!amount) {
      console.error('missing amount to approve')
      return
    }

    if (!account) {
      console.error('no account')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    if (!currentNetwork) {
      console.error('invalid network')
      return
    }

    const erc20 = new ERC20Client(currentNetwork.rpcURL, currentNetwork.key ?? '', currentNetwork.eventStream)
    await erc20.setContractHash(selectedToken.address ?? '')

    const contractHashAsByteArray = decodeBase16(selectedToken.address)
    const senderKey = CLPublicKey.fromHex(account)
    const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
    const deploy = DeployUtil.makeDeploy(
      deployParams,
      DeployUtil.ExecutableDeployItem.newStoredContractByHash(
        contractHashAsByteArray,
        'approve',
        RuntimeArgs.fromMap({
          spender: CLValueBuilder.key(new CLByteArray(decodeBase16(spender))),
          amount: CLValueBuilder.u256(amountToApprove.toString()),
        }),
      ),
      DeployUtil.standardPayment(
        selectedToken.supportedChainIds && selectedToken.supportedChainIds?.length >= 2 ? 6000000000 : 1000000000,
      ),
    )

    if (deploy && provider) {
      // @ts-ignore
      const json = DeployUtil.deployToJson(deploy)
      const casperClient = new CasperClient(currentNetwork.rpcURL)

      const deployFn = getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

      return deployFn
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Approve ${amount.toString()} ${selectedToken?.symbol}`,
            approval: { tokenAddress: selectedToken.address, spender: spender },
          })
        })
        .catch((error: Error) => {
          console.error('Failed to approve token', error)
        })
    }
  }, [approvalState, selectedToken, amount, spender, addTransaction])

  return [approvalState, approve]
}
