import { EuiBasicTableColumn, EuiButtonIcon, EuiHealth, EuiInMemoryTable, Pagination, Search } from '@elastic/eui'
import { useCurrentNetwork } from 'hooks'
import { useState } from 'react'
import Transaction from 'type/Transaction'
import ClaimTokenButton from './components/ClaimTokenButton'
import ClaimLink from './components/ClaimLink'
import RequestLink from './components/RequestLink'
import RequestTime from './components/RequestTime'
import { StyledSpan, TokenTableWrap } from './Styled'
import TxnDetails from './components/TxnDetails'
import { useAccount } from 'state/wallet/hooks'
import { CLPublicKey } from 'casper-js-sdk'
import ClaimTokenForButton from './components/ClaimTokenButton/ClaimTokenForButton'

interface TokenTransactionTableProps {
  search: Search
  getAllTxns: boolean
  isLoading: boolean
  transactions: Transaction[]
  error: any
}

function TokenTransactionTable(props: TokenTransactionTableProps): JSX.Element {
  const { search, getAllTxns, isLoading, transactions, error } = props

  const account = useAccount()
  const currentNetwork = useCurrentNetwork()

  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<any>({})

  const toggleDetails = (item: Transaction) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap }

    if (itemIdToExpandedRowMapValues[item._id]) {
      delete itemIdToExpandedRowMapValues[item._id]
    } else {
      itemIdToExpandedRowMapValues[item._id] = <TxnDetails item={item} />
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues)
  }

  const columns: EuiBasicTableColumn<any>[] = [
    {
      field: 'requestTime',
      name: 'Request Time',
      sortable: true,
      width: '15%',
      render: function (time: number): JSX.Element {
        return <RequestTime time={time} />
      },
    },
    {
      field: 'fromChainId',
      name: 'From Chain Id',
    },
    {
      field: 'tokenSymbol',
      name: 'Token',
      width: '8%',
      render: function (tokenSymbol: string): JSX.Element {
        return <StyledSpan>{tokenSymbol}</StyledSpan>
      },
    },
    {
      field: 'requestHashLink',
      name: 'Request Tx',
      width: '18%',
      render: function ({
        networkName,
        explorerLogo,
        requestHash,
        requestHashUrl,
      }: {
        networkName: string
        explorerLogo: string
        requestHash: string
        requestHashUrl: string
      }): JSX.Element {
        return (
          <RequestLink
            networkName={networkName}
            explorerLogo={explorerLogo}
            requestHash={requestHash}
            requestHashUrl={requestHashUrl}
          />
        )
      },
    },
    {
      field: 'amountFormated',
      name: 'Amount',
      width: '20%',
      sortable: true,
      render: function (amountFormated: string): JSX.Element {
        return <StyledSpan>{amountFormated}</StyledSpan>
      },
    },
    {
      field: 'claimHashLink',
      name: 'Claim Tx',
      width: '18%',
      render: function ({
        networkName,
        explorerLogo,
        claimHash,
        claimHashUrl,
      }: {
        networkName: string
        explorerLogo: string
        claimHash: string
        claimHashUrl: string
      }): JSX.Element {
        return (
          <ClaimLink
            networkName={networkName}
            explorerLogo={explorerLogo}
            claimHash={claimHash}
            claimHashUrl={claimHashUrl}
          />
        )
      },
    },
    {
      field: 'toChainId',
      name: 'To Chain Id',
    },
    {
      field: 'claimed',
      name: '',
      width: 'calc(21% - 50px)',
      actions: [
        {
          render: (item: Transaction) => {
            const color = item.claimed ? 'success' : 'warning'
            const label = item.claimed ? 'Success' : 'Awaiting claim'
            let accountLowerCase = account ? account.toLowerCase() : ''

            if (account && currentNetwork?.name.toLowerCase().includes('casper')) {
              accountLowerCase = CLPublicKey.fromHex(account ?? '')
                .toAccountHashStr()
                .substring(13)
            }

            if (accountLowerCase === item.account.toLowerCase() && !item.claimed) {
              return <ClaimTokenButton item={item} />
            }

            if (getAllTxns) {
              return <EuiHealth color={color}>{label}</EuiHealth>
            }
            return <ClaimTokenForButton item={item} inline={true} />
          },
        },
      ],
    },
    {
      align: 'right',
      width: '40px',
      isExpander: true,
      render: (item: Transaction) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item)}
          aria-label={itemIdToExpandedRowMap[item._id] ? 'Collapse' : 'Expand'}
          iconType={itemIdToExpandedRowMap[item._id] ? 'arrowUp' : 'arrowDown'}
        />
      ),
    },
  ]

  // pagination
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: 5,
    pageSizeOptions: [20, 50, 100],
  } as Pagination

  // Sorting
  const [sortField, setSortField] = useState('requestTime')
  const [sortDirection, setSortDirection] = useState('desc')
  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  const onTableChange = ({ page = {}, sort = {} }) => {
    // @ts-ignore
    const { index: _pageIndex, size: _pageSize } = page

    setPageIndex(_pageIndex)
    setPageSize(_pageSize)

    // @ts-ignore
    const { field: _sortField, direction: _sortDirection } = sort
    setSortField(_sortField)
    setSortDirection(_sortDirection)
  }

  return (
    <TokenTableWrap className={getAllTxns ? 'historyTable' : ''}>
      {error ? (
        <span>Could not load data</span>
      ) : (
        <EuiInMemoryTable
          loading={isLoading}
          itemId="_id"
          items={transactions}
          columns={columns}
          isExpandable={true}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
          hasActions={false}
          tableLayout="fixed"
          pagination={pagination}
          // @ts-ignore
          sorting={sorting}
          search={search}
          onTableChange={onTableChange}
        />
      )}
    </TokenTableWrap>
  )
}

export default TokenTransactionTable
