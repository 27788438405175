import { createReducer, nanoid } from '@reduxjs/toolkit'
import {
  PopupContent,
  updateBlockNumber,
  updateSourceNetwork,
  updateTargetNetwork,
  addPopup,
  removePopup,
  updateRecipientAddress,
  updateBridgeAddress,
  updateNFTBridgeAddress,
} from './actions'
import Network from 'type/Network'

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number }
  readonly popupList: PopupList
  readonly sourceNetwork?: Network
  readonly targetNetwork?: Network
  readonly recipientAddress: string
  readonly bridgeAddress: string
  readonly NFTBridgeAddress: string
}

const initialState: ApplicationState = {
  blockNumber: {},
  popupList: [],
  sourceNetwork: undefined,
  targetNetwork: undefined,
  recipientAddress: '',
  bridgeAddress: '',
  NFTBridgeAddress: '',
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId])
      }
    })
    .addCase(addPopup, (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
      state.popupList = (key ? state.popupList.filter(popup => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach(p => {
        if (p.key === key) {
          p.show = false
        }
      })
    })
    .addCase(updateSourceNetwork, (state, action) => {
      state.sourceNetwork = action.payload.network
    })
    .addCase(updateTargetNetwork, (state, action) => {
      state.targetNetwork = action.payload.network
    })
    .addCase(updateRecipientAddress, (state, action) => {
      state.recipientAddress = action.payload.address
    })
    .addCase(updateBridgeAddress, (state, action) => {
      state.bridgeAddress = action.payload.address
    })
    .addCase(updateNFTBridgeAddress, (state, action) => {
      state.NFTBridgeAddress = action.payload.address
    }),
)
