import {
  NFTItemWrapperLoading,
  NFTItemInnerLoading,
  NFTImageLoading,
  NFTDescriptionLoading,
  NFTTextLoading,
} from '../Styled'

function LoadingNFTItem(): JSX.Element {
  return (
    <NFTItemWrapperLoading>
      <NFTItemInnerLoading>
        <NFTImageLoading />
        <NFTDescriptionLoading>
          <NFTTextLoading />
          <NFTTextLoading />
        </NFTDescriptionLoading>
      </NFTItemInnerLoading>
    </NFTItemWrapperLoading>
  )
}

export default LoadingNFTItem
