import { useContext, useState } from 'react'
import { EuiButton, EuiLoadingSpinner, EuiTextAlign, EuiToolTip } from '@elastic/eui'
import BridgeAppContext from 'context/BridgeAppContext'
import { useActiveWeb3React, useCurrentNetwork, useNetworkInfo, useTargetNetwork, useTokenBalanceCallback } from 'hooks'
import { ethers } from 'ethers'
import { NATIVE_TOKEN_ADDERSS, SUPPORTED_CHAINIDS } from '../../constants'
import {
  ButtonWrap,
  NetworkButton,
  NetworkButtonInner,
  NetworkLogo,
  TokenBalance,
  WalletAddress,
  WrongNetwork,
  Address,
} from './Styled'
import NetworksModal from 'components/NetworksModal'
import WalletModal from 'components/WalletModal'
import AccountInfoModal from 'components/AccountInfoModal'
import { formatNumber, shortenAddress } from 'utils'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { Dots } from 'theme/components'
import { usePendingTransactions } from 'state/transactions/hooks'
import { BiError } from 'react-icons/bi'

function AccountButton(): JSX.Element {
  const chainId = useChainId()
  const account = useAccount()
  const sourceNetwork = useCurrentNetwork()
  const targetNetwork = useTargetNetwork()

  const { library: web3Library } = useActiveWeb3React()
  const { ledgerAddress } = useContext(BridgeAppContext)

  const [showWalletModal, setShowWalletModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const [showNetworksModal, setShowNetworksModal] = useState(false)

  const networkInfo = useNetworkInfo(chainId)
  const library = ledgerAddress !== '' ? new ethers.providers.JsonRpcProvider(networkInfo?.rpcURL ?? '') : web3Library

  const pendingTransactions = usePendingTransactions()
  const pendingTransactionsCount = Object.values(pendingTransactions).length

  const [tokenBalance, loading] = useTokenBalanceCallback(
    NATIVE_TOKEN_ADDERSS,
    networkInfo?.nativeCurrency.decimals,
    account,
    library,
    networkInfo,
  )

  return (
    <>
      {sourceNetwork && (
        <>
          <NetworkButton color="text" onClick={() => (ledgerAddress !== '' ? null : setShowNetworksModal(true))}>
            <NetworkButtonInner>
              {sourceNetwork.logoURI && <NetworkLogo src={sourceNetwork.logoURI} alt={sourceNetwork.name} />}
              <span>{sourceNetwork.name}</span>
            </NetworkButtonInner>
          </NetworkButton>
          {showNetworksModal && (
            <NetworksModal
              title="Supported Networks"
              side="SOURCE"
              selectedNetwork={sourceNetwork}
              otherNetwork={targetNetwork}
              showAllNetworks={true}
              onDismiss={() => setShowNetworksModal(false)}
            />
          )}
        </>
      )}
      {account ? (
        <>
          <ButtonWrap>
            <TokenBalance>
              {loading ? (
                <Dots />
              ) : (
                <>
                  {tokenBalance ? formatNumber(tokenBalance.toFixed(4)) : '0'} {networkInfo?.nativeCurrency.symbol}
                </>
              )}
            </TokenBalance>
            <WalletAddress onClick={() => setShowAccountModal(true)}>
              <EuiTextAlign textAlign="left">
                {pendingTransactionsCount > 0 ? (
                  <>
                    <EuiLoadingSpinner size="m" />
                  </>
                ) : (
                  <Address>{shortenAddress(account)}</Address>
                )}
              </EuiTextAlign>
            </WalletAddress>
          </ButtonWrap>
          {showAccountModal && <AccountInfoModal onDismiss={() => setShowAccountModal(false)} />}
        </>
      ) : (
        <>
          {SUPPORTED_CHAINIDS.includes(chainId) ? (
            <>
              <EuiButton
                style={{ backgroundColor: 'rgba(255, 255, 255, 0' }}
                fill
                onClick={() => setShowWalletModal(true)}
              >
                Connect Wallet
              </EuiButton>
              {showWalletModal && <WalletModal onDismiss={() => setShowWalletModal(false)} />}
            </>
          ) : (
            <EuiToolTip content="You wallet's current network is unsupported.">
              <WrongNetwork fill>
                <BiError size={20} />
                Wrong Network
              </WrongNetwork>
            </EuiToolTip>
          )}
        </>
      )}
    </>
  )
}

export default AccountButton
