import React from 'react'
import styled from 'styled-components/macro'

export const StyledLogo = styled.img<{ size: string; marginRight?: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 100%;
  margin-right: ${({ marginRight }) => marginRight ?? '0'};
`
export const MissingImageLogo = styled.div<{ size?: string; marginRight?: string }>`
  --size: ${({ size }) => size};
  margin-right: ${({ marginRight }) => marginRight ?? '0'};
  border-radius: 100px;
  color: #fff;
  background-color: #151515;
  font-size: calc(var(--size) / 3);
  font-weight: 500;
  height: ${({ size }) => size ?? '24px'};
  line-height: ${({ size }) => size ?? '24px'};
  text-align: center;
  width: ${({ size }) => size ?? '24px'};
`

function AssetLogo({
  src,
  text,
  size,
  marginRight,
}: {
  src: string
  text: string
  size: string
  marginRight?: string
}): JSX.Element {
  return (
    <>
      {src ? (
        <StyledLogo src={src} alt={text} size={size} marginRight={marginRight} />
      ) : (
        <MissingImageLogo size={size} marginRight={marginRight}>
          {/* use only first 3 characters of Symbol for design reasons */}
          {text.toUpperCase().replace('$', '').replace(/\s+/g, '').slice(0, 3)}
        </MissingImageLogo>
      )}
    </>
  )
}

export default AssetLogo
