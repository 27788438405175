import { Flex, NetworkLogo, NetworkName } from './Styled'
import Network from 'type/Network'
import UnknownSVG from 'assets/images/unknown.svg'

function NetworkInfo({ network, className }: { network: Network | undefined; className?: string }): JSX.Element {
  return (
    <Flex className={className ?? ''}>
      {network ? (
        <>
          <NetworkLogo src={network.logoURI ? network.logoURI : UnknownSVG} alt={network.name} />
          <NetworkName>{network.name}</NetworkName>
        </>
      ) : (
        <>
          <NetworkLogo src={UnknownSVG} alt="Unknown network" />
          <NetworkName>Unknown network</NetworkName>
        </>
      )}
    </Flex>
  )
}

export default NetworkInfo
