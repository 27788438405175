import axios from 'axios'
import { BigNumber } from 'ethers'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getContractVersionOnCasper, getStateRootHash, getTokenLogos, ipfsURLConvert, isCacheOutdated } from 'utils'
import { AppState, AppDispatch } from 'state'
import { updateNFTList } from 'state/nft/actions'
import NFT from 'type/NFT'
import Network from 'type/Network'
import NFTCollection, { NFTType } from 'type/NFTCollection'
import { BRIDGE_ADDRESSES_URL, CASPER_MAINNET_API, CASPER_TESTNET_API, SUPPORTED_NETWORKS } from '../../constants'
import { useCurrentNetwork, useNFTBridgeContract, useNFTContract } from 'hooks'
import DefaultNFTImage from 'assets/images/nft.png'
import { useChainId } from 'state/wallet/hooks'
import { CEP78Client } from 'casper-cep78-js-client'
import { utils as CasperUtils } from 'casper-js-client-helper'
import { CLPublicKey } from 'casper-js-sdk'
import { useNFTBridgeAddress } from 'state/application/hooks'
import NFTTransaction from 'type/NFTTransaction'
import TokenMap from 'type/TokenMap'
import networksMainnet from 'config/networks.mainnet.json'
import networksTestnet from 'config/networks.testnet.json'
import { AccountNFT } from 'type/AccountNFT'

export const networks = [...networksMainnet, ...networksTestnet]

export const useSupportedNFTCollections = (
  network: Network | undefined,
  readTokenMap?: boolean,
): (() => Promise<NFTCollection[]>) => {
  const callback = useCallback(async (): Promise<NFTCollection[]> => {
    const nftCollectionList: NFTCollection[] = []
    try {
      if (network !== undefined) {
        const response = await axios.get(`${BRIDGE_ADDRESSES_URL}/nfts/${network.chainId}.json`)

        if (response.status === 200 && response.data) {
          const list: NFTCollection[] = response.data
          const existingAddresses = new Set<string>(nftCollectionList.map(n => n.address))

          for await (const nft of list) {
            let nftAddress = ''
            if (network.notEVM && network.key?.includes('casper')) {
              if (nft.contractHash) {
                nftAddress = nft.contractHash
              } else if (nft.contractPackageHash) {
                nftAddress = await getContractVersionOnCasper(nft.contractPackageHash, network)
              }
            } else {
              nftAddress = nft.address ?? nft.originContractAddress ?? ''
            }

            if (!existingAddresses.has(nftAddress.toLowerCase())) {
              nftCollectionList.push({
                ...nft,
                name: nft.name ?? nft.originName ?? '',
                address: nftAddress,
                symbol: nft.symbol ?? nft.originSymbol ?? '',
                contractHash: nft.contractHash ?? nftAddress ?? '',
                contractPackageHash: nft.contractPackageHash ?? '',
                supportedChainIds: [],
              })

              existingAddresses.add(nftAddress.toLowerCase())
            }
          }

          if (readTokenMap) {
            const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/tokenMap`)
            if (response2.status === 200) {
              const { tokenMap }: { tokenMap: TokenMap[] } = response2.data
              const tokenLogos = await getTokenLogos()

              tokenMap.forEach(item => {
                if (item.mapInfo && item.decimals === 0) {
                  Object.values(item.mapInfo).forEach(async info => {
                    const _network = SUPPORTED_NETWORKS.find(n => n.chainId === info.networkId) as Network
                    const isCasper = _network && _network.key?.includes('casper')

                    // we have contract package hash on Casper
                    if (isCasper) {
                      try {
                        const contractHash = await getContractVersionOnCasper(info.address, _network)
                        info.address = contractHash
                      } catch (error) {
                        // skip
                      }
                    }

                    if (info.networkId === network.chainId && !existingAddresses.has(info.address.toLowerCase())) {
                      const logo = tokenLogos.find(
                        _logo =>
                          _logo.address.toLowerCase() === item.address.toLowerCase() &&
                          _logo.chainId === item.networkId,
                      )
                      nftCollectionList.push({
                        name: info.name,
                        address: info.address,
                        symbol: item.symbol,
                        logoURI: logo?.logoURI || '',
                        originChainId: item.networkId,
                        originSymbol: item.symbol,
                        originContractAddress: item.address,
                        contractHash: isCasper ? info.address : '',
                        contractPackageHash: isCasper && item.address === info.address ? info.address : '',
                        type: NFTType.ERC721,
                        supportedChainIds: [],
                      })

                      existingAddresses.add(info.address.toLowerCase())
                    }
                  })
                }
              })
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
    return nftCollectionList
  }, [network])

  return callback
}

export const useSelectedNFTCollection = (): NFTCollection | undefined => {
  const nftCollection = useSelector((state: AppState) => state.nft.nftCollection)
  return useMemo(() => nftCollection, [nftCollection])
}

export const useNFTListCallback = (
  account: string | null | undefined,
  contractAddress: string,
  type?: NFTType,
): ((loadFromCache: boolean) => Promise<NFT[]>) => {
  const nftContract = useNFTContract(contractAddress, type)
  const dispatch = useDispatch<AppDispatch>()
  const chainId = useChainId()

  const callback = useCallback(
    async (loadFromCache: boolean): Promise<NFT[]> => {
      const nfts: NFT[] = []
      try {
        const key = `nft-${account}-${contractAddress}-${chainId}`
        const cache = localStorage.getItem(key)
        // @ts-ignore
        const cachedNFTs = JSON.parse(cache) as NFT[]
        if (cachedNFTs && loadFromCache && !isCacheOutdated()) {
          return cachedNFTs
        } else {
          if (account && nftContract) {
            const itemsCount = await nftContract.balanceOf(account)
            const collectionName = await nftContract.name()
            const collectionSymbol = await nftContract.symbol()
            for (let i = 0; i < itemsCount; i++) {
              const tokenId = await nftContract.tokenOfOwnerByIndex(account, i)
              const tokenURI = (await nftContract.tokenURI(tokenId)) as string

              try {
                const response = await axios.get(ipfsURLConvert(tokenURI))
                if (response.status == 200 && response.data) {
                  nfts.push({
                    name: response.data.name ?? collectionName,
                    tokenId: BigNumber.from(tokenId).toNumber(),
                    image: ipfsURLConvert(response.data.image ?? DefaultNFTImage),
                    attributes: response.data.attributes,
                    collectionName,
                    collectionSymbol,
                  })
                }
              } catch (error) {
                nfts.push({
                  name: collectionName,
                  tokenId: BigNumber.from(tokenId).toNumber(),
                  image: DefaultNFTImage,
                  attributes: [],
                  collectionName,
                  collectionSymbol,
                })
              }
            }

            nfts.sort((a, b) => {
              return a.tokenId - b.tokenId
            })

            dispatch(updateNFTList({ nftList: nfts }))
            localStorage.setItem(key, JSON.stringify(nfts))
            localStorage.setItem('dto-bridge-lastupdated', Date.now().toString())
          }
        }
      } catch (error) {
        console.error(error)
      }
      return nfts
    },
    [account, contractAddress],
  )

  return callback
}

export const useCasperNFTListCallback = (
  account: string | null | undefined,
  network?: Network,
): ((loadFromCache: boolean) => Promise<NFT[]>) => {
  const dispatch = useDispatch<AppDispatch>()
  const nftCollection = useSelectedNFTCollection()
  const contractHash = nftCollection?.contractHash ?? ''

  const callback = useCallback(
    async (loadFromCache: boolean): Promise<NFT[]> => {
      const nfts: NFT[] = []

      if (account && contractHash && network) {
        const properNFTContractHash = contractHash.startsWith('hash-') ? contractHash.slice(5) : contractHash
        const nftContract = new CEP78Client(properNFTContractHash, network?.rpcURL, network.key ?? 'casper-test', [])
        await nftContract.init()

        try {
          const key = `nft-${account}-${contractHash}-${network.chainId}`
          const cache = localStorage.getItem(key)
          // @ts-ignore
          const cachedNFTs = JSON.parse(cache) as NFT[]

          if (cachedNFTs && loadFromCache && !isCacheOutdated()) {
            return cachedNFTs
          } else {
            const accountHash = CLPublicKey.fromHex(account).toAccountHashStr().substring(13)
            const apiUrl = `${
              network.isMainnet ? CASPER_MAINNET_API : CASPER_TESTNET_API
            }/accounts/${accountHash}/nft-tokens`

            try {
              const response2 = await axios.get(apiUrl, {
                params: {
                  fields: 'contract_package',
                },
              })

              if (response2.status === 200) {
                const { data }: { data: AccountNFT[] } = response2.data

                data.map(async item => {
                  if (item.contract_package_hash === nftCollection?.contractPackageHash) {
                    nfts.push({
                      name: item.metadata.name,
                      tokenId: Number(item.token_id),
                      image: ipfsURLConvert(item.offchain_metadata.image ?? DefaultNFTImage),
                      attributes: item.offchain_metadata.attributes,
                      collectionName: item.metadata.name,
                      collectionSymbol: item.metadata.symbol,
                    })
                  }
                })
              }
            } catch (error) {
              console.error(error)

              // If get data from API has error
              const tokenIds = await nftContract.getOwnedTokenIds(CLPublicKey.fromHex(account))

              const collectionName = await nftContract.collectionName()
              const collectionSymbol = await nftContract.collectionSymbol()

              for (let i = 0; i < tokenIds.length; i++) {
                const tokenId = tokenIds[i]
                const tokenURI = await nftContract.getTokenMetadata(tokenId)

                try {
                  const response = await axios.get(ipfsURLConvert(JSON.parse(tokenURI)['token_uri']), {
                    timeout: 10000,
                  })

                  if (response.status == 200 && response.data) {
                    nfts.push({
                      name: response.data.name ?? collectionName,
                      tokenId: BigNumber.from(tokenId).toNumber(),
                      image: ipfsURLConvert(response.data.image ?? DefaultNFTImage),
                      attributes: response.data.attributes,
                      collectionName,
                      collectionSymbol,
                    })
                  }
                } catch (e) {
                  nfts.push({
                    name: collectionName,
                    tokenId: BigNumber.from(tokenId).toNumber(),
                    image: DefaultNFTImage,
                    attributes: [],
                    collectionName,
                    collectionSymbol,
                  })
                }
              }

              nfts.sort((a, b) => {
                return a.tokenId - b.tokenId
              })
            }

            dispatch(updateNFTList({ nftList: nfts }))
            localStorage.setItem(key, JSON.stringify(nfts))
            localStorage.setItem('dto-bridge-lastupdated', Date.now().toString())
          }
        } catch (error) {
          console.error(error)
        }
      }
      return nfts
    },
    [account, contractHash],
  )

  return callback
}

export const useCasperNFTListAPICallback = (
  account: string | null | undefined,
  contractHash?: string,
  network?: Network,
): ((loadFromCache: boolean) => Promise<NFT[]>) => {
  const dispatch = useDispatch<AppDispatch>()

  const callback = useCallback(
    async (loadFromCache: boolean): Promise<NFT[]> => {
      const nfts: NFT[] = []

      if (account && contractHash && network) {
        const properNFTContractHash = contractHash.startsWith('hash-') ? contractHash.slice(5) : contractHash
        const nftContract = new CEP78Client(properNFTContractHash, network?.rpcURL, network.key ?? 'casper-test', [])
        await nftContract.init()

        try {
          const key = `nft-${account}-${contractHash}-${network.chainId}`
          const cache = localStorage.getItem(key)
          // @ts-ignore
          const cachedNFTs = JSON.parse(cache) as NFT[]

          if (cachedNFTs && loadFromCache && !isCacheOutdated()) {
            return cachedNFTs
          } else {
            // const apiUrl = network.isMainnet
            //   ? 'https://api.cspr.live/'
            //   : 'https://event-store-api-clarity-testnet.make.services'
            // const accountHash = CLPublicKey.fromHex(account).toAccountHashStr().slice(12)
            // const response = axios.get(
            //   `${apiUrl}/accounts/${accountHash}/nft-tokens?page=1&limit=3&fields=contract_package`,
            // )

            // console.log({ response })

            // const tokenIds = readByIdHash
            //   ? await nftContract.getOwnedTokenIdsHash(CLPublicKey.fromHex(account))
            //   : await nftContract.getOwnedTokenIds(CLPublicKey.fromHex(account))
            // const collectionName = await nftContract.collectionName()
            // const collectionSymbol = await nftContract.collectionSymbol()

            // for (let i = 0; i < tokenIds.length; i++) {
            //   const tokenId = tokenIds[i]
            //   const tokenURI = await nftContract.getTokenMetadata(tokenId)

            //   try {
            //     const response = await axios.get(ipfsURLConvert(JSON.parse(tokenURI)['token_uri']), {
            //       timeout: 10000,
            //     })

            //     if (response.status == 200 && response.data) {
            //       nfts.push({
            //         name: response.data.name ?? collectionName,
            //         tokenId: BigNumber.from(tokenId).toNumber(),
            //         image: ipfsURLConvert(response.data.image ?? DefaultNFTImage),
            //         attributes: response.data.attributes,
            //         collectionName,
            //         collectionSymbol,
            //       })
            //     }
            //   } catch (error) {
            //     nfts.push({
            //       name: collectionName,
            //       tokenId: BigNumber.from(tokenId).toNumber(),
            //       image: DefaultNFTImage,
            //       attributes: [],
            //       collectionName,
            //       collectionSymbol,
            //     })
            //   }
            // }

            nfts.sort((a, b) => {
              return a.tokenId - b.tokenId
            })

            dispatch(updateNFTList({ nftList: nfts }))
            localStorage.setItem(key, JSON.stringify(nfts))
            localStorage.setItem('dto-bridge-lastupdated', Date.now().toString())
          }
        } catch (error) {
          console.error(error)
        }
      }
      return nfts
    },
    [account, contractHash],
  )

  return callback
}

export const useSupportedChainIds = (nftBridgeAddress: string, nftAddress: string): (() => Promise<number[]>) => {
  const contract = useNFTBridgeContract(nftBridgeAddress)

  const callback = useCallback(async (): Promise<number[]> => {
    if (contract && nftAddress) {
      const result = (await contract.getSupportedChainsForToken(nftAddress)) as BigNumber[]
      const chainIds = []
      for (let i = 0; i < result.length; i++) {
        chainIds.push(result[i].toNumber())
      }

      return chainIds
    }

    return []
  }, [nftBridgeAddress, nftAddress])
  return callback
}
export const useSelectedNFTs = (): NFT[] => {
  const nfts = useSelector((state: AppState) => state.nft.selectedNFTs)
  return useMemo(() => nfts, [nfts])
}

export enum ClaimState {
  LOADING,
  CLAIMABLE,
  NOT_CLAIMABLE,
}

export const useUserClaimState = (item: NFTTransaction, account?: string | null): [ClaimState, number] => {
  const currentNetwork = useCurrentNetwork()
  const nftBridgeAddress = useNFTBridgeAddress()
  const [claimState, setClaimState] = useState(ClaimState.LOADING)
  const [itemsCount, setItemsCount] = useState(0)

  const isCasper = currentNetwork?.key?.toLowerCase().includes('casper')
  const originIsCasper = item.originNetwork?.key?.toLowerCase().includes('casper')

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        // if transfer to evm
        if (!item.toNetwork?.notEVM) {
          setClaimState(ClaimState.CLAIMABLE)
          return
        }

        let tokenAddress = ''

        if (currentNetwork) {
          if (item.contractHash) {
            tokenAddress = item.contractHash
          } else if (item.contractPackageHash) {
            tokenAddress = await getContractVersionOnCasper(item.contractPackageHash, currentNetwork)
          }
        }

        const nameKey = originIsCasper ? 'user_unlock_id_list' : 'user_mint_id_list'
        const contractAddress = originIsCasper ? nftBridgeAddress : tokenAddress

        if (account && currentNetwork && isCasper) {
          const stateRootHash = await getStateRootHash(currentNetwork)
          const contractData = await CasperUtils.getContractData(
            currentNetwork.rpcURL,
            stateRootHash,
            contractAddress,
            [],
          )
          const namedKeys = contractData.Contract?.namedKeys
          let uRef = ''

          if (namedKeys && namedKeys?.length) {
            for (let i = 0; i < namedKeys.length; i++) {
              const _nameKey = namedKeys[i]
              if (_nameKey.name === nameKey) {
                uRef = _nameKey.key
              }
            }
          }

          const accountHash = CLPublicKey.fromHex(account).toAccountHashStr().substring(13)
          const response = await axios.post(currentNetwork.rpcURL, {
            jsonrpc: '2.0',
            method: 'state_get_dictionary_item',
            params: {
              state_root_hash: stateRootHash,
              dictionary_identifier: {
                URef: {
                  seed_uref: uRef,
                  dictionary_item_key: accountHash,
                },
              },
            },
            id: 0,
          })

          if (response.data.result && response.data.result.stored_value.CLValue.bytes) {
            setClaimState(ClaimState.CLAIMABLE)
            const count = response.data.result.stored_value.CLValue.bytes.substring(0, 2)
            setItemsCount(parseInt(count))
          } else {
            setClaimState(ClaimState.NOT_CLAIMABLE)
          }
        } else {
          setClaimState(ClaimState.NOT_CLAIMABLE)
        }
      } catch (error) {
        console.error(error)

        if (isCasper) {
          setClaimState(ClaimState.NOT_CLAIMABLE)
        }
      }
    })()
  }, [account])
  return [claimState, itemsCount]
}
