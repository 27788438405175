import { useContext } from 'react'
import { EuiButton, EuiButtonEmpty, EuiFieldText } from '@elastic/eui'
import styled from 'styled-components/macro'
import { useActiveWeb3React, useNetworkInfo, useTokenBalanceCallback } from 'hooks'
import BridgeAppContext from 'context/BridgeAppContext'
import { formatNumber } from 'utils'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { ethers } from 'ethers'
import { useSelectedToken } from 'state/token/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { updateTokenAmount } from 'state/token/actions'
import { Dots } from 'theme/components'

const AmountInputWrapper = styled.div`
  width: 100%;
`
const StyledLabel = styled.div`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;

  span {
    font-size: 12px;
    font-weigth: 600;
    line-height: 1.5;
  }
`
const Input = styled(EuiFieldText)`
  padding: 1.25rem 1rem;
`
const Button = styled(EuiButton)`
  margin-right: 1rem;
  margin-top: 0.75rem;
  height: 2.5rem !important;
  padding: 0.5rem 1rem;
  min-width: 0;
  border-radius: 0.5rem !important;

  .euiButtonContent {
    height: auto;
  }
`
const BalanceText = styled.p`
  font-size: 0.75rem;
  text-transform: none;
  color: #777e90;
  margin-bottom: 0;
`

function AmountInput(): JSX.Element {
  const { ledgerAddress } = useContext(BridgeAppContext)
  const { library: web3Library } = useActiveWeb3React()

  const account = useAccount()
  const chainId = useChainId()

  const tokenAmount = useSelector((state: AppState) => state.token.tokenAmount)
  const dispatch = useDispatch<AppDispatch>()

  const networkInfo = useNetworkInfo(chainId)
  const selectedToken = useSelectedToken()
  const library = ledgerAddress !== '' ? new ethers.providers.JsonRpcProvider(networkInfo?.rpcURL ?? '') : web3Library

  const [tokenBalance, isLoadingBalance] = useTokenBalanceCallback(
    selectedToken ? selectedToken.address : undefined,
    selectedToken ? selectedToken.decimals : undefined,
    account,
    library,
    networkInfo,
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    const _value = e.target.value
    if (!_value) {
      dispatch(updateTokenAmount({ amount: '' }))
      return
    }
    dispatch(updateTokenAmount({ amount: _value }))
  }

  const onMax = () => {
    dispatch(updateTokenAmount({ amount: tokenBalance.toString() }))
  }

  return (
    <AmountInputWrapper>
      <StyledLabel>
        <span style={{ color: '#727eaa' }}>Amount</span>
        {selectedToken && account && (
          <BalanceText>
            {isLoadingBalance ? (
              <>
                <Dots>Balance: </Dots> {selectedToken.symbol}
              </>
            ) : (
              <span style={{ color: '#727eaa' }}>
                Balance: {tokenBalance ? formatNumber(tokenBalance.toFixed(4)) : '0'} {selectedToken.symbol}
              </span>
            )}
          </BalanceText>
        )}
      </StyledLabel>
      <Input
        fullWidth
        min={0}
        max={tokenBalance}
        step={0.1}
        value={tokenAmount ?? ''}
        onChange={onChange}
        style={{ color: '#000' }}
        append={
          <EuiButtonEmpty disabled={isLoadingBalance} onClick={onMax}>
            Max
          </EuiButtonEmpty>
        }
      />
    </AmountInputWrapper>
  )
}

export default AmountInput
