/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ethers, ContractInterface, Contract } from 'ethers'
import { getAddress } from 'ethers/lib/utils'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import axios from 'axios'
import Token from 'type/Token'
import Network from 'type/Network'
import {
  BRIDGE_ADDRESSES_URL,
  CASPER_MAINNET_API,
  CASPER_TESTNET_API,
  NATIVE_TOKEN_ADDERSS,
  SUPPORTED_NETWORKS,
  ConnectorNames,
} from '../constants'
import { CasperClient, DeployUtil, CLPublicKey, CasperServiceByJsonRPC } from 'casper-js-sdk'
import { SafeEventEmitterProvider } from 'casper-js-sdk/dist/services/ProviderTransport'
import { utils as CasperUtils } from 'casper-js-client-helper'
import ContractPackage from 'type/ContracPackage'
import _, { memoize } from 'lodash'
import TokenMap from 'type/TokenMap'
import TokenLogo from 'type/TokenLogo'

export const isDev = Boolean(process.env.REACT_APP_IS_TESTNET == 'true')

export const isCacheOutdated = (time?: number): boolean => {
  const key = 'dto-bridge-lastupdated'
  const lastUpdated = localStorage.getItem(key)
  if (lastUpdated !== undefined) {
    const now = Date.now()
    const diff = now - Number(lastUpdated)
    const deadline = time ?? 15 * 60 * 1000

    if (diff > deadline) {
      localStorage.setItem(key, now.toString())
      return true
    }

    return false
  }

  return true
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

export const getContract = (
  address: string,
  abi: ContractInterface,
  library: Web3Provider,
  account?: string,
): Contract | null => {
  try {
    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    if (address == NATIVE_TOKEN_ADDERSS) return null
    // @ts-ignore
    return new ethers.Contract(address, abi, getProviderOrSigner(library, account) as any)
  } catch (error) {
    console.error('Failed to get contract', error)
    return null
  }
}

export const getStateRootHash = async (network: Network) => {
  const apiURL = network.isMainnet ? CASPER_MAINNET_API : CASPER_TESTNET_API
  try {
    const response = await axios.get(`${apiURL}/rpc/info_get_status`)
    if (response.status === 200) {
      return response.data.result.last_added_block_info.state_root_hash
    }

    return ''
  } catch (error) {
    return await CasperUtils.getStateRootHash(network.rpcURL)
  }
}

export const getContractVersionOnCasper = async (contractPackageHash: string, network: Network): Promise<string> => {
  let tokenAddress = ''
  try {
    const apiURL = network.isMainnet ? CASPER_MAINNET_API : CASPER_TESTNET_API
    const stateRootHash = await getStateRootHash(network)
    let _response

    try {
      _response = await axios.get(`${apiURL}/rpc/state_get_item`, {
        params: {
          state_root_hash: stateRootHash,
          key: `hash-${contractPackageHash}`,
          path: [],
        },
      })
    } catch (error) {
      _response = await axios.post(network.rpcURL, {
        jsonrpc: '2.0',
        method: 'state_get_item',
        params: {
          state_root_hash: stateRootHash,
          key: `hash-${contractPackageHash}`,
          path: [],
        },
        id: 0,
      })
    }

    if (
      _response.status === 200 &&
      _response.data.result &&
      _response.data.result.stored_value.ContractPackage.versions
    ) {
      const contractPackage = _response.data.result.stored_value.ContractPackage as ContractPackage
      const { versions, disabled_versions } = contractPackage
      const versionNumbers: number[] = []
      const disabledVersionNumbers = []

      for (let i = 0; i < disabled_versions.length; i++) {
        disabledVersionNumbers.push(disabled_versions[i].contract_version)
      }

      for (let i = 0; i < versions.length; i++) {
        if (!disabledVersionNumbers.includes(versions[i].contract_version)) {
          versionNumbers.push(versions[i].contract_version)
        }
      }

      const version = versions.find((v: any) => v.contract_version === _.max(versionNumbers))

      if (version) {
        tokenAddress = version.contract_hash
        if (tokenAddress.includes('contract-')) {
          tokenAddress = tokenAddress.substring(9)
        }
      }
    }
  } catch (error) {
    console.error(`error while read tokenPackageHash: ${error}`)
  }
  return tokenAddress
}

const mapToken = (token: Token): Token => ({
  name: token.name,
  address: token.address,
  symbol: token.symbol,
  decimals: Number(token.decimals),
  logoURI: token.logoURI,
})

export const getTokenLogos = async (): Promise<TokenLogo[]> => {
  try {
    const data = localStorage.getItem('token_logos')
    const outdated = isCacheOutdated()
    if (data && !outdated) {
      return JSON.parse(data) as TokenLogo[]
    }

    const response = await axios.get(`${BRIDGE_ADDRESSES_URL}/logos.json`)
    if (response.status === 200) {
      localStorage.setItem('token_logos', JSON.stringify(response.data))
      localStorage.setItem('dto-bridge-lastupdated', Date.now().toString())
      return response.data as TokenLogo[]
    }
  } catch (error) {
    console.error(error)
  }
  return []
}

export const getTokensFromConfig = async (
  account: string | null | undefined,
  chainId: number | undefined,
  readTokenMap?: boolean,
): Promise<Token[]> => {
  const tokens: Token[] = []

  try {
    if (!chainId) {
      return tokens
    }

    // Get token from local storage first
    if (account) {
      const data = localStorage.getItem(`tokens_${account}_${chainId}`)
      if (data) {
        const customTokens = JSON.parse(data) as Token[]
        tokens.push(...customTokens.map(mapToken))
      }
    }

    const network = SUPPORTED_NETWORKS.find(n => n.chainId === chainId) as Network
    const isCasper = network && network.key?.includes('casper')
    const response = await axios.get(`${BRIDGE_ADDRESSES_URL}/tokens/${chainId}.json`)

    if (response.status === 200) {
      const tokenList: Token[] = response.data
      const existingAddresses = new Set<string>(tokens.map(t => t.address))
      const newTokens: Token[] = []

      for await (const token of tokenList) {
        let tokenAddress = ''

        if (network.notEVM && network.name.toLowerCase().includes('casper')) {
          if (token.contractHash) {
            tokenAddress = token.contractHash
          } else if (token.contractPackageHash) {
            tokenAddress = await getContractVersionOnCasper(token.contractPackageHash, network)
          }
        } else {
          tokenAddress = token.address
        }

        newTokens.push({
          name: token.name,
          address: tokenAddress,
          originContractAddress: token.originContractAddress || '',
          originChainId: token.originChainId || chainId,
          originSymbol: token.originSymbol || token.symbol,
          contractHash: token.contractHash || '',
          contractPackageHash: token.contractPackageHash || '',
          symbol: token.symbol,
          decimals: Number(token.decimals),
          logoURI: token.logoURI,
          minBridge: token.minBridge || '0',
          supportedChainIds: token.supportedChainIds || [],
        })

        existingAddresses.add(tokenAddress.toLowerCase())
      }

      if (readTokenMap) {
        const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/tokenMap`)

        if (response2.status === 200) {
          const { tokenMap }: { tokenMap: TokenMap[] } = response2.data
          const tokenLogos = await getTokenLogos()

          await Promise.all(
            Array.from(tokenMap).map(async item => {
              const originNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === item.networkId) as Network
              const originIsCasper = originNetwork && originNetwork.key?.includes('casper')

              if (item.mapInfo && item.decimals > 0) {
                await Promise.all(
                  Object.values(item.mapInfo).map(async info => {
                    const _network = SUPPORTED_NETWORKS.find(n => n.chainId === info.networkId) as Network
                    let { address } = info

                    // if current network is Casper
                    if (isCasper) {
                      // we have contract package hash if the origin network is Casper
                      if (originIsCasper) {
                        try {
                          const contractHash = await getContractVersionOnCasper(item.address, _network)
                          address = contractHash
                        } catch (error) {
                          // skip
                        }
                      }
                    }

                    if (info.networkId === chainId && !existingAddresses.has(address.toLowerCase())) {
                      const logo = tokenLogos.find(
                        _logo =>
                          _logo.address.toLowerCase() === item.address.toLowerCase() &&
                          _logo.chainId === item.networkId,
                      )

                      newTokens.push({
                        name: info.name,
                        address,
                        originContractAddress: item.address || '',
                        originChainId: item.networkId || chainId,
                        originSymbol: item.name || item.symbol,
                        contractHash: address,
                        contractPackageHash: originIsCasper ? item.address : '',
                        symbol: item.symbol,
                        decimals: Number(item.decimals),
                        logoURI: logo?.logoURI || '',
                        minBridge: '0',
                        supportedChainIds: [],
                      })

                      existingAddresses.add(address.toLowerCase())
                    }
                  }),
                )
              }
            }),
          )
        }
      }
      tokens.push(...newTokens)
    }
  } catch (error) {
    console.error(error)
  }
  return tokens
}

const memoizedGetTokensFromConfig = memoize(getTokensFromConfig)

export const getTokensFromConfigMemoized = async (
  account: string | null | undefined,
  chainId: number | undefined,
  readTokenMap?: boolean,
): Promise<Token[]> => {
  return memoizedGetTokensFromConfig(account, chainId, readTokenMap)
}

export const getPayTokensFromConfig = async (
  account: string | null | undefined,
  chainId: number | undefined,
): Promise<Token[]> => {
  const tokens: Token[] = []

  try {
    if (!chainId) {
      return tokens
    }

    const network = SUPPORTED_NETWORKS.find(n => n.chainId === chainId) as Network
    const response = await axios.get(
      'https://raw.githubusercontent.com/quyen123z/list-token-pay/main/tokens.json?token=GHSAT0AAAAAACTLN2C47AO4R7JKFWQYJZ3SZUIETIQ',
    )
    let tokenList: Token[] = []

    if (response.status === 200) {
      tokenList = response.data[network?.nativeCurrency.symbol]
      const newTokens: Token[] = []

      for await (const token of tokenList) {
        let tokenAddress = ''

        if (network.notEVM && network.name.toLowerCase().includes('casper')) {
          if (token.contractHash) {
            tokenAddress = token.contractHash
          } else if (token.contractPackageHash) {
            tokenAddress = await getContractVersionOnCasper(token.contractPackageHash, network)
          }
        } else {
          tokenAddress = token.address
        }

        newTokens.push({
          name: token.name,
          address: tokenAddress,
          originContractAddress: token.originContractAddress || '',
          originChainId: token.originChainId || chainId,
          originSymbol: token.originSymbol || token.symbol,
          contractHash: token.contractHash || '',
          contractPackageHash: token.contractPackageHash || '',
          symbol: token.symbol,
          decimals: Number(token.decimals),
          logoURI: token.logoURI,
          minBridge: token.minBridge || '0',
          supportedChainIds: token.supportedChainIds || [],
        })
      }

      //console.log('new token', newTokens)

      tokens.push(...newTokens)
    }
  } catch (error) {
    console.error(error)
  }
  return tokens
}

export const getAllTokensFromConfig = async (): Promise<any> => {
  const allTokens: any = {}
  //const listChainId = [1, 56, 43114, 88, 1284, 66, 131614895977472]
  const listChainId = [
    { symbol: 'eth', chainId: 1 },
    { symbol: 'bnb', chainId: 56 },
    { symbol: 'avax', chainId: 43114 },
    { symbol: 'tomo', chainId: 88 },
    { symbol: 'glmr', chainId: 1284 },
    { symbol: 'okt', chainId: 66 },
    { symbol: 'cspr', chainId: 131614895977472 },
  ]

  try {
    // if (!chainId) {
    //   return tokens
    // }

    // const network = SUPPORTED_NETWORKS.find(n => n.chainId === chainId) as Network
    // const isCasper = network && network.key?.includes('casper')
    //const response = await axios.get('https://raw.githubusercontent.com/quyen123z/list-token-pay/main/tokens.json')
    for (let i = 0; i < listChainId.length; i++) {
      const respondToken = await axios.get(`${BRIDGE_ADDRESSES_URL}/tokens/${listChainId[i].chainId}.json`)
      if (respondToken && respondToken.data) {
        const propName = listChainId[i].symbol
        allTokens[propName] = respondToken.data
      }
    }

    //console.log('after fetch: ', allTokens)
  } catch (error) {
    console.error(error)
  }
  return allTokens
}

export const formatNumber = (number: number | string | undefined): string => {
  if (!number) {
    return '0'
  }

  const seps = number.toString().split('.')
  seps[0] = seps[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return Number(number) % 1 === 0 ? seps[0] : seps.join('.')
}

interface WindowChain {
  ethereum?: {
    isMetaMask?: true
    request?: (...args: any[]) => any
  }
}

export const setupNetwork = async (network: Network): Promise<boolean> => {
  const provider = (window as WindowChain).ethereum
  const defaultMetaMaskChainId = [1, 5]
  const method = defaultMetaMaskChainId.includes(network.chainId)
    ? 'wallet_switchEthereumChain'
    : 'wallet_addEthereumChain'
  const params = defaultMetaMaskChainId.includes(network.chainId)
    ? [{ chainId: `0x${network.chainId.toString(16)}` }]
    : [
        {
          chainId: `0x${network.chainId.toString(16)}`,
          chainName: network.name,
          nativeCurrency: {
            name: network.nativeCurrency.name,
            symbol: network.nativeCurrency.symbol,
            decimals: network.nativeCurrency.decimals,
          },
          rpcUrls: [network.rpcURL],
          blockExplorerUrls: [network.explorer],
        },
      ]

  if (provider) {
    try {
      // @ts-ignore
      await provider.request({
        method,
        params,
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't setup network on MetaMask because window.ethereum is undefined")
    return false
  }
}

export const ipfsURLConvert = (url: string) => url.replace('ipfs://', 'https://ipfs.io/ipfs/')

export const getExplorerLink = (
  network: Network | undefined,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block' | 'contract' | 'contract-package' | '',
): string => {
  const prefix = `${network?.explorer}`

  if (network == undefined) {
    return ''
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/${network?.txUrl}/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'contract': {
      return `${prefix}/contract/${data}`
    }
    case 'contract-package': {
      return `${prefix}/contract-package/${data}`
    }
    case 'address':
    default: {
      if (network.key?.includes('casper')) {
        return `${prefix}/account/${data}`
      }
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  return `${address.substring(0, chars + 2)}...${address.substring(address.length - chars)}`
}

export const genRanHex = (size = 64) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getDeployFunction = async (
  account: string,
  casperClient: CasperClient,
  connectorId: string,
  deploy: any,
  provider: any,
  json: any,
  connector: any,
): Promise<any> => {
  let signature: any = undefined
  let deployObject: any = undefined
  let deployFn: any = undefined

  if (connectorId === ConnectorNames.CasperSigner || connectorId === ConnectorNames.CasperDash) {
    signature = await provider.sign(json, account, account)
    const _deploy = DeployUtil.deployFromJson(signature)
    deployObject = _deploy.val
    if (deployObject instanceof DeployUtil.Deploy) {
      deployFn = casperClient.putDeploy(deployObject)
    }
  } else if (connectorId === ConnectorNames.CasperWallet) {
    signature = await provider.sign(JSON.stringify(json), account)
    deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
    deployFn = casperClient.putDeploy(deployObject)
  } else {
    if (connector) {
      // @ts-ignore
      const { torus } = connector
      const casperService = new CasperServiceByJsonRPC(torus?.provider as SafeEventEmitterProvider)
      deployFn = casperService.deploy(deploy)
    }
  }

  return deployFn
}

export const getBridgeAddress = async (chainId: number | undefined): Promise<[string, string]> => {
  try {
    const response = await axios.get(`${BRIDGE_ADDRESSES_URL}/bridge-contracts.json`)
    if (response.status === 200 && response.data && chainId) {
      const { bridge, nftBridge } = response.data
      return [bridge[chainId], nftBridge[chainId]]
    }
    return ['', '']
  } catch (error) {
    const network = SUPPORTED_NETWORKS.find(n => n.chainId === chainId) as Network
    return network ? [network.bridge, network.nftBridge] : ['', '']
  }
}
