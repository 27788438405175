export enum NFTType {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

type NFTCollection = {
  name: string
  address: string
  symbol: string
  logoURI: string
  originName?: string
  contractPackageHash?: string
  originContractAddress?: string
  contractHash?: string
  originSymbol?: string
  originChainId?: number
  type: NFTType
  supportedChainIds: number[]
}

export default NFTCollection
