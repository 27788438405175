/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { formatNumber, getContractVersionOnCasper, getExplorerLink, getTokenLogos, ipfsURLConvert } from 'utils'
import axios from 'axios'
import networksMainnet from 'config/networks.mainnet.json'
import networksTestnet from 'config/networks.testnet.json'
import Transaction from 'type/Transaction'
import Token from 'type/Token'
import Network from 'type/Network'
import { BRIDGE_ADDRESSES_URL, NATIVE_TOKEN_ADDERSS, SUPPORTED_NETWORKS } from '../constants'
import NFTTransaction from 'type/NFTTransaction'
import NFTCollection, { NFTType } from 'type/NFTCollection'
import DefaultNFTImage from 'assets/images/nft.png'
import { BigNumber } from 'ethers'
import NFT from 'type/NFT'
import { formatUnits } from 'ethers/lib/utils'
import TokenMap from 'type/TokenMap'

export const parseResponseToTransactions = async (
  response: any,
  chainId: number | undefined,
): Promise<Transaction[]> => {
  const transactions = [] as Transaction[]

  if (!response || !chainId) {
    return []
  }

  const currentNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === chainId) as Network
  const isCasper = currentNetwork && currentNetwork.key?.includes('casper')
  let tokenList: Token[] = []

  try {
    const tokenResponse = await axios.get(`${BRIDGE_ADDRESSES_URL}/tokens/${chainId}.json`)
    if (tokenResponse.status === 200) {
      tokenList = tokenResponse.data
    }
  } catch (error) {
    console.warn(`Could not find config file for chain id: ${chainId}`)
  }
  const existingAddresses = new Set<string>(tokenList.map(n => n.address))

  // Read token map
  const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/tokenMap`)
  if (response2.status === 200) {
    const { tokenMap }: { tokenMap: TokenMap[] } = response2.data
    const tokenLogos = await getTokenLogos()

    await Promise.all(
      Array.from(tokenMap).map(async item => {
        const originNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === item.networkId) as Network
        const originIsCasper = originNetwork && originNetwork.key?.includes('casper')

        if (item.mapInfo && item.decimals > 0) {
          await Promise.all(
            Object.values(item.mapInfo).map(async info => {
              const _network = SUPPORTED_NETWORKS.find(n => n.chainId === info.networkId) as Network
              let { address } = info

              // if current network is Casper
              if (isCasper) {
                // we have contract package hash if the origin network is Casper
                if (originIsCasper) {
                  try {
                    const contractHash = await getContractVersionOnCasper(item.address, _network)
                    address = contractHash
                  } catch (error) {
                    // skip
                  }
                }
              }

              if (info.networkId === chainId && !existingAddresses.has(address.toLowerCase())) {
                const logo = tokenLogos.find(
                  _logo =>
                    _logo.address.toLowerCase() === item.address.toLowerCase() && _logo.chainId === item.networkId,
                )

                tokenList.push({
                  name: info.name,
                  address,
                  originContractAddress: item.address || '',
                  originChainId: item.networkId || chainId,
                  originSymbol: item.name || item.symbol,
                  contractHash: address,
                  contractPackageHash: originIsCasper ? item.address : '',
                  symbol: item.symbol,
                  decimals: Number(item.decimals),
                  logoURI: logo?.logoURI || '',
                  minBridge: '0',
                  supportedChainIds: [],
                })

                existingAddresses.add(address.toLowerCase())
              }
            }),
          )
        }
      }),
    )
  }

  if (response.transactions && response.total) {
    response.transactions.forEach((t: Transaction) => {
      try {
        const fromNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === t.fromChainId) as Network
        const toNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === t.toChainId) as Network
        const originNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === t.originChainId) as Network
        const amountFormated = `${formatNumber(Number(formatUnits(t.amount, t.originDecimals)))} ${
          t.originToken == NATIVE_TOKEN_ADDERSS ? originNetwork.nativeCurrency.symbol : t.originSymbol
        }`

        let _requestHash = t.requestHash

        if (fromNetwork && fromNetwork.notEVM && _requestHash && _requestHash.substring(0, 2) === '0x') {
          _requestHash = _requestHash.substring(2, _requestHash.length)
        }
        const requestEllipsis = `${_requestHash.substring(0, 6)}...${_requestHash.substring(_requestHash.length - 4)}`
        const requestHashUrl = fromNetwork ? `${fromNetwork.explorer}/${fromNetwork.txUrl}/${_requestHash}` : ''

        let _claimHash = t.claimHash

        if (toNetwork && toNetwork.notEVM && _claimHash && _claimHash.substring(0, 2) === '0x') {
          _claimHash = _claimHash.substring(2, _claimHash.length)
        }
        const claimEllipsis = _claimHash
          ? `${_claimHash.substring(0, 6)}...${_claimHash.substring(_claimHash.length - 4)}`
          : ''
        const claimHashUrl = toNetwork ? `${toNetwork.explorer}/${toNetwork.txUrl}/${_claimHash}` : ''

        let _account = t.account
        if (toNetwork && toNetwork.notEVM) {
          _account = _account.substring(13, 77)
        }
        const _accountUrl = getExplorerLink(toNetwork, _account, 'address')

        let contractHash: string | undefined = undefined
        let contractPackageHash: string | undefined = undefined
        let tokenAddress: string | undefined = undefined

        // If token was deployed on Casper
        if (originNetwork.key?.includes('casper')) {
          const token = tokenList.find(
            _token =>
              _token.contractPackageHash?.toLowerCase() === t.originToken.toLowerCase() &&
              _token.originChainId === t.originChainId,
          )
          contractPackageHash = token?.contractPackageHash
          tokenAddress = token?.address
        } else {
          // Find contract hash on Casper
          let tokenOnCasper = tokenList.find(
            _token =>
              _token.originContractAddress?.toLowerCase() === t.originToken.toLowerCase() &&
              _token?.originChainId === t.originChainId,
          )
          contractHash = tokenOnCasper?.contractHash
          contractPackageHash = tokenOnCasper?.contractPackageHash

          // From EVM to Casper
          if (!currentNetwork.notEVM && toNetwork.notEVM) {
            tokenOnCasper = tokenList.find(_token => _token.address.toLowerCase() === t.originToken.toLowerCase())
            contractHash = tokenOnCasper?.contractHash
          }
        }

        transactions.push({
          ...t,
          tokenSymbol: t.originToken == NATIVE_TOKEN_ADDERSS ? originNetwork.nativeCurrency.symbol : t.originSymbol,
          fromNetwork,
          toNetwork,
          originNetwork,
          amountFormated,
          account: _account,
          accountUrl: _accountUrl,
          requestHashLink: {
            networkName: fromNetwork.name,
            explorerLogo: fromNetwork ? fromNetwork.logoURI : '',
            requestHash: requestEllipsis,
            requestHashUrl,
          },
          claimHashLink: {
            networkName: toNetwork.name,
            explorerLogo: toNetwork ? toNetwork.logoURI : '',
            claimHash: claimEllipsis,
            claimHashUrl,
          },
          contractHash,
          contractPackageHash,
          tokenAddress,
          claimed: typeof t.claimed == 'undefined' ? false : t.claimed,
        })
      } catch (error) {}
    })

    // localStorage.setItem(`transactions_${account}_${chainId}`, JSON.stringify(transactions))
  }
  return transactions
}

export const parseResponseToNFTTransactions = async (
  response: any,
  chainId: number | undefined,
): Promise<NFTTransaction[]> => {
  const transactions = [] as NFTTransaction[]

  if (!response || !chainId) {
    return []
  }

  const currentNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === chainId) as Network
  let nftCollectionList: NFTCollection[] = []

  try {
    const tokenResponse = await axios.get(`${BRIDGE_ADDRESSES_URL}/nfts/${currentNetwork.chainId}.json`)

    if (tokenResponse.status === 200) {
      nftCollectionList = tokenResponse.data
    }
  } catch (error) {
    console.warn(`Could not find config file for chain id: ${chainId}`)
  }
  const existingAddresses = new Set<string>(nftCollectionList.map(n => n.address))

  // Read token map
  const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/tokenMap`)
  if (response2.status === 200) {
    const { tokenMap }: { tokenMap: TokenMap[] } = response2.data
    const tokenLogos = await getTokenLogos()

    tokenMap.forEach(item => {
      if (item.mapInfo && item.decimals === 0) {
        Object.values(item.mapInfo).forEach(async info => {
          const _network = SUPPORTED_NETWORKS.find(n => n.chainId === info.networkId) as Network
          const isCasper = _network && _network.key?.includes('casper')

          // we have contract package hash on Casper
          if (isCasper) {
            try {
              const contractHash = await getContractVersionOnCasper(info.address, _network)
              info.address = contractHash
            } catch (error) {
              // skip
            }
          }

          if (!existingAddresses.has(info.address.toLowerCase())) {
            const logo = tokenLogos.find(
              _logo => _logo.address.toLowerCase() === item.address.toLowerCase() && _logo.chainId === item.networkId,
            )

            nftCollectionList.push({
              name: info.name,
              address: info.address,
              symbol: item.symbol,
              logoURI: logo?.logoURI || '',
              originChainId: item.networkId,
              originSymbol: item.symbol,
              originContractAddress: item.address,
              contractHash: isCasper ? info.address : '',
              contractPackageHash: item.address,
              type: NFTType.ERC721,
              supportedChainIds: [],
            })

            existingAddresses.add(info.address.toLowerCase())
          }
        })
      }
    })
  }

  if (response.transactions) {
    for (let i = 0; i < response.transactions.length; i++) {
      try {
        const t = response.transactions[i]
        const fromNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === t.fromChainId) as Network
        const toNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === t.toChainId) as Network
        const originNetwork = SUPPORTED_NETWORKS.find(n => n.chainId === t.originChainId) as Network

        let _requestHash = t.requestHash

        if (fromNetwork && fromNetwork.notEVM && _requestHash && _requestHash.substring(0, 2) === '0x') {
          _requestHash = _requestHash.substring(2, _requestHash.length)
        }
        const requestEllipsis = `${_requestHash.substring(0, 6)}...${_requestHash.substring(_requestHash.length - 4)}`
        const requestHashUrl = fromNetwork ? `${fromNetwork.explorer}/${fromNetwork.txUrl}/${_requestHash}` : ''

        let _claimHash = t.claimHash

        if (toNetwork && toNetwork.notEVM && _claimHash && _claimHash.substring(0, 2) === '0x') {
          _claimHash = _claimHash.substring(2, _claimHash.length)
        }
        const claimEllipsis = _claimHash
          ? `${_claimHash.substring(0, 6)}...${_claimHash.substring(_claimHash.length - 4)}`
          : ''
        const claimHashUrl = toNetwork ? `${toNetwork.explorer}/${toNetwork.txUrl}/${_claimHash}` : ''

        let _account = t.account
        if (toNetwork && toNetwork.key?.includes('casper')) {
          _account = _account.substring(13)
        }
        const _accountUrl = getExplorerLink(toNetwork, _account, 'address')

        // Get nft image
        const nftData: NFT[] = []
        if (t.tokenMetadatas.length > 0) {
          for (let j = 0; j < t.tokenMetadatas.length; j++) {
            let tokenURI = ''
            try {
              const json = JSON.parse(t.tokenMetadatas[j])
              tokenURI = json['token_uri']
            } catch (error) {
              tokenURI = t.tokenMetadatas[j]
            }

            try {
              if (tokenURI.includes('ipfs')) {
                const tokenData = await axios.get(ipfsURLConvert(tokenURI))

                if (tokenData.status == 200 && tokenData.data) {
                  nftData.push({
                    name: tokenData.data.name,
                    tokenId: BigNumber.from(t.tokenIds[j] ?? 0).toNumber(),
                    image: ipfsURLConvert(tokenData.data.image ?? DefaultNFTImage),
                    attributes: tokenData.data.attributes,
                    collectionName: t.originName,
                    collectionSymbol: t.originSymbol,
                  })
                }
              } else {
                nftData.push({
                  name: t.originName,
                  tokenId: BigNumber.from(t.tokenIds[j] ?? 0).toNumber(),
                  image: DefaultNFTImage,
                  attributes: [],
                  collectionName: t.originName,
                  collectionSymbol: t.originSymbol,
                })
              }
            } catch (error) {
              nftData.push({
                name: t.originName,
                tokenId: BigNumber.from(t.tokenIds[j] ?? 0).toNumber(),
                image: DefaultNFTImage,
                attributes: [],
                collectionName: t.originName,
                collectionSymbol: t.originSymbol,
              })
            }
          }
        }

        let contractHash: string | undefined = undefined
        let contractPackageHash: string | undefined = undefined
        let tokenAddress: string | undefined = undefined

        // If token was deployed on Casper
        if (originNetwork.key?.includes('casper')) {
          const collection = nftCollectionList.find(
            c =>
              c.contractPackageHash?.toLowerCase() === t.originToken.toLowerCase() &&
              c.originChainId === t.originChainId,
          )
          contractPackageHash = collection?.contractPackageHash
          tokenAddress = collection?.address
        } else {
          // Find contract hash on Casper
          let tokenOnCasper = nftCollectionList.find(
            _token =>
              _token.originContractAddress?.toLowerCase() === t.originToken.toLowerCase() &&
              _token?.originChainId === t.originChainId,
          )
          contractHash = tokenOnCasper?.contractHash
          contractPackageHash = tokenOnCasper?.contractPackageHash

          // From EVM to Casper
          if (!currentNetwork.notEVM && toNetwork.notEVM) {
            tokenOnCasper = nftCollectionList.find(
              _token => _token.address.toLowerCase() === t.originToken.toLowerCase(),
            )
            contractHash = tokenOnCasper?.contractHash
          }
        }

        transactions.push({
          ...t,
          fromNetwork,
          toNetwork,
          originNetwork,
          account: _account,
          accountUrl: _accountUrl,
          requestHashLink: {
            networkName: fromNetwork.name,
            explorerLogo: fromNetwork ? fromNetwork.logoURI : '',
            requestHash: requestEllipsis,
            requestHashUrl,
          },
          claimHashLink: {
            networkName: toNetwork.name,
            explorerLogo: toNetwork ? toNetwork.logoURI : '',
            claimHash: claimEllipsis,
            claimHashUrl,
          },
          nftData,
          tokenAddress,
          contractHash,
          contractPackageHash,
          claimed: typeof t.claimed == 'undefined' ? false : t.claimed,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return transactions
}

export const parseResponseToTransactionsAllChain = async (response: any, isMainnet?: boolean) => {
  const _networks = (isMainnet ? networksMainnet : networksTestnet) as Network[]
  let transactions: Transaction[] = []

  for (let i = 0; i < _networks.length; i++) {
    const network = _networks[i]
    const _txns = await parseResponseToTransactions(response, network.chainId)
    const set = new Set(transactions.map(t => t._id))
    transactions = [...transactions, ..._txns.filter(t => !set.has(t._id))] // Array.from(new Set([...transactions, ..._txns]))

    // Sort by date
    transactions = transactions.sort((a, b) => a.requestTime - b.requestTime)
  }

  return transactions
}
