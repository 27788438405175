import { EuiBasicTableColumn, EuiButtonIcon, EuiHealth, EuiInMemoryTable, Pagination, Search } from '@elastic/eui'
import { useState } from 'react'
import NFTTransaction from 'type/NFTTransaction'
import { NFTTableWrap } from './Styled'
import RequestTime from './components/RequestTime'
import RequestLink from './components/RequestLink'
import ClaimLink from './components/ClaimLink'
import ClaimNFTButtonEVM from './components/ClaimNFTButton/ClaimNFTButtonEVM'
import NFT from 'type/NFT'
import NFTData from './components/NFTData'
import NFTTxnDetails from './components/NFTTxnDetails'
// import { parseResponseToNFTTransactions } from 'utils/transactions'
import { useCurrentNetwork } from 'hooks'
import { useAccount } from 'state/wallet/hooks'
import ClaimNFTButtonCasper from './components/ClaimNFTButton/ClaimNFTButtonCasper'
import ClaimNFTForButtonEVM from './components/ClaimNFTButton/ClaimNFTForButtonEVM'
import ClaimNFTForButtonCasper from './components/ClaimNFTButton/ClaimNFTForButtonCasper'
import { CLPublicKey } from 'casper-js-sdk'

interface NFTTransactionTableProps {
  search: Search
  getAllTxns: boolean
  isLoading: boolean
  transactions: NFTTransaction[]
  error: any
}

function NFTTransactionTable(props: NFTTransactionTableProps): JSX.Element {
  const { search, getAllTxns, isLoading, transactions, error } = props

  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const isCasper = currentNetwork?.name.toLowerCase().includes('casper')

  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<any>({})

  const toggleDetails = (item: NFTTransaction) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap }

    if (itemIdToExpandedRowMapValues[item._id]) {
      delete itemIdToExpandedRowMapValues[item._id]
    } else {
      itemIdToExpandedRowMapValues[item._id] = <NFTTxnDetails item={item} />
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues)
  }

  const columns: EuiBasicTableColumn<any>[] = [
    {
      field: 'requestTime',
      name: 'Request Time',
      sortable: true,
      width: '15%',
      render: function (time: number): JSX.Element {
        return <RequestTime time={time} />
      },
    },
    {
      field: 'nftData',
      name: 'Item(s)',
      width: '25%',
      render: function (nftData: NFT[]): JSX.Element {
        return <NFTData nftData={nftData} />
      },
    },
    {
      field: 'fromChainId',
      name: 'From Chain Id',
    },
    {
      field: 'requestHashLink',
      name: 'Request Tx',
      width: '20%',
      render: function ({
        networkName,
        explorerLogo,
        requestHash,
        requestHashUrl,
      }: {
        networkName: string
        explorerLogo: string
        requestHash: string
        requestHashUrl: string
      }): JSX.Element {
        return (
          <RequestLink
            networkName={networkName}
            explorerLogo={explorerLogo}
            requestHash={requestHash}
            requestHashUrl={requestHashUrl}
          />
        )
      },
    },
    {
      field: 'claimHashLink',
      name: 'Claim Tx',
      width: '20%',
      render: function ({
        networkName,
        explorerLogo,
        claimHash,
        claimHashUrl,
      }: {
        networkName: string
        explorerLogo: string
        claimHash: string
        claimHashUrl: string
      }): JSX.Element {
        return (
          <ClaimLink
            networkName={networkName}
            explorerLogo={explorerLogo}
            claimHash={claimHash}
            claimHashUrl={claimHashUrl}
          />
        )
      },
    },
    {
      field: 'toChainId',
      name: 'To Chain Id',
    },
    {
      field: 'claimed',
      name: '',
      width: 'calc(20% - 50px)',
      actions: [
        {
          render: (item: NFTTransaction) => {
            const color = item.claimed ? 'success' : 'warning'
            const label = item.claimed ? 'Success' : 'Awaiting claim'
            let accountLowerCase = account ? account.toLowerCase() : ''

            if (!account) {
              return <EuiHealth color={color}>{label}</EuiHealth>
            }

            if (getAllTxns) {
              if (account && isCasper) {
                accountLowerCase = CLPublicKey.fromHex(account).toAccountHashStr().substring(13)
              }

              if (accountLowerCase === item.account.toLowerCase()) {
                return isCasper ? <ClaimNFTButtonCasper item={item} /> : <ClaimNFTButtonEVM item={item} />
              }
              return <EuiHealth color={color}>{label}</EuiHealth>
            }

            if (!item.claimed) {
              return isCasper ? <ClaimNFTButtonCasper item={item} /> : <ClaimNFTButtonEVM item={item} />
            }

            return isCasper ? (
              <ClaimNFTForButtonCasper item={item} inline={true} />
            ) : (
              <ClaimNFTForButtonEVM item={item} inline={true} />
            )
          },
        },
      ],
    },
    {
      align: 'right',
      width: '40px',
      isExpander: true,
      render: (item: NFTTransaction) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item)}
          aria-label={itemIdToExpandedRowMap[item._id] ? 'Collapse' : 'Expand'}
          iconType={itemIdToExpandedRowMap[item._id] ? 'arrowUp' : 'arrowDown'}
        />
      ),
    },
  ]

  // pagination
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: 5,
    pageSizeOptions: [20, 50, 100],
  } as Pagination

  // Sorting
  const [sortField, setSortField] = useState('requestTime')
  const [sortDirection, setSortDirection] = useState('desc')

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  const onTableChange = ({ page = {}, sort = {} }) => {
    // @ts-ignore
    const { index: _pageIndex, size: _pageSize } = page

    setPageIndex(_pageIndex)
    setPageSize(_pageSize)

    // @ts-ignore
    const { field: _sortField, direction: _sortDirection } = sort
    setSortField(_sortField)
    setSortDirection(_sortDirection)
  }

  return (
    <NFTTableWrap className={getAllTxns ? 'historyTable' : ''}>
      {error ? (
        <span>Could not load data</span>
      ) : (
        <EuiInMemoryTable
          loading={isLoading}
          itemId="_id"
          items={transactions}
          columns={columns}
          isExpandable={true}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
          hasActions={false}
          tableLayout="fixed"
          pagination={pagination}
          // @ts-ignore
          sorting={sorting}
          search={search}
          onTableChange={onTableChange}
        />
      )}
    </NFTTableWrap>
  )
}

export default NFTTransactionTable
