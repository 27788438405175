import { useCallback } from 'react'
import { CEP78Client } from 'casper-cep78-js-client'
import Network from 'type/Network'
import { CLByteArray, CLPublicKey, CLValueBuilder, decodeBase16, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import { helpers } from 'casper-js-client-helper'
import { genRanHex } from 'utils'
import NFTCollection from 'type/NFTCollection'
import { useNFTBridgeAddress } from 'state/application/hooks'

export const useRequestCasperNFTBridge = (
  account?: string | null,
  nftCollection?: NFTCollection | undefined,
  network?: Network,
  targetNetwork?: Network,
  receiverAddress?: string,
): ((tokenIds: number[], moduleBytes?: Uint8Array) => Promise<DeployUtil.Deploy | null>) => {
  const { createRecipientAddress } = helpers
  const nftBridgeAddress = useNFTBridgeAddress()

  const callback = useCallback(
    async (tokenIds: number[], moduleBytes?: Uint8Array): Promise<DeployUtil.Deploy | null> => {
      const nftContractHash = nftCollection?.contractHash
      const nftContractPackageHash = nftCollection?.contractPackageHash
      if (account && nftContractHash && nftContractPackageHash && network && targetNetwork && receiverAddress) {
        const isRequestingNFTBack = nftCollection?.originChainId !== network.chainId
        const contractPackageHashAsByteArray = new CLByteArray(decodeBase16(nftContractPackageHash))
        const nftBridgeByteArray = new CLByteArray(decodeBase16(nftBridgeAddress))
        const properNFTContractHash = nftContractHash.startsWith('hash-') ? nftContractHash.slice(5) : nftContractHash
        const nftContract = new CEP78Client(properNFTContractHash, network.rpcURL, network.key ?? 'casper-test')
        await nftContract.init()
        const identifierMode = await nftContract.identifierMode()

        let runtimeArgs: RuntimeArgs

        if (identifierMode == 0) {
          const _tokenIds = tokenIds.map(e => CLValueBuilder.u64(e))

          runtimeArgs = RuntimeArgs.fromMap({
            nft_package_hash: createRecipientAddress(contractPackageHashAsByteArray),
            to_chainid: CLValueBuilder.u256(targetNetwork.chainId),
            request_id: CLValueBuilder.string(genRanHex()),
            identifier_mode: CLValueBuilder.u8(identifierMode),
            token_ids: CLValueBuilder.list(_tokenIds),
            receiver_address: CLValueBuilder.string(receiverAddress),
          })

          if (moduleBytes) {
            runtimeArgs = RuntimeArgs.fromMap({
              nft_package_hash: createRecipientAddress(contractPackageHashAsByteArray),
              to_chainid: CLValueBuilder.u256(targetNetwork.chainId),
              bridge_contract_hash: createRecipientAddress(nftBridgeByteArray),
              identifier_mode: CLValueBuilder.u8(identifierMode),
              token_ids: CLValueBuilder.list(_tokenIds),
              receiver_address: CLValueBuilder.string(receiverAddress),
            })
          }

          if (isRequestingNFTBack) {
            runtimeArgs = RuntimeArgs.fromMap({
              token_ids: CLValueBuilder.list(_tokenIds),
              token_hashes: CLValueBuilder.list(_tokenIds),
              to_chainid: CLValueBuilder.u256(targetNetwork.chainId),
              request_id: CLValueBuilder.string(genRanHex()),
              receiver_address: CLValueBuilder.string(receiverAddress),
            })
          }
        } else {
          const _tokenIds = tokenIds.map(e => CLValueBuilder.string(e.toString()))

          runtimeArgs = RuntimeArgs.fromMap({
            nft_package_hash: createRecipientAddress(contractPackageHashAsByteArray),
            to_chainid: CLValueBuilder.u256(targetNetwork.chainId),
            request_id: CLValueBuilder.string(genRanHex()),
            identifier_mode: CLValueBuilder.u8(identifierMode),
            token_hashes: CLValueBuilder.list(_tokenIds),
            receiver_address: CLValueBuilder.string(receiverAddress),
          })

          if (moduleBytes) {
            runtimeArgs = RuntimeArgs.fromMap({
              nft_package_hash: createRecipientAddress(contractPackageHashAsByteArray),
              to_chainid: CLValueBuilder.u256(targetNetwork.chainId),
              bridge_contract_hash: createRecipientAddress(nftBridgeByteArray),
              identifier_mode: CLValueBuilder.u8(identifierMode),
              token_hashes: CLValueBuilder.list(_tokenIds),
              receiver_address: CLValueBuilder.string(receiverAddress),
            })
          }

          if (isRequestingNFTBack) {
            runtimeArgs = RuntimeArgs.fromMap({
              token_ids: CLValueBuilder.list(_tokenIds),
              token_hashes: CLValueBuilder.list(_tokenIds),
              to_chainid: CLValueBuilder.u256(targetNetwork.chainId),
              request_id: CLValueBuilder.string(genRanHex()),
              receiver_address: CLValueBuilder.string(receiverAddress),
            })
          }
        }

        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, network.key ?? 'casper-test', 1, 1800000)

        if (moduleBytes && !isRequestingNFTBack) {
          return DeployUtil.makeDeploy(
            deployParams,
            DeployUtil.ExecutableDeployItem.newModuleBytes(moduleBytes, runtimeArgs),
            DeployUtil.standardPayment(30000000000),
          )
        }
        return DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            decodeBase16(isRequestingNFTBack ? nftContractHash : nftBridgeAddress),
            isRequestingNFTBack ? 'request_bridge_back' : 'request_bridge_nft',
            runtimeArgs,
          ),
          DeployUtil.standardPayment(isRequestingNFTBack ? 4000000000 : 30000000000),
        )
      }

      return null
    },
    [account, nftCollection, receiverAddress],
  )

  return callback
}
