import styled from 'styled-components/macro'

export const BoxWrapper = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  width: 100%;
  margin: 1.5rem 0rem;

  @media (min-width: 48rem) {
    margin: 3rem 0rem;
  }
`
export const BoxTitle = styled.h3`
  font-size: 1rem;
  color: #fff;
  margin-bottom: -0.1rem;

  @media (min-width: 48rem) {
    font-size: 1.25rem;
  }
`
