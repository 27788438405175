import axios from 'axios'
import { useEffect, useState } from 'react'
import TokenMap from 'type/TokenMap'
import { getTokenLogos, isDev } from 'utils'

export const useSupportedTokens = (): [TokenMap[], boolean] => {
  const chainId = isDev ? 96945816564243 : 131614895977472

  const [tokenMap, setTokenMap] = useState<TokenMap[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        setIsLoading(true)

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tokenMap`)

        if (response.status === 200) {
          const { tokenMap: _tokenMap }: { tokenMap: TokenMap[] } = response.data
          const tokenLogos = await getTokenLogos()
          const result: TokenMap[] = []

          for (const tm of _tokenMap) {
            const logo = tokenLogos.find(
              _logo => _logo.address.toLowerCase() === tm.address.toLowerCase() && _logo.chainId === tm.networkId,
            )

            result.push({
              ...tm,
              originKey: `${tm.address}-${tm.networkId}`,
              logoURI: logo?.logoURI ?? '',
            })
          }

          setTokenMap(result)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [chainId])

  return [tokenMap, isLoading]
}
