import { EuiButton, EuiButtonEmpty } from '@elastic/eui'
import Box from 'components/Box'
import { BoxTitle } from 'components/Box/Styled'
import { Row } from 'components/Grid'
import { Col50 } from 'components/Grid/Styled'
import NetworkSelect from 'components/NetworkSelect'
import styled, { keyframes } from 'styled-components/macro'
import NFTCollectionSelect from './components/NFTCollectionSelect'
import { IconButton, RotatingAnimation } from 'theme/components'
import Background1 from 'assets/images/bg-1.svg'

export const StepsRow = styled(Row)`
  .euiStepsHorizontal {
    display: none;

    @media (min-width: 48rem) {
      display: flex;
    }
  }
`
export const StepsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 48rem) {
    display: none;
  }

  p {
    font-weight: 600;
    margin: 0;
  }

  svg,
  span {
    display: inline-block;
    vertical-align: middle;
  }

  strong {
    font-weight: 600;
    color: ${props => props.theme.primary};
  }
`
// NFT Collection Modal
export const NFTCollectionName = styled.span`
  color: #154ba0;

  &.ellipsis {
    max-width: 8rem;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (min-width: 75rem) {
      max-width: 25rem;
    }
  }

  .euiBetaBadge {
    color: ${props => props.theme.primary};
  }
`
// Step1
export const LeftCol = styled(Col50)`
  position: relative;
  width: 100% !important;
  flex: 0 0 100% !important;

  @media (min-width: 48rem) {
    width: 80% !important;
    flex: 0 0 80% !important;
  }

  @media (min-width: 62rem) {
    width: 50% !important;
    flex: 0 0 50% !important;
  }
`
export const RightCol = styled(Col50)`
  margin-top: 2rem !important;
  width: 100% !important;
  flex: 0 0 100% !important;

  @media (min-width: 62rem) {
    margin-top: 0 !important;
    width: 50% !important;
    flex: 0 0 50% !important;
  }

  .slick-slide img {
    border-radius: 10px;
    max-width: 17.5rem;
    margin: 0 auto;

    @media (min-width: 26.5625rem) {
      max-width: 21.875rem;
      margin-top: 0 !important;
    }

    @media (min-width: 48rem) {
      max-width: 25ren;
      margin-top: 0 !important;
    }

    @media (min-width: 75rem) {
      max-width: 31.25rem;
    }

    @media (min-width: 90rem) {
      max-width: none;
    }
  }

  a {
    margin-bottom: 2rem;
    text-align: right;

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }
`
export const StyledButton = styled(EuiButton)`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  @media (min-width: 62rem) {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
`
export const StyledNote = styled.div`
  margin-bottom: 1rem;
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  color: ${props => props.theme.primary};

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 48rem) {
    font-size: 1rem;
  }

  svg {
    margin-right: 0.5rem;
  }

  a {
    color: #fff;

    &:hover {
      text-shadow: 0 0 10px #e2007a !important;
    }
  }
`
export const SwapBtn = styled.div`
  cursor: pointer;
  margin-bottom: 1rem;
  width: 3rem;
  height: 1rem;
  border-radius: 99px;
  background-color: #727eaa;
  display: flex;
  justify-content: center;
  padding: 0.25rem;
`
// Step2
export const Step2Box = styled(Box)`
  padding: 0 1.5rem;
  padding-bottom: 0.875rem;

  @media (min-width: 48rem) {
    padding-bottom: 0.125rem;
  }
`
export const Step2BoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: 48rem) {
    margin: 0;
  }
`
export const Step2BoxTitle = styled(BoxTitle)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`
export const YourNFTsIn = styled.span`
  display: none;

  @media (min-width: 48rem) {
    display: inline-block;
    color: #154ba0;
  }
`
export const InlineNFTCollectionSelect = styled(NFTCollectionSelect)`
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;

  button {
    margin-bottom: 0;
    padding: 0.75rem;
  }

  @media (min-width: 48rem) {
    margin-left: 1rem;

    button {
      max-width: none;
      border-radius: 0.5rem;
    }

    .euiButtonContent__icon {
      display: block;
    }

    .euiButton__text {
      margin-right: 1rem;

      span {
        display: flex;
        align-items: center;
      }

      img {
        margin-right: 0.5rem;
      }
    }
  }
`
export const RefreshButton = styled(IconButton)`
  margin-left: 0.5rem;

  &.is-loading svg {
    animation: ${RotatingAnimation} 0.75s linear infinite;
  }
`
// NFT Grid
export const NFTGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  margin-top: 0.5rem;
  margin-left: -15px;
  margin-right: -15px;
  height: 14rem;
  overflow: auto;

  @media (min-width: 26.5625rem) {
    height: 17rem;
  }

  @media (min-width: 48rem) {
    height: 22rem;
  }

  @media (min-width: 62rem) {
    height: 41rem;
  }

  @media (min-width: 75rem) {
    height: 47.5rem;
  }

  @media (min-width: 90rem) {
    height: 52.625rem;
  }
`
export const NFTItemWrapper = styled.div`
  margin-bottom: 0.625rem;
  width: 50%;
  flex: 0 0 50%;
  max-height: 375px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;

  &:nth-child(2n) {
    padding-left: 5px;
  }

  &:nth-child(2n + 1) {
    padding-right: 5px;
  }

  &.is-selected {
    > div {
      box-shadow: 0 0 5px ${props => props.theme.primary}cc;

      @media (min-width: 48rem) {
        box-shadow: 0 0 15px ${props => props.theme.primary}cc;
      }
    }

    .nft-checkbox::before {
      background-color: ${props => props.theme.primary};
    }

    .nft-checkbox svg {
      display: block;
    }
  }

  @media (min-width: 48rem) {
    margin-bottom: 30px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  @media (min-width: 62rem) {
    width: 33.333%;
    flex: 0 0 33.333%;
  }
`
export const NFTItemWrapperLoading = styled(NFTItemWrapper)`
  cursor: initial;
`
export const NFTItemInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  justify-content: space-between;
  background-color: #f1f2f6;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100%;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 0 0 5px ${props => props.theme.primary}cc;

    @media (min-width: 48rem) {
      box-shadow: 0 0 15px ${props => props.theme.primary}cc;
    }
  }
`
export const NFTItemInnerLoading = styled(NFTItemInner)`
  &:hover {
    box-shadow: none;
  }
`
export const NFTImage = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`
const LoadingAnimation = keyframes`
  0% {
    background-color: #2e3039;
  }

  40% {
    background-color: #282a31;
  }

  100% {
    background-color: #2e3039;
  }
`
export const NFTImageLoading = styled(NFTImage)`
  background-color: #154ba0;
  height: 75%;
`
export const NFTCheckbox = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100%;
  height: 1.75rem;
  width: 1.75rem;
  background-color: #154ba0;
  box-shadow: 0 1px 9px #373f5c36;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    display: block;
    border-radius: 100%;
    height: 1.25rem;
    width: 1.25rem;
    background-color: #e6e8ec;
  }

  svg {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
export const NFTDescription = styled.div`
  padding: 0.5rem 0.5rem 0.75rem;

  @media (min-width: 48rem) {
    padding: 0.75rem;
  }
`
export const NFTDescriptionLoading = styled(NFTDescription)`
  height: 25%;
`
export const NFTName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0.33rem;
  color: #154ba0;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const NFTTextLoading = styled.div`
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  height: 1.25rem;
  background-color: #727eaa;
  overflow: hidden;
  animation: 1s ${LoadingAnimation} infinite linear;

  &:last-child {
    margin-bottom: 0;
    height: 2rem;
  }
`
export const TokenInfoButton = styled.span`
  cursor: pointer;

  svg {
    margin-left: 0.25rem;
    display: inline-block;
    vertical-align: middle;
    color: #ffd166;

    @media (min-width: 26.5625rem) {
      margin-left: 0.5rem;
      font-size: 1rem;
    }

    @media (min-width: 48rem) {
      font-size: 1.25rem;
    }
  }
`
export const NFTId = styled.div`
  font-size: 0.875rem;
  margin: 0;
  color: #154ba0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 26.5625rem) {
    font-size: 1rem;
  }
`
export const Step2Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
`
export const SelectAll = styled.div`
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 100%;
    height: 1.75rem;
    width: 1.75rem;
    background-color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    border-radius: 100%;
    height: 1.25rem;
    width: 1.25rem;
    background-color: #e6e8ec;
  }

  svg {
    display: none;
    position: absolute;
    top: 50%;
    left: 0.35rem;
    transform: translateY(-50%);
    z-index: 1;
  }

  span {
    padding-left: 2.25rem;
    display: inline-block;
    vertical-align: middle;
  }

  &.is-selected {
    &::after {
      background-color: ${props => props.theme.primary};
    }

    svg {
      display: block;
    }
  }
`
export const Step2Buttons = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 26.5625rem) {
    padding-bottom: 0.5rem;
  }

  @media (min-width: 62rem) {
    padding-bottom: 1.5rem;
  }

  .euiButtonEmpty {
    margin-right: 1rem;
    display: none;

    @media (min-width: 48rem) {
      display: block;
    }
  }

  .euiButtonEmpty__text {
    color: #fff;
  }
`

// Step3
export const StepBox3Title = styled(BoxTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Step3NetworkSelect = styled(NetworkSelect)`
  .euiButton {
    margin-bottom: 0;
  }
`
export const SelectedNFTsTitle = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;

  span {
    color: #154ba0;
  }

  @media (min-width: 48rem) {
    font-size: 1.25rem;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
`
export const ReSelectButton = styled(EuiButtonEmpty)`
  color: ${props => props.theme.secondary};
`
export const SelectedNFTsBox = styled.ul`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #1f1f1f;
`
// NFT List Item
export const NFTListItemWrapper = styled.li`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  @media (min-width: 48rem) {
    margin-bottom: 1.5rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`
export const NFTImageList = styled.img`
  max-width: 3rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.5rem;

  @media (min-width: 48rem) {
    margin-right: 1.5rem;
  }
`
export const NFTNameList = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-size: 0.875rem;

  @media (min-width: 48rem) {
    font-size: 1rem;
  }
`
export const FeeBox = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #1f1f1f;

  h5 {
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }

  @media (min-width: 48rem) {
    margin-bottom: 2.5rem;
  }
`
export const FeeBoxRow = styled.div`
  @media (min-width: 76rem) {
    display: flex;
    justify-content: space-between;
  }
`
export const NoteText = styled.div`
  margin-bottom: 1rem;
  color: #b1b5b5;
  font-size: 12px;
`
export const FeeText = styled.span`
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-weight: 600;

  @media (min-width: 75rem) {
    text-align: right;
  }
`
export const BalanceText = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: #777e90;

  @media (min-width: 75rem) {
    text-align: right;
  }
`

// Step4
export const Step4Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const ResultMessage = styled.h3`
  margin-top: 1rem;
  margin-bottom: 0;

  @media (min-width: 48rem) {
    margin-top: 1.5rem;
  }
`
export const TransactionWrapper = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #23262f;
  border-radius: 8px;
  color: #777e90;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
  }

  .euiButtonEmpty--primary:enabled:focus {
    background-color: transparent;
  }

  .euiButtonEmpty,
  .euiButtonContent {
    padding: 0;
    border: 0;
  }
`
export const TxIDSpan = styled.span`
  color: #fff;
  margin-left: 0.25rem;
`
export const Step4Buttons = styled.div`
  margin-top: 2rem;
  width: 100%;
  text-align: center;

  .euiButton {
    margin-bottom: 1rem;
  }

  .euiButtonEmpty,
  .euiButtonContent {
    padding: 0;
    border: 0;
  }

  .euiButtonEmpty__text {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    color: ${props => props.theme.primary};
  }
`

// NFT Info Modal
export const NFTImageInfo = styled.img`
  max-width: 15rem;
  border-radius: 0.5rem;
`
export const NFTInfoList = styled.div`
  max-height: 18rem;
  overflow: auto;
  width: 100%;
  flex: 0 100%;

  p {
    background: #727eaa;
    border-radius: 0.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.75rem;
  }

  strong {
    display: block;
  }
`

// Confirm Modal
export const ConfirmationButtons = styled(Step2Buttons)`
  padding-bottom: 0;
`
export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  background-color: #154ba0;
  border-radius: 0.5rem 0.5rem 0 0;
  background-image: url(${Background1});
  background-size: 170px 150px;
  background-repeat: no-repeat;
  background-position: right center;
`
