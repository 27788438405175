/* eslint-disable @typescript-eslint/no-extra-semi */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TransactionResponse } from '@ethersproject/providers'
import { useNFTContract } from './useContract'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { useSingleCallResult } from 'state/multicall/hooks'
import { NFTType } from 'type/NFTCollection'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { useCurrentNetwork } from './useNetwork'
import {
  CasperClient,
  CLBool,
  CLByteArray,
  CLKey,
  CLPublicKey,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { CEP78Client } from 'casper-cep78-js-client'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import { getDeployFunction } from 'utils'
import { useActiveWeb3React } from './useWeb3'
import Network from 'type/Network'

export enum NFTApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  LOADING = 'LOADING',
}

export const useApprovedForAll = (
  contractAddress?: string,
  type?: NFTType,
  account?: string,
  spender?: string,
): boolean => {
  const nftContract = useNFTContract(contractAddress, type, false)

  const inputs = useMemo(() => [account, spender], [account, spender])
  const approved = useSingleCallResult(nftContract, 'isApprovedForAll', inputs).result
  return useMemo(() => {
    return Boolean(approved?.toString() === 'true')
  }, [contractAddress, account, spender, approved])
}

export const useApproveNFTCallback = (
  contractAddress?: string,
  type?: NFTType,
  spender?: string,
  tokenId?: number,
): [NFTApprovalState, () => Promise<void>] => {
  const account = useAccount()

  const addTransaction = useTransactionAdder()
  const nftContract = useNFTContract(contractAddress)
  const approved = useApprovedForAll(contractAddress, type, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(contractAddress, spender)

  const approvalState: NFTApprovalState = useMemo(() => {
    if (!account) return NFTApprovalState.UNKNOWN
    return !approved
      ? pendingApproval
        ? NFTApprovalState.PENDING
        : NFTApprovalState.NOT_APPROVED
      : NFTApprovalState.APPROVED
  }, [contractAddress, approved, spender, pendingApproval])

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== NFTApprovalState.NOT_APPROVED) {
      console.error('approve nft was called unnecessarily')
      return
    }

    if (!contractAddress) {
      console.error('no contract')
      return
    }

    if (!nftContract) {
      console.error('nftContract is null')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    return nftContract
      .setApprovalForAll(spender, true)
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Approve your NFT',
          approval: { tokenAddress: contractAddress, spender: spender },
        })
      })
      .catch((error: Error) => {
        console.error('Failed to approve token', error)
        throw error
      })
  }, [NFTApprovalState, account, contractAddress, nftContract, spender, tokenId, addTransaction])

  return [approvalState, approve]
}

export const useApprovedForAllCasper = (
  contractHash?: string,
  isWrapped?: boolean,
  network?: Network,
  account?: string,
  spender?: string,
): [boolean, boolean] => {
  const [approved, setApproved] = useState(false)
  const [loading, setLoading] = useState(false)
  const pendingApproval = useHasPendingApproval(contractHash, spender)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (isWrapped) {
        setApproved(true)
      } else {
        if (network && contractHash && account) {
          try {
            const _cep78Client = await CEP78Client.createInstance(
              contractHash,
              network.rpcURL,
              network.key ?? 'casper-test',
            )

            const _approved = await _cep78Client.checkOperatorDictionaryKey(account, spender)
            setApproved(_approved)
          } catch (error) {
            setApproved(false)
          } finally {
            setLoading(false)
          }
        }
      }
    })()
  }, [contractHash, isWrapped, network, account, spender, pendingApproval])

  // return useMemo(() => [approved, loading], [contractHash, isWrapped, network, account, spender])
  return [approved, loading]
}

export const useApproveNFTCasperCallback = (
  contractHash?: string,
  spender?: string,
  isWrapped?: boolean,
): [NFTApprovalState, () => Promise<void>] => {
  const account = useAccount()
  const { connector } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const pendingApproval = useHasPendingApproval(contractHash, spender)
  const network = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const [approved, loading] = useApprovedForAllCasper(contractHash, isWrapped, network, account ?? undefined, spender)

  const approvalState: NFTApprovalState = useMemo(() => {
    if (!account) return NFTApprovalState.UNKNOWN

    if (loading) return NFTApprovalState.LOADING

    return !approved
      ? pendingApproval
        ? NFTApprovalState.PENDING
        : NFTApprovalState.NOT_APPROVED
      : NFTApprovalState.APPROVED
  }, [account, contractHash, approved, loading, spender, pendingApproval])

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState == NFTApprovalState.APPROVED) {
      console.error('approve nft was called unnecessarily')
      return
    }

    if (!contractHash) {
      console.error('no contract')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    if (account && network) {
      try {
        const deployParams = new DeployUtil.DeployParams(
          CLPublicKey.fromHex(account),
          network?.key ?? 'casper-test',
          1,
          1800000,
        )

        const runtimeArgs = RuntimeArgs.fromMap({
          token_owner: createRecipientAddress(CLPublicKey.fromHex(account)),
          approve_all: new CLBool(true),
          operator: new CLKey(new CLByteArray(decodeBase16(spender))),
        })

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            decodeBase16(contractHash),
            'set_approval_for_all',
            runtimeArgs,
          ),
          DeployUtil.standardPayment(2000000000),
        )

        const json = DeployUtil.deployToJson(deploy)
        const casperClient = new CasperClient(network.rpcURL)

        const deployFn = getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

        deployFn
          .then(async (hash: any) => {
            addTransaction(hash, {
              summary: 'Approve your NFT',
              approval: { tokenAddress: contractHash, spender: spender },
            })
          })
          .catch((error: any) => {
            console.error(error)
          })
      } catch (error) {}
    }
  }, [NFTApprovalState, account, contractHash, spender, addTransaction])

  return [approvalState, approve]
}
