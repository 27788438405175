import NavItem from './NavItem.type'

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Home',
    href: 'https://paradiso.io/',
    target: '_blank',
  },
  {
    label: 'Bridge',
    slug: 'home',
    to: '/',
  },
  {
    label: 'NFT Bridge',
    slug: 'nft-bridge',
    to: '/nft-bridge',
  },
  {
    label: 'Liquid Staking',
    target: '_blank',
    href: 'https://stcspr.paradiso.io',
  },
  {
    label: 'Explorer',
    slug: 'explorer',
    to: '/explorer',
  },
  {
    label: 'Tokens',
    slug: 'tokens',
    to: '/supported-tokens',
  },
]

export default NAV_ITEMS
