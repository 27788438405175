import { createReducer } from '@reduxjs/toolkit'
import Token from 'type/Token'
import { updateTokenAmount, updateSelectedToken, clearTokenCache } from './actions'

export interface TokenState {
  readonly selectedToken?: Token
  readonly tokenAmount: string
}

const initialState: TokenState = {
  selectedToken: undefined,
  tokenAmount: '',
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateSelectedToken, (state, action) => {
      state.selectedToken = action.payload.token
    })
    .addCase(updateTokenAmount, (state, action) => {
      state.tokenAmount = action.payload.amount
    })
    .addCase(clearTokenCache, () => {
      return initialState
    }),
)
