import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import {
  EuiFieldSearch,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOutsideClickDetector,
} from '@elastic/eui'
import NFTCollection from 'type/NFTCollection'
import NFTCollectionRow from './NFTCollectionRow'
import { HiOutlineRefresh } from 'react-icons/hi'
import { updateNFTCollection, updateNFTList, updateSelectedNFTs } from 'state/nft/actions'
import { useSelectedNFTCollection, useSupportedNFTCollections } from 'state/nft/hooks'
import { useCurrentNetwork } from 'hooks'
import { ModalBody, BreakLine, NoResultsFound, RotatingAnimation, IconButton } from 'theme/components'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'

const LoadingWrap = styled.div`
  padding: 0 1rem;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`
const StyledLink = styled(Link)`
  color: #888d9b;
  display: flex;
  align-items: center;
  gap: 4px;
`
const ListDiv = styled.div`
  height: 90%;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 280px;
  }
`
const ModalFooter = styled(EuiModalFooter)`
  justify-content: space-between;
`
export const RefreshButton = styled(IconButton)`
  &.is-loading svg {
    animation: ${RotatingAnimation} 0.75s linear infinite;
  }
`

interface NFTCollectionModalProps {
  onDismiss: () => void
}

function NFTCollectionModal(props: NFTCollectionModalProps): JSX.Element {
  const { onDismiss } = props

  const network = useCurrentNetwork()
  const nftCollection = useSelectedNFTCollection()
  const dispatch = useDispatch<AppDispatch>()

  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const supportedNFTCollectionsCallback = useSupportedNFTCollections(network, true)
  const [nftCollections, setNFTCollections] = useState<NFTCollection[]>([])

  const fetchData = async () => {
    setLoading(true)
    const _nftCollections = await supportedNFTCollectionsCallback()
    setNFTCollections(_nftCollections)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [network])

  const handleInput = useCallback((event: any) => {
    const input = event.target.value
    setSearchQuery(input)
  }, [])

  const handleonDismiss = (collection: NFTCollection) => {
    dispatch(updateNFTCollection({ nftCollection: collection }))
    dispatch(updateNFTList({ nftList: [] }))
    dispatch(updateSelectedNFTs({ selectedNFTs: [] }))
    onDismiss()
  }

  const onRefresh = async () => {
    localStorage.removeItem('token_logos')
    await fetchData()
  }

  return (
    <>
      <EuiOutsideClickDetector onOutsideClick={onDismiss}>
        <EuiModal onClose={onDismiss}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Select an NFT Collection</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <ModalBody>
            <EuiFieldSearch
              name="search-network"
              value={searchQuery}
              autoComplete="off"
              placeholder="Search name of NFT Collection"
              onChange={handleInput}
            />
            <BreakLine />
            {loading ? (
              <LoadingWrap>
                <EuiLoadingSpinner size="l" />
              </LoadingWrap>
            ) : (
              <>
                {nftCollections.length > 0 ? (
                  <ListDiv>
                    <div className="eui-yScroll">
                      {nftCollections.map(c => (
                        <NFTCollectionRow
                          key={c.address}
                          collection={c}
                          isSelected={
                            nftCollection
                              ? Boolean(c.address.toLowerCase() == nftCollection?.address.toLowerCase())
                              : false
                          }
                          onSelect={() => handleonDismiss(c)}
                        />
                      ))}
                    </div>
                  </ListDiv>
                ) : (
                  <NoResultsFound>No results found.</NoResultsFound>
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <RefreshButton onClick={onRefresh} className={loading ? 'is-loading' : ''}>
              <HiOutlineRefresh color="#888d9b" size={18} />
            </RefreshButton>
            <StyledLink to="supported-tokens" target="_blank">
              <span>Supported Tokens</span>
              <BiLinkExternal />
            </StyledLink>
          </ModalFooter>
        </EuiModal>
      </EuiOutsideClickDetector>
    </>
  )
}

export default NFTCollectionModal
