import styled, { css } from 'styled-components/macro'
import { EuiFlexItem, EuiHeader } from '@elastic/eui'
import { Link } from 'react-router-dom'

export const HeaderWrapper = styled(EuiHeader)`
  min-height: 80px;
  border-bottom: none;
  box-shadow: none;
  background: linear-gradient(135deg, #0b8994, #3a96a8, #14499f);

  > div {
    width: 100%;
    flex: 0 100%;
  }
`
export const MenuItem = styled(EuiFlexItem)`
  position: relative;
  padding: 0 0.5rem;
`
export const LogoDekstop = styled.img`
  display: none;
  padding: 0;

  .euiIcon--large {
    height: auto;
    width: 250px;
  }

  @media (min-width: 82rem) {
    display: block;
  }
`
export const LogoMobile = styled.img`
  display: block;
  padding: 0;

  .euiIcon--large {
    height: auto;
    width: 40px;
  }

  @media (min-width: 82rem) {
    display: none;
  }
`
export const SubText = styled.span`
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  right: 0;
  top: -25px;
  width: fit-content;
  font-size: 7px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: #fff;
  background-color: ${props => props.theme.primary};
`
export const MenuLinkStyle = css`
  padding: 27px 1rem;
  position: relative;
  font-weight: 600;
  color: #fcfcfd;

  svg {
    vertical-align: middle;
    margin-left: 8px;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    transition: all 400ms ease;
    background-color: ${props => props.theme.primary};
  }

  &:hover,
  &.active {
  }

  &.active {
  }
`
export const MenuLink = styled(Link)`
  ${MenuLinkStyle}
`
export const MenuA = styled.a`
  ${MenuLinkStyle}
`
export const MenuToggle = styled.div`
  margin-left: 1rem;
  margin-top: 0.15rem;
  color: #fff;

  &:hover,
  &:focus {
    transform: none !important;
  }
`
export const MobileMenuLinkStyle = css`
  display: block;
  font-weight: 500;
  border-bottom: 1px solid #333;
  padding: 15px 20px;
  color: #fff;
`
export const MobileMenuItem = styled.li``
export const MobileMenuLink = styled(Link)`
  ${MobileMenuLinkStyle}
`
export const MobileMenuA = styled.a`
  ${MobileMenuLinkStyle}
`
export const MobileSubText = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  padding: 5px;
  border-radius: 3px;
  width: fit-content;
  font-size: 7px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: #fff;
  background-color: ${props => props.theme.primary};
`
