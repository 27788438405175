import { EuiToolTip } from '@elastic/eui'
import styled from 'styled-components/macro'
import UnknownSVG from 'assets/images/unknown.svg'
import { Flex } from '../Styled'

const ExplorerLogo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
`
const Link = styled.a`
  font-size: 0.875rem;
  color: #154ba0;

  &:hover {
    text-decoration: underline;
  }
`
const FakeLink = styled.span`
  font-size: 0.875rem;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
`

interface ClaimLinkProps {
  networkName: string
  explorerLogo: string
  claimHash: string
  claimHashUrl: string
}

function ClaimLink(props: ClaimLinkProps): JSX.Element {
  const { networkName, explorerLogo, claimHash, claimHashUrl } = props

  return (
    <EuiToolTip content={networkName}>
      <Flex>
        {claimHash && <ExplorerLogo src={explorerLogo ? explorerLogo : UnknownSVG} alt="explorer-logo" />}
        {claimHashUrl ? (
          <Link href={claimHashUrl} target="__blank" rel="noopener nofollow noreferrer">
            {claimHash}
          </Link>
        ) : (
          <FakeLink>{claimHash}</FakeLink>
        )}
      </Flex>
    </EuiToolTip>
  )
}

export default ClaimLink
