import { useContext } from 'react'
import { EuiExpression, EuiButtonIcon, EuiIcon } from '@elastic/eui'
import { ethers } from 'ethers'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import styled from 'styled-components/macro'
import Token from 'type/Token'
import { formatNumber } from 'utils'
import BridgeAppContext from 'context/BridgeAppContext'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { useActiveWeb3React, useCurrentNetwork, useTokenBalanceCallback } from 'hooks'
import { updateSelectedToken } from 'state/token/actions'
import { useIsUserAddedToken } from 'state/token/hooks'
import { Dots, WrappedTag } from 'theme/components'
import { updateTargetNetwork } from 'state/application/actions'
import AssetLogo from 'components/AssetLogo'

const Row = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0px, 5rem);
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-bottom: 0.25rem;
  height: 56px;
  cursor: pointer;
  color: #fcfcfd;
  transition: all 0.5s ease;

  &:hover {
    background-color: #fff;
  }

  &.disabled {
    opacity: 0.5;
    cursor: initial;
  }

  &.selected {
    cursor: initial;
    background-color: #727eaa;
  }

  @media (min-width: 768px) {
    padding: 0 30px;
    grid-template-columns: auto minmax(auto, 1fr) minmax(0px, 7.5rem);
    gap: 1rem;
  }
`
const TokenNameWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  p {
    margin: 0;
    color: #154ba0;
  }

  .euiBetaBadge {
    color: ${props => props.theme.primary};
  }
`
const TokenName = styled.span`
  display: block;
  margin-top: 0.25rem;
  font-size: 12px;
  line-height: 1.2;
  color: #6c7284;

  a {
    text-decoration: underline;
    color: #6c7284;

    &:hover {
      color: #fff;
    }
  }
`
const TokenBlance = styled.div`
  text-align: right !important;
`
const Balance = styled(EuiExpression)`
  > span {
    text-transform: none;
    color: #154ba0;
  }
`
const RemoveButton = styled(EuiButtonIcon)`
  position: absolute;
  top: -2px;
  right: -5px;
  background-color: transparent !important;

  svg {
    width: 12px;
    height: 12px;
  }
`

interface ITokenRow {
  token: Token
  isSelected: boolean
  onSelect: () => void
  onRemoveCustomToken: () => void
}

function TokenRow(props: ITokenRow): JSX.Element {
  const { token, isSelected, onSelect, onRemoveCustomToken } = props
  const { ledgerAddress } = useContext(BridgeAppContext)
  const { library: web3Library } = useActiveWeb3React()

  const account = useAccount()
  const chainId = useChainId()

  const currentNetwork = useCurrentNetwork()
  const library =
    ledgerAddress !== '' ? new ethers.providers.JsonRpcProvider(currentNetwork?.rpcURL ?? '') : web3Library

  const isAdded = useIsUserAddedToken(token)

  const [tokenBalance, isLoadingBalance] = useTokenBalanceCallback(
    token.address,
    token.decimals,
    account,
    library,
    currentNetwork,
  )

  const dispatch = useDispatch<AppDispatch>()

  const handleRemoveCustomToken = () => {
    let _tokens = [] as Token[]
    const data = localStorage.getItem(`tokens_${account}_${chainId}`)

    if (data) {
      _tokens = JSON.parse(data) as Token[]
    }

    if (token) {
      const _newTokens = _tokens.filter(t => t.address !== token.address)
      localStorage.setItem(`tokens_${account}_${chainId}`, JSON.stringify(_newTokens))
    }
  }

  const handleSelectToken = () => {
    dispatch(updateSelectedToken({ token }))
    dispatch(updateTargetNetwork({ network: undefined }))
    onSelect()
  }

  return (
    <Row className={isSelected ? 'disabled' : ''} onClick={() => (isSelected ? null : handleSelectToken())}>
      <AssetLogo src={token.logoURI} text={token.name} size="32px" />
      <TokenNameWrap>
        <div>
          <p>{token.name}</p>
          {isAdded ? (
            <TokenName>
              <span>Added by user • </span>
              {currentNetwork ? (
                <a
                  href={`${currentNetwork.explorer}/address/${token.address}`}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  {token.symbol} <EuiIcon size="s" type="popout" />
                </a>
              ) : (
                <span>{token.symbol}</span>
              )}
            </TokenName>
          ) : (
            <TokenName>{token.symbol}</TokenName>
          )}
        </div>
        {token.originChainId !== undefined && token.originChainId !== chainId && (
          <WrappedTag size="s" label="Wrapped" color="accent" />
        )}
      </TokenNameWrap>
      <TokenBlance>
        {tokenBalance >= 0 ? (
          <>
            {isLoadingBalance ? (
              <Dots />
            ) : (
              <Balance description={`${formatNumber(tokenBalance.toFixed(3))}`} textWrap="truncate" color="subdued" />
            )}
          </>
        ) : (
          <Balance description="" />
        )}
      </TokenBlance>
      {isAdded && (
        <RemoveButton
          aria-label="Remove Token"
          iconType="cross"
          color="danger"
          onClick={() => {
            handleRemoveCustomToken()
            onRemoveCustomToken()
          }}
        />
      )}
    </Row>
  )
}

export default TokenRow
