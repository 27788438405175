import { useCallback, useState } from 'react'
import { EuiConfirmModal, EuiHealth } from '@elastic/eui'
import NFTTransaction from 'type/NFTTransaction'
import { useCurrentNetwork } from 'hooks'
import Network from 'type/Network'
import NetworkInfo from '../../NetworkInfo'
import { connectorLocalStorageKey, ConnectorNames } from '../../../../constants'
import { setupNetwork } from 'utils'
import { ConfirmMessage, Dots, StyledClaimButton } from 'theme/components'
import { useTransactionAdder } from 'state/transactions/hooks'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { useAccount, useChainId, useConnectWalletCallback, useDeactivateCallback } from 'state/wallet/hooks'
import toast from 'react-hot-toast'
import { ClaimState, useUserClaimState } from 'state/nft/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateSourceNetwork, updateTargetNetwork } from 'state/application/actions'
import { updateSelectedToken, updateTokenAmount } from 'state/token/actions'
import { updateConnector } from 'state/wallet/actions'
import { useClaimNFTOriginCasperCallback, useClaimNFTCasperCallback } from 'hooks/useClaimNFTsCallback'

function ClaimNFTButtonCasper({ item }: { item: NFTTransaction }): JSX.Element {
  const deactivate = useDeactivateCallback()

  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const [claimState, itemsCount] = useUserClaimState(item, account)

  const connectWalletCallback = useConnectWalletCallback()
  const dispatch = useDispatch<AppDispatch>()

  const [isClaiming, setIsClaiming] = useState(false)
  const [toNetwork, setToNetwork] = useState<Network>()
  const [showNetworkModal, setShowNetworkModal] = useState(false)

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const addTransaction = useTransactionAdder()
  const claimNFTOriginCasper = useClaimNFTOriginCasperCallback()
  const claimNFTCasper = useClaimNFTCasperCallback()

  const onClaimOriginNFTs = async () => {
    const { toChainId } = item

    setToNetwork(item.toNetwork)

    // Ask user if the currentChainId is different than the toChainId
    if (chainId !== toChainId) {
      setShowNetworkModal(true)
    } else {
      try {
        if (currentNetwork && account) {
          setShowConfirm(true)
          setAttemptingTxn(true)
          setIsClaiming(true)

          claimNFTOriginCasper(account, itemsCount, false)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Claim NFT`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        }
      } catch (error) {
        console.error(error)
        setShowConfirm(false)
        setAttemptingTxn(false)
      } finally {
        setIsClaiming(false)
      }
    }
  }

  const onClaimNFTs = () => {
    const { toChainId } = item

    setToNetwork(item.toNetwork)

    // Ask user if the currentChainId is different than the toChainId
    if (chainId !== toChainId) {
      setShowNetworkModal(true)
    } else {
      try {
        if (currentNetwork && account) {
          setShowConfirm(true)
          setAttemptingTxn(true)
          setIsClaiming(true)

          claimNFTCasper(item, account, false)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Claim NFT`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              setShowConfirm(false)
              setAttemptingTxn(false)
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        }
      } catch (error) {
        console.error(error)
        setShowConfirm(false)
        setAttemptingTxn(false)
      } finally {
        setIsClaiming(false)
      }
    }
  }

  const changeToEVMChain = async (network: Network) => {
    const hasSetup = await setupNetwork(network)

    if (hasSetup) {
      // @ts-ignore
      window.ethereum.on('chainChanged', () => {
        if (chainId === network.chainId && currentNetwork) {
          dispatch(updateTargetNetwork({ network: currentNetwork }))
        }
        // dispatch(updateSourceNetwork({ network }))
        dispatch(updateSelectedToken({ token: undefined }))
        dispatch(updateTokenAmount({ amount: '' }))
      })
    }
  }

  const onSetupNetwork = async (network: Network | undefined) => {
    if (!network) return
    try {
      const isBitcoin = network.name.toLowerCase().includes('bitcoin')
      const _isCasper = network.name.toLowerCase().includes('casper')

      // if current network is EVM
      if (!currentNetwork?.notEVM) {
        // if change to EVM
        if (!network.notEVM) {
          await changeToEVMChain(network)
        } else if (_isCasper) {
          const casperConnector =
            // @ts-ignore
            window.CasperWalletProvider !== undefined
              ? ConnectorNames.CasperWallet
              : // @ts-ignore
              window.casperDashHelper != undefined
              ? ConnectorNames.CasperDash
              : ConnectorNames.CasperSigner
          window.localStorage.setItem(connectorLocalStorageKey, casperConnector)
          dispatch(updateConnector({ connector: casperConnector }))

          await deactivate()
          await connectWalletCallback(ConnectorNames.CasperDash)
          window.location.reload()
        } else if (isBitcoin) {
          // window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Bitcoin)
          dispatch(updateConnector({ connector: ConnectorNames.Bitcoin }))
        }
      } else {
        // from Casper to EVM
        if (currentNetwork?.name.toLowerCase().includes('casper') && !network.notEVM) {
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))

          await deactivate()
          await changeToEVMChain(network)
          window.location.reload()
        }
        // from Bitcoin to EVM
        if (currentNetwork?.name.toLowerCase().includes('bitcoin') && !network.notEVM) {
          window.localStorage.removeItem(connectorLocalStorageKey)
          window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)

          dispatch(updateConnector({ connector: ConnectorNames.Injected }))
          await connectWalletCallback(ConnectorNames.Injected)
        }
      }

      dispatch(updateSourceNetwork({ network }))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error('Could not setup network')
        console.error(error)
      }
    } finally {
      setShowNetworkModal(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      {item.claimed && item.index >= 0 ? (
        <EuiHealth color="success">Success</EuiHealth>
      ) : (
        <>
          {claimState === ClaimState.CLAIMABLE ? (
            <StyledClaimButton
              isDisabled={isClaiming}
              onClick={item.originChainId === chainId ? onClaimOriginNFTs : onClaimNFTs}
            >
              {isClaiming ? <Dots>Verifying</Dots> : <span>Claim</span>}
            </StyledClaimButton>
          ) : (
            <Dots />
          )}
        </>
      )}
      {showNetworkModal && toNetwork && (
        <EuiConfirmModal
          title="Important!"
          onCancel={() => setShowNetworkModal(false)}
          onConfirm={() => onSetupNetwork(toNetwork)}
          cancelButtonText="Cancel"
          confirmButtonText="Change network"
          defaultFocusedButton="confirm"
        >
          <ConfirmMessage>
            You are connecting to <NetworkInfo network={currentNetwork}></NetworkInfo>
          </ConfirmMessage>
          <ConfirmMessage>
            Please change the network to <NetworkInfo network={toNetwork}></NetworkInfo> to claim your NFTs.
          </ConfirmMessage>
        </EuiConfirmModal>
      )}

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Claim your NFTs"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default ClaimNFTButtonCasper
